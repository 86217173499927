export const dateTimeFormate = (date) => {
  const inputDate = new Date(date);

  // Adjusting the date to the local timezone
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Karachi', // Ensure the date is treated as UTC
  };

  // Formatting the date
  const formattedDate = inputDate.toLocaleString('en-US', options);

  return formattedDate;
}
