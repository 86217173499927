import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getCategorizationListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
const DiagnosisList = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const params = useParams()
    const [categorizationList, setCategorizationList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            grade_1: [
                { SCLEROSIS: { key: 'SCLEROSIS', value: 1, name: 'Sclerosis' } },
                { OLISTHESIS: { key: 'OLISTHESIS', value: 2, name: 'Olisthesis' } },
                { TRIPLE_LINE_SIGNTRIPLE_LINE_SIGN: { key: 'TRIPLE_LINE_SIGN', value: 3, name: 'Triple Line Sign' } },
                { CLINICAL_SIGNS: { key: 'CLINICAL_SIGNS', value: 4, name: 'Clinical Signs' } },
                { NO_NEUROLOGICAL_SYMPTOMS_SIGNS: { key: 'NO_NEUROLOGICAL_SYMPTOMS_SIGNS', value: 5, name: 'No Neurological Symptoms / Signs' } }
            ]
        },
        {
            grade_2: [
                { DEFORMITY: { key: 'DEFORMITY', value: 1, name: 'Deformity' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULITIS_ONLY: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULITIS_ONLY', value: 2, name: 'Symptoms and Signs related to radiculitis only' } },
            ]
        },

        {
            grade_3: [
                { REVERSIBLE_DAMAGE: { key: 'REVERSIBLE_DAMAGE', value: 1, name: 'Reversible Damage' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_SINGLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_SINGLE_LEVEL', value: 2, name: 'Symptoms and signs related to radiculopathy - Sensory - Single-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_MULTIPLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_MULTIPLE_LEVEL', value: 3, name: 'Symptoms and signs related to radiculopathy - Sensory - Multi-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_SINGLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_SINGLE_LEVEL', value: 4, name: 'Symptoms and signs related to radiculopathy - Motor - Single-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_MULTI_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_MULTI_LEVEL', value: 5, name: 'Symptoms and signs related to radiculopathy - Motor - Multi-Level' } },
                { MRI_MULTI_LEVEL_DISC_BULGES: { key: 'MRI_MULTI_LEVEL_DISC_BULGES', value: 6, name: 'MRI - Multi-level Disc bulges' } },
                { MRI_DISC_PROTRUSION: { key: 'MRI_DISC_PROTRUSION', value: 7, name: 'MRI - Disc Protrusion' } },
                { MRI_DISC_EXTRUSION: { key: 'MRI_DISC_EXTRUSION', value: 8, name: 'MRI - Disc Extrusion' } },
                { MODERATE_TO_SEVERE_VERTEBRAL_ROTATION: { key: 'MODERATE_TO_SEVERE_VERTEBRAL_ROTATION', value: 9, name: 'Moderate to Severe Vertebral Rotation' } },
                { GRADE_III_O_A_KNEE_HIP_SHOULDER: { key: 'GRADE_III_O_A_KNEE_HIP_SHOULDER', value: 10, name: 'Grade III O.A Knee, Hip, Shoulder' } },
            ],
        },
        {
            grade_4: [
                { SEVERE_DAMAGE: { key: 'SEVERE_DAMAGE', value: 1, name: 'Severe Damage' } },
                { PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_KNEE: { key: 'PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_KNEE', value: 2, name: 'Previous Surgery of Spine Large Joints - Knee' } },
                { PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_HIP: { key: 'PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_HIP', value: 3, name: 'Previous Surgery of Spine Large Joints - Hip' } },
                { MYELOPATHY: { key: 'MYELOPATHY', value: 4, name: 'Myelopathy' } },
                { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 5, name: 'Cervical Dystonia' } },
                { SPINE_CEREBELLAR_DISEASE: { key: 'SPINE_CEREBELLAR_DISEASE', value: 6, name: 'Spine-Cerebellar Disease' } },
                { PARKINSONS_DISEASE: { key: 'PARKINSONS_DISEASE', value: 7, name: "Parkinson's Disease" } },
                { PREVIOUS_HISTORY_OF_BRAIN_ISCHEMIA_WITH_HEMIPARESIS_HEMIPLEGIA: { key: 'PREVIOUS_HISTORY_OF_BRAIN_ISCHEMIA_WITH_HEMIPARESIS_HEMIPLEGIA', value: 8, name: 'Previous History of Brain Ischemia with Hemiparesis/Hemiplegia' } },
                { END_STAGE_INFLAMMATORY_DISORDERS_WITH_RIGIDITY_DEFORMITY: { key: 'END_STAGE_INFLAMMATORY_DISORDERS_WITH_RIGIDITY_DEFORMITY', value: 9, name: 'End Stage Inflammatory Disorders with rigidity / deformity' } },
                { RHEUMATOID_ARTHRITIS: { key: 'RHEUMATOID_ARTHRITIS', value: 10, name: 'Rheumatoid Arthritis' } },
                { ANKYLOSING_SPONDYLITIS: { key: 'ANKYLOSING_SPONDYLITIS', value: 11, name: 'Ankylosing Spondylitis' } },
                { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 12, name: 'Degenerative Scoliosis' } },
                { SEVERE_SKIN_DISEASE: { key: 'SEVERE_SKIN_DISEASE', value: 13, name: 'Severe Skin Disease' } },
                { GRADE_IV_OA_KNEE: { key: 'GRADE_IV_OA_KNEE', value: 14, name: 'Grade IV O.A Knee' } },
                { SEVERE_OA_HIP: { key: 'SEVERE_OA_HIP', value: 15, name: 'Severe O.A Hip' } },
                { SEVERE_OA_SHOULDER: { key: 'SEVERE_OA_SHOULDER', value: 16, name: 'Severe O.A Shoulder' } },
            ],
        },
    ])
    useEffect(() => {
        if (centerId) {
            getCategorizationList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getCategorizationList(centerId, page, rowsPerPage)
    }, [page]);
    const getCategorizationList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getCategorizationListAPI(centerId, params?.patient_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCategorizationList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddCategorizationClick = () => {
        history.push(`/patient/categorization/add/${params?.patient_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/categorization/edit/${params?.patient_id}/${id}`)
    }
    const getNameofSelectedOptions = (ids, key) => {
        console.log(ids, 'ids')
        let allDdata = null
        if (diagnosisQuestions) {
            for (let a of diagnosisQuestions) {
                let keyValueData = Object.keys(a)[0]
                if (keyValueData) {
                    if (keyValueData == key) {
                        allDdata = a[keyValueData]
                    }
                }

            }
        }

        console.log(allDdata, 'allDdata')
        console.log(diagnosisQuestions, 'diagnosisQuestions')
        ids = ids.map(Number);
        if (allDdata) {
            const filteredData = allDdata.filter(item => {
                const value = Object.values(item)[0].value;
                return ids.includes(value);
            });
            console.log(filteredData, 'filteredData')
            // return filteredData
            const names = filteredData.map(item => Object.values(item)[0].name).join(', ');
            console.log(names, 'names')
            return names
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Categorization" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Categorization
                        {(permissions.includes("PATIENT_CSREF_FORM_6_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddCategorizationClick()}>
                                Add Categorization
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                    <Table striped className="mb-0">
                        <thead>
                            <tr>
                                <th>Grade 1</th>
                                <th>Grade 2</th>
                                <th>Grade 3</th>
                                <th>Grade 4</th>
                                <th>Created At</th>
                                {(permissions.includes("PATIENT_CATEGORIZATION_UPDATE") || role == "SUPER_ADMIN") && (
                                    <th>Action</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {categorizationList && categorizationList.map((item, index) => {
                                // console.log(getNameofSpinal(item?.spinal),'getNameofSpinal(item?.spinal)')
                                // console.log(getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', '),'getNameofSpinal(item?.spinal)dfd')
                                // let spinalName = getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', ')
                                return (
                                    <tr key={index}>
                                        <td>{getNameofSelectedOptions(item?.grade_1, 'grade_1')}</td>
                                        <td>{getNameofSelectedOptions(item?.grade_2, 'grade_2')}</td>
                                        <td>{getNameofSelectedOptions(item?.grade_3, 'grade_3')}</td>
                                        <td>{getNameofSelectedOptions(item?.grade_4, 'grade_4')}</td>
                                        <td>{dateTimeFormate(item?.created_at)}</td>
                                        {(permissions.includes("PATIENT_CATEGORIZATION_UPDATE") || role == "SUPER_ADMIN") && (
                                            <td>
                                                <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}


                        </tbody>
                    </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default DiagnosisList;
