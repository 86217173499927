import React from 'react';
import { Link } from 'react-router-dom';
const BreadCrumb = ({ items }) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={index === items.length - 1 ? "breadcrumb-item active" : "breadcrumb-item"}
                    >
                        {index !== items.length - 1 ? (
                            <Link to={item.url || "#"}>{item.label}</Link>
                        ) : (
                            item.label
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};
export default BreadCrumb