import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback,

} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitTendinopathiesAPI, submiTendinopathiesEditAPI, getTendinopathiesDetailAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const DiagnosisAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [questionValue, setQuestionValue] = useState({});
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            question: 'Left Shoulder',
            key: 'left_shoulder',
            description: 'Tick all that apply',
            options: [
                { SUBACROMIAL_BURSITIS: { key: 'SUBACROMIAL_BURSITIS', value: 1, name: 'Subacromial Bursitis' } },
                { ADHESIVE_CAPSULITIS: { key: 'ADHESIVE_CAPSULITIS', value: 2, name: 'Adhesive Capsulitis' } },
                { SUPRASPINATUS_TENDINITIS: { key: 'SUPRASPINATUS_TENDINITIS', value: 3, name: 'Supraspinatus Tendinitis' } },
                { BICIPITAL_TENDINITIS: { key: 'BICIPITAL_TENDINITIS', value: 4, name: 'Bicipital Tendinitis' } },
                { RADICULAR: { key: 'RADICULAR', value: 5, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 6, name: 'None' } }
            ],
        },
        {
            question: 'Right Shoulder',
            key: 'right_shoulder',
            description: 'Tick all that apply',
            options: [
                { SUBACROMIAL_BURSITIS: { key: 'SUBACROMIAL_BURSITIS', value: 1, name: 'Subacromial Bursitis' } },
                { ADHESIVE_CAPSULITIS: { key: 'ADHESIVE_CAPSULITIS', value: 2, name: 'Adhesive Capsulitis' } },
                { SUPRASPINATUS_TENDINITIS: { key: 'SUPRASPINATUS_TENDINITIS', value: 3, name: 'Supraspinatus Tendinitis' } },
                { BICIPITAL_TENDINITIS: { key: 'BICIPITAL_TENDINITIS', value: 4, name: 'Bicipital Tendinitis' } },
                { RADICULAR: { key: 'RADICULAR', value: 5, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 6, name: 'None' } }
            ],
        },

        {
            question: 'Left Knee',
            key: 'left_knee',
            description: 'Tick all that apply',
            options: [
                { MENISCUS_TEAR_MEDIAL: { key: 'MENISCUS_TEAR_MEDIAL', value: 1, name: 'Meniscus Tear Medial' } },
                { MENISCUS_TEAR_LATERAL: { key: 'MENISCUS_TEAR_LATERAL', value: 2, name: 'Meniscus Tear Lateral' } },
                { CRUCIATE_INJURY_ANTERIOR: { key: 'CRUCIATE_INJURY_ANTERIOR', value: 3, name: 'Cruciate Injury Anterior' } },
                { CRUCIATE_INJURY_POSTERIOR: { key: 'CRUCIATE_INJURY_POSTERIOR', value: 4, name: 'Cruciate Injury Posterior' } },
                { BURSITIS_SUPRAPATELLAR: { key: 'BURSITIS_SUPRAPATELLAR', value: 5, name: 'Bursitis Suprapatellar' } },
                { BURSITIS_PREPATELLAR: { key: 'BURSITIS_PREPATELLAR', value: 6, name: 'Bursitis Prepatellar' } },
                { BURSITIS_INFRAPATELLAR: { key: 'BURSITIS_INFRAPATELLAR', value: 7, name: 'Bursitis Infrapatellar' } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },
        {
            question: 'Right Knee',
            key: 'right_knee',
            description: 'Tick all that apply',
            options: [
                { MENISCUS_TEAR_MEDIAL: { key: 'MENISCUS_TEAR_MEDIAL', value: 1, name: 'Meniscus Tear Medial' } },
                { MENISCUS_TEAR_LATERAL: { key: 'MENISCUS_TEAR_LATERAL', value: 2, name: 'Meniscus Tear Lateral' } },
                { CRUCIATE_INJURY_ANTERIOR: { key: 'CRUCIATE_INJURY_ANTERIOR', value: 3, name: 'Cruciate Injury Anterior' } },
                { CRUCIATE_INJURY_POSTERIOR: { key: 'CRUCIATE_INJURY_POSTERIOR', value: 4, name: 'Cruciate Injury Posterior' } },
                { BURSITIS_SUPRAPATELLAR: { key: 'BURSITIS_SUPRAPATELLAR', value: 5, name: 'Bursitis Suprapatellar' } },
                { BURSITIS_PREPATELLAR: { key: 'BURSITIS_PREPATELLAR', value: 6, name: 'Bursitis Prepatellar' } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            question: 'Left Elbow',
            key: 'left_elbow',
            description: 'Tick all that apply',
            options: [
                { TENNIS_ELBOW: { key: 'TENNIS_ELBOW', value: 1, name: 'Tennis Elbow' } },
                { GOLFERS_ELBOW: { key: 'GOLFERS_ELBOW', value: 2, name: "Golfer's Elbow" } },
                { RADICULAR: { key: 'RADICULAR', value: 3, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 4, name: 'None' } }
            ],
        },
        {
            question: 'Right Elbow',
            key: 'right_elbow',
            description: 'Tick all that apply',
            options: [
                { TENNIS_ELBOW: { key: 'TENNIS_ELBOW', value: 1, name: 'Tennis Elbow' } },
                { GOLFERS_ELBOW: { key: 'GOLFERS_ELBOW', value: 2, name: "Golfer's Elbow" } },
                { RADICULAR: { key: 'RADICULAR', value: 3, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 4, name: 'None' } }
            ],
        },
        {
            question: 'Left Hand/Wrist',
            key: 'left_hand_wrist',
            description: 'Tick all that apply',
            options: [
                { DE_QUERVAINS_DISEASE: { key: 'DE_QUERVAINS_DISEASE', value: 1, name: "De Quervain's Disease" } },
                { GANGLION_CYST: { key: 'GANGLION_CYST', value: 2, name: "Ganglion Cyst" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB', value: 3, name: "Flexor Tenosynovitis Trigger Thumb" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER', value: 4, name: "Flexor Tenosynovitis Trigger Finger" } },
                { DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME: { key: 'DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME', value: 5, name: "Dupuytren's Contracture Carpel Tunnel Syndrome" } },
                { TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES: { key: 'TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES', value: 6, name: "Triangular Fibrocartilage Complex Injuries" } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            question: 'Right Hand/Wrist',
            key: 'right_hand_wrist',
            description: 'Tick all that apply',
            options: [
                { DE_QUERVAINS_DISEASE: { key: 'DE_QUERVAINS_DISEASE', value: 1, name: "De Quervain's Disease" } },
                { GANGLION_CYST: { key: 'GANGLION_CYST', value: 2, name: "Ganglion Cyst" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_THUMB', value: 3, name: "Flexor Tenosynovitis Trigger Thumb" } },
                { FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER: { key: 'FLEXOR_TENOSYNOVITIS_TRIGGER_FINGER', value: 4, name: "Flexor Tenosynovitis Trigger Finger" } },
                { DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME: { key: 'DUPUYTRENS_CONTRACTURE_CARPEL_TUNNEL_SYNDROME', value: 5, name: "Dupuytren's Contracture Carpel Tunnel Syndrome" } },
                { TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES: { key: 'TRIANGULAR_FIBROCARTILAGE_COMPLEX_INJURIES', value: 6, name: "Triangular Fibrocartilage Complex Injuries" } },
                { RADICULAR: { key: 'RADICULAR', value: 7, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 8, name: 'None' } }
            ],
        },
        {
            question: 'Left Ankle/Foot',
            key: 'left_ankle_foot',
            description: 'Tick all that apply',
            options: [
                { PLANTAR_FASCIITIS: { key: 'PLANTAR_FASCIITIS', value: 1, name: "Plantar Fasciitis" } },
                { RETROCALCANEAL_BURSITIS: { key: 'RETROCALCANEAL_BURSITIS', value: 2, name: "Retrocalcaneal Bursitis" } },
                { TENDOACHILLES_TENDINOSIS: { key: 'TENDOACHILLES_TENDINOSIS', value: 3, name: "TendoAchilles Tendinosis" } },
                { CLAW_TOES: { key: 'CLAW_TOES', value: 4, name: "Claw Toes" } },
                { PAINFUL_BUNION_BIG_TOE: { key: 'PAINFUL_BUNION_BIG_TOE', value: 5, name: "Painful Bunion Big toe" } },
                { HALLUX_VAGUS: { key: 'HALLUX_VAGUS', value: 6, name: "Hallux Vagus" } },
                { HALLUX_RIGIDUS: { key: 'HALLUX_RIGIDUS', value: 7, name: "Hallux Rigidus" } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },
        {
            question: 'Right Ankle/Foot',
            key: 'right_ankle_foot',
            description: 'Tick all that apply',
            options: [
                { PLANTAR_FASCIITIS: { key: 'PLANTAR_FASCIITIS', value: 1, name: "Plantar Fasciitis" } },
                { RETROCALCANEAL_BURSITIS: { key: 'RETROCALCANEAL_BURSITIS', value: 2, name: "Retrocalcaneal Bursitis" } },
                { TENDOACHILLES_TENDINOSIS: { key: 'TENDOACHILLES_TENDINOSIS', value: 3, name: "TendoAchilles Tendinosis" } },
                { CLAW_TOES: { key: 'CLAW_TOES', value: 4, name: "Claw Toes" } },
                { PAINFUL_BUNION_BIG_TOE: { key: 'PAINFUL_BUNION_BIG_TOE', value: 5, name: "Painful Bunion Big toe" } },
                { HALLUX_VAGUS: { key: 'HALLUX_VAGUS', value: 6, name: "Hallux Vagus" } },
                { HALLUX_RIGIDUS: { key: 'HALLUX_RIGIDUS', value: 7, name: "Hallux Rigidus" } },
                { RADICULAR: { key: 'RADICULAR', value: 8, name: 'Radicular' } },
                { NONE: { key: 'NONE', value: 9, name: 'None' } }
            ],
        },

    ]);

    useEffect(() => {
        if (params?.tendinopathies_id) {
            getDiagnosisDetail(params?.tendinopathies_id)

        }
    }, [params?.tendinopathies_id]);


    const getDiagnosisDetail = async (patientId) => {
        setIsLoader(true)
        const response = await getTendinopathiesDetailAPI(userUtil?.userData?.center_id, patientId, params?.tendinopathies_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let left_shoulder = response?.data?.left_shoulder
            left_shoulder = left_shoulder.map(Number);

            let right_shoulder = response?.data?.right_shoulder
            right_shoulder = right_shoulder.map(Number);

            let left_knee = response?.data?.left_knee
            left_knee = left_knee.map(Number);

            let right_knee = response?.data?.right_knee
            right_knee = right_knee.map(Number);

            let left_elbow = response?.data?.left_elbow
            left_elbow = left_elbow.map(Number);

            let right_elbow = response?.data?.right_elbow
            right_elbow = right_elbow.map(Number);

            let left_hand_wrist = response?.data?.left_hand_wrist
            left_hand_wrist = left_hand_wrist.map(Number);

            let right_hand_wrist = response?.data?.right_hand_wrist
            right_hand_wrist = right_hand_wrist.map(Number);

            let left_ankle_foot = response?.data?.left_ankle_foot
            left_ankle_foot = left_ankle_foot.map(Number);

            let right_ankle_foot = response?.data?.right_ankle_foot
            right_ankle_foot = right_ankle_foot.map(Number);

            let dataToStore = {
                left_shoulder: left_shoulder,
                right_shoulder: right_shoulder,
                left_knee: left_knee,
                right_knee: right_knee,
                left_elbow: left_elbow,
                right_elbow: right_elbow,
                left_hand_wrist: left_hand_wrist,
                right_hand_wrist: right_hand_wrist,
                left_ankle_foot: left_ankle_foot,
                right_ankle_foot: right_ankle_foot,
            }
            setQuestionValue(dataToStore)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')

        if (params?.tendinopathies_id) {
            handleSubmitUserEdit();
        } else {
            handleSubmitUser();
        }


    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
        }
        questionValue['id'] = params?.tendinopathies_id
        questionValue['customCreatedAt'] =customCreatedAt
        const response = await submiTendinopathiesEditAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Tendinopathies Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/tendinopathies/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        questionValue['customCreatedAt'] =customCreatedAt
        const response = await submitTendinopathiesAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Tendinopathies Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/tendinopathies/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (e, data, id = null) => {
        console.log(data.key, 'data.key')
        console.log(id, 'id')
        var selectedValues = [];

        const checkbox = document.getElementById(data.key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        console.log(checkbox, 'checkbox')
        if (checkbox) {
            checkbox.checked = true;
            console.log(checkbox.checked, 'checkbox')
        } else {
            console.error('Checkbox not found');
        }

        var checkboxes = document.getElementsByName(data?.key);

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                selectedValues.push(checkboxes[i].value);
            }
        }
        questionValue[data?.key] = selectedValues
        setQuestionValue(questionValue)
        console.log(questionValue, 'selectedValues selectedValues')
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Tendinopathies And Bursitis", url: `/patient/tendinopathies/list/${params?.patient_id}` },
                        { label: params?.tendinopathies_id ? "Edit Tendinopathies And Bursitis" : "Add Tendinopathies And Bursitis" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.tendinopathies_id ? "Edit" : "Add"}{" "} Tendinopathies And Bursitis</CardTitle>
                            <Row>

                                {diagnosisQuestions && diagnosisQuestions.map((item, index) => {
                                    return (
                                        <Col md="6" >
                                            {!params?.tendinopathies_id ?
                                                <>
                                                    <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                        <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                        {item?.options && Object.keys(item?.options).map(itemOption => {
                                                            let key = Object.keys(item?.options[itemOption])
                                                            console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                            let optionItem = item?.options[itemOption][key]
                                                            let optionValue = item?.options[itemOption][key]?.value
                                                            let optionName = item?.options[itemOption][key]?.name
                                                            let optionKey = item?.options[itemOption][key]?.key

                                                            console.log(questionValue, 'questionValue')

                                                            let isChecked = false;

                                                            let SelectedValues = questionValue[item?.key]
                                                            if (SelectedValues) {
                                                                SelectedValues = SelectedValues.map(Number)
                                                                if (SelectedValues) {
                                                                    for (let s of SelectedValues) {
                                                                        if (s == optionValue) {
                                                                            isChecked = true
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            return (
                                                                <FormGroup check style={{ marginLeft: '30px' }}>
                                                                    <Label check>
                                                                        <Input name={item?.key} defaultChecked={isChecked} defaultValue={optionValue} type={"checkbox"}
                                                                            onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                        />
                                                                        {optionName}
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        })}

                                                    </FormGroup>

                                                </>
                                                :
                                                <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                    <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                    {item?.options && Object.keys(item?.options).map(itemOption => {
                                                        let key = Object.keys(item?.options[itemOption])
                                                        console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                        let optionItem = item?.options[itemOption][key]
                                                        let optionValue = item?.options[itemOption][key]?.value
                                                        let optionName = item?.options[itemOption][key]?.name
                                                        let optionKey = item?.options[itemOption][key]?.key

                                                        console.log(questionValue, 'questionValue')

                                                        let isChecked = false;

                                                        let SelectedValues = questionValue[item?.key]
                                                        if (SelectedValues) {
                                                            SelectedValues = SelectedValues.map(Number)
                                                            if (SelectedValues) {
                                                                for (let s of SelectedValues) {
                                                                    if (s == optionValue) {
                                                                        isChecked = true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        console.log(isChecked, 'isChecked isChecked isChecked isChecked')
                                                        return (
                                                            <FormGroup check style={{ marginLeft: '30px' }}>
                                                                <Label check>
                                                                    <Input name={item?.key} id={item?.key + "_" + optionValue} checked={isChecked} defaultValue={optionValue} type={"checkbox"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                    {optionName}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    })}

                                                </FormGroup>
                                            }
                                        </Col>
                                    )
                                })}
<FormGroup>
                                    <Label for="cus_created_at">Custom Created At</Label>
                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                </FormGroup>
                            </Row>

                            {!params?.tendinopathies_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DiagnosisAdd;
