import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitECDIFormAPI, getSpecificECDIAPI, updateECDIFormAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const CdiAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [finalPayloadData, setFinalPayloadData] = useState({
        "deformity_grade": null,
        "type": null,
        "lateral_static_straightening": null,
        "lateral_static_reversal": null,
        "lateral_static_lazy_s": null,
        "flexion_straightening": null,
        "flexion_reversal": null,
        "flexion_lazy_s": null,
        "extension_straightening": null,
        "extension_reversal": null,
        "extension_lazy_s": null
    });
    const [lateralStatic, setLateralStatic] = useState([
        {
            name: "Straightening", key: 'straightening',
        },
        {
            name: "Reversal", key: 'reversal',
        },
        {
            name: "Lazy S", key: 'lazy_s'
        },
    ]
    );


    useEffect(() => {
        // getProfessions()
        if (params?.e_cdi_id) {
            getPatientECDIDetail(params?.e_cdi_id)

        }
    }, [params?.e_cdi_id]);
    let patientId = params?.patient_id


    const getPatientECDIDetail = async (cdiId) => {
        const response = await getSpecificECDIAPI(centerId, patientId, cdiId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let data = response?.data
            delete data['created_at']
            delete data['created_by']
            delete data['patient_id']
            delete data['updated_at']
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
            setFinalPayloadData(data)
            console.log(data, 'data')
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }


    const handleClick = (e, key) => {
        console.log(finalPayloadData[key], 'finalPayloadData')
        finalPayloadData[key] = e.target.value
        console.log(finalPayloadData, 'finalPayloadData')
        setFinalPayloadData(finalPayloadData)
        console.log(finalPayloadData, 'finalPayloadData')
    }
    const handleSubmit = async (e) => {
        if (validData()) {
            toast("Select all required options!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return;
        } else {
            if (!params?.e_cdi_id) {
                finalPayloadData['customCreatedAt'] = customCreatedAt

                setIsLoader(true)
                const response = await submitECDIFormAPI(centerId, patientId, finalPayloadData, cancelTokenSource.token)
                setIsLoader(false)
                if (response.success) {
                    toast("Data Added Successfully!", {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'success'
                    });
                    // history.push(`/patient/extended-cdis/list/${params?.patient_id}`)
                    history.push(`/patient/dashboard/${params?.patient_id}`)
                } else {
                    toast(response?.system_message, {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                }
            } else {
                updateCDiForm()
            }
        }
    }
    const updateCDiForm = async (e) => {
        if (validData()) {
            toast("Select all required options!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return;
        }
        setIsLoader(true)
        finalPayloadData['customCreatedAt'] = customCreatedAt
        const response = await updateECDIFormAPI(centerId, patientId, params?.e_cdi_id, finalPayloadData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Data Added Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/extended-cdis/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }

    }

    const validData = () => {
        for (const key in finalPayloadData) {
            if (finalPayloadData.hasOwnProperty(key) && finalPayloadData[key] === null) {
                return false
            }
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Extended CDI", url: `/patient/extended-cdis/list/${params?.patient_id}` },
                        { label: params?.e_cdi_id ? "Edit CDI" : "Add CDI" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody >
                            <CardTitle className="flex-space-between">{params?.e_cdi_id ? "Edit" : "Add"}{" "} Extended CDI by Dr Afzal Hussain for Deformity Grading</CardTitle>
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <FormGroup style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                        <div>
                                            <Label for="f_name"><span style={{ marginRight: '5px' }}>1.</span>Lateral Static</Label>
                                            <p className="cdi-label-description">Mark only one oval per row</p>
                                        </div>
                                        <div class="table-responsive">
                                            <Table className="mb-0" >
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>None</th>
                                                        <th>Exists</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lateralStatic && lateralStatic.map((lateralStaticItem, lateralStaticIndex) => (
                                                        <tr key={lateralStaticIndex}>
                                                            {console.log(finalPayloadData['lateral_static' + "_" + lateralStaticItem?.key], 'hjabsjdasbjas')}
                                                            <td>{lateralStaticItem?.name}</td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'lateral_static' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['lateral_static' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'lateral_static' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'lateral_static' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['lateral_static' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'lateral_static' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    }
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'lateral_static' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['lateral_static' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'lateral_static' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'lateral_static' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['lateral_static' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'lateral_static' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    }
                                                                </FormGroup>
                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <FormGroup style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                        <div>
                                            <Label for="f_name"><span style={{ marginRight: '5px' }}>2.</span>Flexion</Label>
                                            <p className="cdi-label-description">Mark only one oval per row</p>
                                        </div>
                                        <div class="table-responsive">
                                            <Table className="mb-0" >
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>None</th>
                                                        <th>Exists</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lateralStatic && lateralStatic.map((lateralStaticItem, lateralStaticIndex) => (
                                                        <tr key={lateralStaticIndex}>
                                                            <td>{lateralStaticItem?.name}</td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'flexion' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['flexion' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'flexion' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'flexion' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['flexion' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'flexion' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    }
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'flexion' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['flexion' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'flexion' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'flexion' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['flexion' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'flexion' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    }
                                                                </FormGroup>
                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <FormGroup style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                        <div>
                                            <Label for="f_name"><span style={{ marginRight: '5px' }}>3.</span>Extension</Label>
                                            <p className="cdi-label-description">Mark only one oval per row</p>
                                        </div>
                                        <div class="table-responsive">
                                            <Table className="mb-0" >
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>None</th>
                                                        <th>Exists</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lateralStatic && lateralStatic.map((lateralStaticItem, lateralStaticIndex) => (
                                                        <tr key={lateralStaticIndex}>
                                                            <td>{lateralStaticItem?.name}</td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'extension' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['extension' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'extension' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'extension' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['extension' + "_" + lateralStaticItem?.key] == 'None' ? true : false}
                                                                                value={'None'} onChange={(e) => { handleClick(e, 'extension' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>}
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    {params?.e_cdi_id ?
                                                                        <Label check>
                                                                            <Input name={'extension' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                checked={finalPayloadData['extension' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'extension' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                        :
                                                                        <Label check>
                                                                            <Input name={'extension' + "_" + lateralStaticItem?.key} type={'radio'}
                                                                                defaultChecked={finalPayloadData['extension' + "_" + lateralStaticItem?.key] == 'Exists' ? true : false}
                                                                                value={'Exists'} onChange={(e) => { handleClick(e, 'extension' + "_" + lateralStaticItem?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    }
                                                                </FormGroup>
                                                            </td>


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <FormGroup style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                        <div>
                                            <Label for="f_name"><span style={{ marginRight: '5px' }}>4.</span>Deformity Grade (S,R,L)</Label>
                                            <p className="cdi-label-description">Mark only one oval</p>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '0' ? true : false}
                                                            value={'0'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}0 - Normal
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '0' ? true : false}
                                                            value={'0'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}0 - Normal
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '1' ? true : false}
                                                            value={'1'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - Olisthesis
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '1' ? true : false}
                                                            value={'1'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - Olisthesis
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '2' ? true : false}
                                                            value={'2'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - A (Dynamic)
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '2' ? true : false}
                                                            value={'2'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - A (Dynamic)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '3' ? true : false}
                                                            value={'3'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - B (Dynamic)
                                                    </Label> :
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '3' ? true : false}
                                                            value={'3'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - B (Dynamic)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '4' ? true : false}
                                                            value={'4'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - C (Dynamic)
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '4' ? true : false}
                                                            value={'4'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}I - C (Dynamic)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '5' ? true : false}
                                                            value={'5'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - A (Static)
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '5' ? true : false}
                                                            value={'5'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - A (Static)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '6' ? true : false}
                                                            value={'6'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - B (Static)
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '6' ? true : false}
                                                            value={'6'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - B (Static)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '7' ? true : false}
                                                            value={'7'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - C (Static)
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '7' ? true : false}
                                                            value={'7'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}II - C (Static)
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            checked={finalPayloadData['deformity_grade'] == '8' ? true : false}
                                                            value={'8'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}III - Rigid
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'deformity_grade'} type={'radio'}
                                                            defaultChecked={finalPayloadData['deformity_grade'] == '8' ? true : false}
                                                            value={'8'} onChange={(e) => { handleClick(e, 'deformity_grade') }} />
                                                        {" "}III - Rigid
                                                    </Label>
                                                }
                                            </FormGroup>
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <FormGroup style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                        <div>
                                            <Label for="f_name"><span style={{ marginRight: '5px' }}>5.</span>Type</Label>
                                            <p className="cdi-label-description">Mark only one oval</p>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '0' ? true : false}
                                                            value={'0'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Normal
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '0' ? true : false}
                                                            value={'0'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Normal
                                                    </Label>}
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '1' ? true : false}
                                                            value={'1'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Instability
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '1' ? true : false}
                                                            value={'1'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Instability
                                                    </Label>
                                                }

                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '2' ? true : false}
                                                            value={'2'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Mild
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '2' ? true : false}
                                                            value={'2'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Mild
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '3' ? true : false}
                                                            value={'3'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Moderate
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '3' ? true : false}
                                                            value={'3'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Moderate
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '4' ? true : false}
                                                            value={'4'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Severe
                                                    </Label>
                                                    :
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '4' ? true : false}
                                                            value={'4'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Severe
                                                    </Label>
                                                }
                                            </FormGroup>
                                            <FormGroup check>
                                                {params?.e_cdi_id ?
                                                    <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            checked={finalPayloadData['type'] == '5' ? true : false}
                                                            value={'5'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Very Severe
                                                    </Label>
                                                    : <Label check>
                                                        <Input name={'type'} type={'radio'}
                                                            defaultChecked={finalPayloadData['type'] == '5' ? true : false}
                                                            value={'5'} onChange={(e) => { handleClick(e, 'type') }} />
                                                        {" "}Very Severe
                                                    </Label>
                                                }
                                            </FormGroup>

                                        </div>

                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cus_created_at">Custom Created At</Label>
                                        <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!params?.e_cdi_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CdiAdd;
