import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getRolesListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
const RolesList = () => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [rolesList, setRolesList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [centerName, setCenterName] = useState(localStorage.getItem('centerName'));
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);

    useEffect(() => {
        if (centerId) {
            getRolesList()
        }
    }, [centerId]);
    const getRolesList = async () => {
        setIsLoader(true)
        const response = await getRolesListAPI(centerId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setRolesList(response?.data)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClickEdit = (e, id) => {
        history.push(`/role/edit/${id}`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            {role == "SUPER_ADMIN" ?
                <BreadCrumb items={[
                    { label: "Dashboard", url: "/" },
                    { label: "Center List", url: "/centers" },
                    { label: `Dashboard (${centerName})`, url: `/center/dashboard/${centerId}` },
                    { label: `Roles` },
                ]} />
                :
                <BreadCrumb items={[
                    { label: "Dashboard", url: "/" },
                    { label: "Roles" },
                ]} />
            }
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Roles List
                        {/* <button className="btn btn-primary" onClick={() => handleAddPatientClick()}>
                            Add Patient
                        </button> */}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ width: '75%' }}>Permissions</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rolesList && rolesList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.name}</td>
                                        <td>{item?.permissions && item?.permissions.map((item, index) => (
                                            <div className="mb-2 me-2 badge bg-info">{item?.permission?.permission_name}</div>
                                        ))}</td>
                                        <td>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.key) }}></i>
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>

                </CardBody>

            </Card>
        </div>
    );
};

export default RolesList;
