import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import MetisMenu from 'react-metismenu';

import { MainNav, CenterNav, StaffNav, machineNav, patientNav, rolesNav, ProfessionNav, TratmentNav, ReportNav, TestCategoryNav } from './NavItems';
import userUtil from '../../utils/UserUtils';
const Nav = (props) => {
    const history = useHistory();
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const isPathActive = (path) => {
        return props.location.pathname.startsWith(path);
    };
    const handleMenuItemClick = (event) => {
        event.preventDefault();
        // This function will be called when a menu item is clicked
        const menuItem = event.detail;
    };
    document.addEventListener("click", function (event) {
        const clickedElement = event.target;

        if (clickedElement.classList.contains("metismenu-link")) {
            event.preventDefault();
            handleTagClick(clickedElement);
        }
    });

    function handleTagClick(link) {
        const href = link.getAttribute("href");
        // Call your function with the href attribute
        removeActiveClasses()
        link.classList.add("active");
        history.push(href)
        // yourFunctionName(href);
    }

    const removeActiveClasses = () => {
        const metismenuLinks = document.querySelectorAll(".metismenu-link");
        metismenuLinks.forEach((link) => {
            link.classList.remove("active");
        });
    }

    return (
        <div>
            <h5 className="app-sidebar__heading">Dashboard</h5>
            <MetisMenu
                content={MainNav}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"

            />
            {JSON.parse(localStorage.getItem('userDetail'))?.role?.name == "SUPER_ADMIN" ? (
                <>
                    <h5 className="app-sidebar__heading">Center</h5>
                    <MetisMenu
                        content={CenterNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-angle-down"
                    />
                    <h5 className="app-sidebar__heading">Profession</h5>
                    <MetisMenu
                        content={ProfessionNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-angle-down"
                    />
                    <h5 className="app-sidebar__heading">Machine</h5>
                    <MetisMenu
                        content={machineNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-angle-down"
                    />
                    <h5 className="app-sidebar__heading">Reporting</h5>
                    <MetisMenu
                        content={ReportNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-server"
                    />
                     <h5 className="app-sidebar__heading">Test Categories</h5>
                    <MetisMenu
                        content={TestCategoryNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-server"
                    />
                </>
            ) : (
                <>

                    {permissions.includes("PATIENT_READ") && (
                        <>
                            <h5 className="app-sidebar__heading">Patient</h5>
                            <MetisMenu
                                content={patientNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu"
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                            />
                        </>
                    )}
                    {permissions.includes("MEMBER_READ") && (
                        <>
                            <h5 className="app-sidebar__heading">Member</h5>
                            <MetisMenu
                                content={StaffNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu"
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                            />
                        </>
                    )}
                    {permissions.includes("PATIENT_TREATMENT_READ") && (
                        <>
                            <h5 className="app-sidebar__heading">Treatments</h5>
                            <MetisMenu
                                content={TratmentNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu"
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                            />
                        </>
                    )}
                    {/* {permissions.includes("MACHINE_READ") && (
                        <>
                            <h5 className="app-sidebar__heading">Machine</h5>
                            <MetisMenu
                                content={machineNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu"
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                            />
                        </>
                    )} */}
                    {JSON.parse(localStorage.getItem('userDetail'))?.role?.name == "ADMIN" && (
                        <>
                            <h5 className="app-sidebar__heading">Roles</h5>
                            <MetisMenu
                                content={rolesNav}
                                activeLinkFromLocation
                                className="vertical-nav-menu"
                                iconNamePrefix=""
                                classNameStateIcon="pe-7s-angle-down"
                            />
                        </>
                    )}
                    <h5 className="app-sidebar__heading">Reporting</h5>
                    <MetisMenu
                        content={ReportNav}
                        activeLinkFromLocation
                        className="vertical-nav-menu"
                        iconNamePrefix=""
                        classNameStateIcon="pe-7s-server"
                    />

                   

                </>
            )}
        </div>
    );
};

export default Nav;
