import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getUserListByCenterIdAPI } from './../../../../api/network/Api';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../../api/helperComponents/Loader';
import BreadCrumb from '../../../../api/helperComponents/BreadCrumb';
import {dateTimeFormate} from './../../../../api/helperComponents/dateTimeFormat';
const CenterList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [userList, setUserList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        if (params?.center_id) {
            getUserListByCenterId(params?.center_id, page, rowsPerPage)
        }
    }, [params?.center_id]);
    const getUserListByCenterId = async (center_id, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getUserListByCenterIdAPI(center_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setUserList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddCenterClick = () => {
        history.push('/center/add')
    }
    const handleAddUserClick = (e, id) => {
        history.push(`/center/user/add/${params?.center_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/center/user/edit/${params?.center_id}/${id}`)
    }
    return (
        <div>
            {isLoader && <Loader />}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Centers", url: "/centers" },
                { label: "Users" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">User List
                        {userList && userList.length == 0 && (
                            <button className="btn btn-primary" onClick={() => handleAddUserClick()}>
                                Add User
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                    <Table striped className="mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>NIC</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {userList && userList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.email}</td>
                                    <td>{item?.phone}</td>
                                    <td>{item?.nic}</td>
                                    <td>
                                        <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                    </td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={count / rowsPerPage}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CenterList;
