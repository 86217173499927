import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitProfessionAPI, getprofessionDetailByIDAPI, submitProfessionEditAPI } from '../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
const ProfessionAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);


    useEffect(() => {
        if (params?.profession_id) {
            getProfessionDetail(params?.profession_id)
        }
    }, [params?.profession_id]);

    const getProfessionDetail = async (professionId) => {
        setIsLoader(true)
        const response = await getprofessionDetailByIDAPI(professionId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setName(response?.data?.name)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        console.log(name, 'name')
        if (name == '') {
            if (name == '') {
                setNameError(true)
            } else {
                setNameError(false)
            }
        } else {
            if (params?.profession_id) {
                handleSubmitDegreeEdit();
            } else {
                handleSubmitDegree();
            }

        }
    }
    const handleSubmitDegreeEdit = async () => {
        console.log('errpr')
        setIsLoader(true)
        let dataToSend = {
            id: params?.profession_id,
            name: name,
        }
        const response = await submitProfessionEditAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Profession Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/professions')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitDegree = async () => {
        console.log('errpr')
        setIsLoader(true)
        let dataToSend = {
            name: name,
        }
        const response = await submitProfessionAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Profession Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/professions')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Professions", url: "/professions" },
                        { label: params?.profession_id ? "Edit Profession" : "Add Profession" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.profession_id ? "Edit" : "Add"}{" "}Profession</CardTitle>

                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Profession Name" />
                                {console.log(nameError, 'nameError')}
                                {nameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ProfessionAdd;
