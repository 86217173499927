
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faSpinner,
);

function Loader(props) {
    return (
        <div className="loader-container">
            <FontAwesomeIcon
                icon={['fas', 'spinner']}
                pulse
                fixedWidth
                size="4x"
                color="white"
            />
            <p style={{color:'white',marginTop:'20px'}}>{props?.helperText}</p>
        </div>
    )
}

export default Loader;