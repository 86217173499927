import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Container, Label, FormGroup, Input
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getPatientTreatmentProgressReportAPI, getPatientSearchAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import Autosuggest from 'react-autosuggest';
const CenterList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [treatmentData, setTreatmentData] = useState({});
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [suggestions, setSuggestions] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedPatient, setSelectedPatient] = useState("");
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [initialScore, setInitialScore] = useState(0);
    const [finalScore, setFinalScore] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const inputProps = {
        placeholder: 'Search...',
        searchQuery
    };
    // useEffect(() => {
    //     console.log(selectedPatient, 'selectedPatient')
    //     if (startDate && endDate) {
    //         const startDateValue = new Date(startDate);
    //         const endDateValue = new Date(endDate);

    //         if (endDateValue < startDateValue) {
    //             setEndDateError("End date is greater than start date")
    //             console.log('Error: End date is greater than start date');
    //         } else {
    //             setEndDateError("")
    //             console.log('dates are valid');
    //             getPatientTreatmentList(centerId, selectedPatient)
    //         }

    //     }
    // }, [startDate, endDate, page]);
    // useEffect(() => {
    //     console.log(selectedPatient, 'selectedPatient')
    //     // if(selectedPatient){
    //     getPatientTreatmentList(centerId, selectedPatient)
    //     // }
    // }, [page]);
    useEffect(() => {
        const elements = document.querySelectorAll('.react-autosuggest__suggestions-container');
        if (suggestions.length == 0) {
            elements.forEach(element => {
                element.style.display = 'none';
            });
        } else {
            elements.forEach(element => {
                element.style.display = 'block';
            });
        }
    }, [suggestions]);

    const getPatientTreatmentList = async (centerId, pateintId) => {
        setIsLoader(true)
        setPatientList([])
        const response = await getPatientTreatmentProgressReportAPI(centerId, pateintId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCount(response?.data?.report?.length)
            setPatientList(response?.data?.rows)
            setInitialScore(getIntialScore(response?.data?.report[0]))
            setFinalScore(getFinalScore(response?.data?.report[response?.data?.report?.length - 1]))
            setAverageScore(getAverageScore(response?.data?.report))
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getIntialScore = (data) => {
        return data?.cervical_range_motion?.score + data?.cervical_spine?.score + data?.leg_level?.score + data?.leg_weakness?.score + data?.lumbar_spine?.score + data?.s_i?.score + data?.shoulder_level?.score + data?.shoulder_weakness?.score + data?.thoracic_spine?.score
    }
    const getFinalScore = (data) => {
        return data?.cervical_range_motion?.score + data?.cervical_spine?.score + data?.leg_level?.score + data?.leg_weakness?.score + data?.lumbar_spine?.score + data?.s_i?.score + data?.shoulder_level?.score + data?.shoulder_weakness?.score + data?.thoracic_spine?.score
    }
    const getAverageScore = (data) => {
        let totalScore = 0;
        let count = data.length;

        // Iterate over each object in the array
        data.forEach(item => {
            // Access the 'score' property of each object and add it to the total score
            totalScore += item.cervical_spine.score +
                item.thoracic_spine.score +
                item.lumbar_spine.score +
                item.s_i.score +
                item.shoulder_weakness.score +
                item.leg_weakness.score +
                item.shoulder_level.score +
                item.leg_level.score +
                item.cervical_range_motion.score;
        });

        // Calculate the average score
        let averageScore = totalScore / count;

        return averageScore;
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const onSuggestionsFetchRequested = async ({ value }) => {
        setIsLoader(true)
        setSearchQuery(value)
        const response = await getPatientSearchAPI(centerId, value, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setSuggestions(response?.data?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    };
    const onSuggestionsClearRequested = (e) => {
        console.log(searchQuery, 'onSuggestionsClearRequested')
        if (searchQuery == "") {
            setSuggestions([])
            setSelectedPatient("")
            // getPatientTreatmentList(centerId, "", page)
        }
    };
    const onSuggestionSelected = (event, { suggestion }) => {
        console.log(suggestion?.id, 'suggestion onSuggestionSelected')
        setSelectedPatient(suggestion?.id)
        setPatientList(null)
        getPatientTreatmentList(centerId, suggestion?.id)
    };
    const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

    const renderInputComponent = inputProps => (
        <input {...inputProps} />
    );
    const onChange = (event, { newValue }) => {
        setSearchQuery(newValue);
    };
    const getSuggestionValue = suggestion => suggestion.name;
    return (
        <div>
            {isLoader && (<Loader />)}


            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Visit Progress Report
                    </CardTitle>
                    <div>
                        <Row className="treatment-liest-container">
                            <Col md="4">
                                <Label>Search By Patient Name</Label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    onSuggestionSelected={onSuggestionSelected}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                        placeholder: 'Search By Name...',
                                        value: searchQuery, // Make sure 'value' is properly defined
                                        onChange: onChange,
                                        className: 'form-control'
                                    }}
                                />
                            </Col>

                        </Row>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <div class="table-responsive">

                            <Table striped className="mb-0" id="data-table">
                                <thead>
                                    <tr>
                                        {/* <th>Patient Name</th> */}
                                        <th>Total Visit</th>
                                        <th>Initial Score</th>
                                        <th>Final Score</th>
                                        <th>Average Score</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>{count}</td>
                                        <td>{initialScore.toFixed(2)}</td>
                                        <td>{finalScore.toFixed(2)}</td>
                                        <td>{averageScore.toFixed(2)}</td>

                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </CardBody>

            </Card>
        </div >
    );
};

export default CenterList;
