import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Container, Label, FormGroup, Input
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getPatientTreatmentListAPIFilter, getPatientSearchAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import Autosuggest from 'react-autosuggest';
const CenterList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [treatmentData, setTreatmentData] = useState({});
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [suggestions, setSuggestions] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedPatient, setSelectedPatient] = useState("");
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const inputProps = {
        placeholder: 'Search...',
        searchQuery
    };
    useEffect(() => {
        console.log(selectedPatient, 'selectedPatient')
        if (startDate && endDate) {
            const startDateValue = new Date(startDate);
            const endDateValue = new Date(endDate);

            if (endDateValue < startDateValue) {
                setEndDateError("End date is greater than start date")
                console.log('Error: End date is greater than start date');
            } else {
                setEndDateError("")
                console.log('dates are valid');
                getPatientTreatmentList(centerId, selectedPatient, startDate, endDate, rowsPerPage, page)
            }

        }
    }, [startDate, endDate, page]);
    useEffect(() => {
        console.log(selectedPatient, 'selectedPatient')
        // if(selectedPatient){
        getPatientTreatmentList(centerId, selectedPatient, startDate, endDate, rowsPerPage, page)
        // }
    }, [page]);
    useEffect(() => {
        const elements = document.querySelectorAll('.react-autosuggest__suggestions-container');
        if (suggestions.length == 0) {
            elements.forEach(element => {
                element.style.display = 'none';
            });
        } else {
            elements.forEach(element => {
                element.style.display = 'block';
            });
        }
    }, [suggestions]);

    const getPatientTreatmentList = async (centerId, pateintId, startDate, endDate, rowsPerPage, page) => {
        setIsLoader(true)
        setPatientList([])
        const response = await getPatientTreatmentListAPIFilter(centerId, pateintId, startDate, endDate, rowsPerPage, page, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCount(response?.data?.count)
            setPatientList(response?.data?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const onSuggestionsFetchRequested = async ({ value }) => {
        setIsLoader(true)
        setSearchQuery(value)
        const response = await getPatientSearchAPI(centerId, value, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setSuggestions(response?.data?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    };
    const onSuggestionsClearRequested = (e) => {
        console.log(searchQuery, 'onSuggestionsClearRequested')
        if (searchQuery == "") {
            setSuggestions([])
            setSelectedPatient("")
            getPatientTreatmentList(centerId, "", startDate, endDate, rowsPerPage, page)
        }
    };
    const onSuggestionSelected = (event, { suggestion }) => {
        console.log(suggestion?.id, 'suggestion onSuggestionSelected')
        setSelectedPatient(suggestion?.id)
        setPatientList(null)
        getPatientTreatmentList(centerId, suggestion?.id, startDate, endDate, rowsPerPage, page)
    };
    const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

    const renderInputComponent = inputProps => (
        <input {...inputProps} />
    );
    const onChange = (event, { newValue }) => {
        setSearchQuery(newValue);
    };
    const getSuggestionValue = suggestion => suggestion.name;
    return (
        <div>
            {isLoader && (<Loader />)}

            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Treatment List" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Treatment List
                        {/* <button className="btn btn-primary" onClick={(e) => handleAddPatientTreatmentClick()}>
                            Add Treatment
                        </button> */}
                    </CardTitle>
                    <div>
                        <Row className="treatment-liest-container">
                            <Col md="3">
                                <Label>Search By Patient Name</Label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    onSuggestionSelected={onSuggestionSelected}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                        placeholder: 'Search By Name...',
                                        value: searchQuery, // Make sure 'value' is properly defined
                                        onChange: onChange,
                                        className: 'form-control'
                                    }}
                                />
                            </Col>
                            <Col md="3" >
                                <FormGroup>
                                    <Label for="f_name">Start Date</Label>
                                    <Input type="date" name="s_date" id="s_date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                    <span style={{ color: 'red' }}>{startDateError}</span>
                                </FormGroup>
                            </Col>
                            <Col md="3" >
                                <FormGroup>
                                    <Label for="f_name">End Date</Label>
                                    <Input type="date" name="e_date" id="e_date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                    <span style={{ color: 'red' }}>{endDateError}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {patientList && patientList.map((item, index) => {
                            return (
                                <div style={{
                                    marginTop: '30px',
                                    padding: '10px',
                                    backgroundColor: '#47aaa830',
                                    borderRadius: '15px'
                                }}
                                // style={{ borderRadius: '15px', border: '1px solid silver',padding:'10px' }}
                                >
                                    <Container>
                                        <Row className="treatment-liest-container">
                                            <Row>
                                                <Col md="3" className="border-1">
                                                    <p>Pateint Name: {item?.patient_info?.name}</p>
                                                </Col>
                                                <Col md="1" className="border-1">
                                                    <p>Age: {item?.patient_info?.age}</p>
                                                </Col>
                                                <Col md="2" className="border-1">
                                                    <p>Contact #: {item?.patient_info?.contact_number}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Date Of Birth: {item?.patient_info?.dob ? item?.patient_info?.dob.split('T')[0] : ''}</p>
                                                </Col>
                                                <Col md="3" className="border-1 ">
                                                    <p>Position of Examining a balance: <span className="patient_treatment_selected">{item?.position_examination_balance}</span></p>
                                                </Col>
                                            </Row>
                                            <Col md="3" style={{ padding: '0px 0px 10px 10px' }}>
                                                <Row className={`border-1`}>
                                                    <Col md="5" className="border-1" style={{ height: '70px' }}>
                                                        <p>Visit: {item?.visit_number}</p>
                                                    </Col>
                                                    <Col md="7" className="border-1" style={{ height: '70px' }}>
                                                        <p>Date</p>
                                                        <p>{item?.date}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1`} style={{ borderTop: '0px' }}>
                                                    <Col md="12" style={{ height: '161px', padding: '5px' }}>
                                                        <p>Remarks</p>
                                                        <p>{item?.remark}</p>
                                                    </Col>

                                                </Row>

                                            </Col>
                                            <Col md="2" style={{ marginRight: '0px', paddingRight: '0px' }}>
                                                <div className={`center-align border-1`} style={{ borderLeft: '0px' }}>Atlas Rotation</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', textAlign: 'center' }}>
                                                        <p>Right</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_right == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_right == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px', textAlign: 'center' }}>
                                                        <p>Left</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_left == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_left == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '76px' }}>Cervical Range of Motion</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    R
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>



                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    L
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0  margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {item?.shoulder_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {item?.shoulder_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Pelvic Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.pelvic_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.pelvic_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Length Difference</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_length_diff_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_length_diff_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.shoulder_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.shoulder_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '30px' }}>Edema of Thoracic Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${item?.edema_thoracic_spine_t1 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T1</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${item?.edema_thoracic_spine_t2 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T2</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t3 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T3</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t4 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T4</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t5 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T5</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t6 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T6</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t7 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T7</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t8 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T8</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t9 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T9</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t10 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T10</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t11 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T11</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t12 == "Yes" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T12</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="3" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Edemas</div>
                                                <div className={`center-align border-1`}>Cervical Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {item?.cervical_range_motion_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {item?.cervical_range_motion_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>L. Sacral Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L1</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l1}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L2</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l2}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L3</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l3}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L4</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l4}</p>
                                                    </Col>
                                                    <Col md="3" className="center-align padding-0 border-1 margin-0">
                                                        <p>L5</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l5}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="12" className="center-align padding-0 margin-0">
                                                            <p>Scaro-Illiac Joint</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>

                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Right SI</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Left SI</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{item.right_si}</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{item.left_si}</p>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )
                        })}
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CenterList;
