import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter, useHistory } from 'react-router-dom';
import axios, { CancelTokenSource } from "axios";

import ResizeDetector from 'react-resize-detector';

import AppMain from '../../Layout/AppMain';
import { Switch } from 'react-router-dom';
const Main = (props) => {

    const history = useHistory();
    const cancelTokenSource = axios.CancelToken.source();
    const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
    const [width, setWidth] = useState(undefined);

    useEffect(() => {
        axios.defaults.headers = { 'x-auth-token': localStorage.getItem('user-token') };
        const token = localStorage.getItem('user-token');
        const path = window.location.pathname;

        if (!token) {
            if (path.includes('resetPassword')) {
                history.push(path);
            } else if (path.includes('forgot-password')) {
                history.push('/forgot-password');
            } else {
                history.push('/login');
            }
        } else {
            history.push('/');
        }
    }, [history]);

    const onResize = (newWidth) => setWidth(newWidth);

    const {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        enableMobileMenu,
    } = props;

    useEffect(() => {
        setClosedSmallerSidebar(width < 1250);
    }, [width]);

    return (
        <div className={cx(
            "app-container app-theme-" + colorScheme,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
            { 'sidebar-mobile-open': enableMobileMenu },
        )}>
            <Switch>
                <AppMain />
            </Switch>
            <ResizeDetector handleWidth onResize={onResize} />
        </div>
    );
};

const mapStateToProps = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProps)(Main));
