import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback,

} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitDiagnosisAPI, getDiagnosisDetailAPI, submitDiagnosisEditAPI, getProfessionListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const DiagnosisAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [questionValue, setQuestionValue] = useState({});
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            question: 'Spinal',
            key: 'spinal',
            description: 'Tick all that apply',
            options: [
                { SPONDYLOSIS: { key: 'SPONDYLOSIS', value: 1, name: 'Spondylosis' } },
                { SPONDYLOSIS: { key: 'DDD', value: 2, name: 'DDD' } },
                { DISC_BULGE: { key: 'DISC_BULGE', value: 3, name: 'Disc Bulge' } },
                { DISC_PROTRUSION: { key: 'DISC_PROTRUSION', value: 4, name: 'Disc Protrusion' } },
                { SPONDYLOLISTHESIS: { key: 'SPONDYLOLISTHESIS', value: 5, name: 'Spondylolisthesis' } },
                { SCOLIOSIS: { key: 'SCOLIOSIS', value: 6, name: 'Scoliosis' } },
                { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 7, name: 'Degenerative Scoliosis' } },
                { OSTEOPOROSIS: { key: 'OSTEOPOROSIS', value: 8, name: 'Osteoporosis' } },
                { OCHRONOSIS: { key: 'OCHRONOSIS', value: 9, name: 'Ochronosis' } },
                { COCCYDYNIA: { key: 'COCCYDYNIA', value: 10, name: 'Coccydynia' } },
                { ANKYLOSIS: { key: 'ANKYLOSIS', value: 11, name: 'Ankylosis' } },
                { SPONDYLITIS: { key: 'SPONDYLITIS', value: 12, name: 'Spondylitis' } },
                { SPINAL_STENOSIS: { key: 'SPINAL_STENOSIS', value: 13, name: 'Spinal Stenosis' } },
                { RADICULITIS: { key: 'RADICULITIS', value: 14, name: 'Radiculitis' } },
                { RADICULOPATHY: { key: 'RADICULOPATHY', value: 15, name: 'Radiculopathy' } },
                { MYELOPATHY: { key: 'MYELOPATHY', value: 16, name: 'Myelopathy' } },
                { SPINOCEREBELLAR_DISEASE: { key: 'SPINOCEREBELLAR_DISEASE', value: 17, name: 'Spinocerebellar Disease' } },
                { MOTOR_NEURON_DISEASE: { key: 'MOTOR_NEURON_DISEASE', value: 18, name: 'Motor Neuron Disease' } },
                { SYRINGOMYELIA: { key: 'SYRINGOMYELIA', value: 19, name: 'Syringomyelia' } },
                { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 20, name: 'Cervical Dystonia' } },
                { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 21, name: 'Not Applicable' } },
                { OTHER: { key: 'OTHER', value: 22, name: 'Other' } },
            ],
        },
        {
            question: 'Rheumatoid Arthritis',
            key: 'rheumatoid_arthritis',
            description: 'Tick all that apply',
            options: [
                { JUVINILE: { key: 'JUVINILE', value: 1, name: 'Juvinile' } },
                { PAUCI_ARTICULAR: { key: 'PAUCI_ARTICULAR', value: 2, name: 'Pauci Articular' } },
                { POLY_ARTICUALAR: { key: 'POLY_ARTICUALAR', value: 3, name: 'Poly Articular' } },
                { RA_POSITIVE: { key: 'RA_POSITIVE', value: 4, name: '*RA +ve' } },
                { ANTI_CPP_POSITIVE: { key: 'ANTI_CPP_POSITIVE', value: 5, name: 'Anti-CCP +ve' } },
                { RA_NEGATIVE: { key: 'RA_NEGATIVE', value: 6, name: '*RA-ve' } },
                { ANTI_CPP_NEGATIVE: { key: 'ANTI_CPP_NEGATIVE', value: 7, name: 'Anti-CCP -ve' } },
                { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 8, name: 'Not Applicable' } },
            ],
        }
    ]);

    useEffect(() => {
        if (params?.diagnosis_id) {
            getDiagnosisDetail(params?.diagnosis_id)

        }
    }, [params?.diagnosis_id]);


    const getDiagnosisDetail = async (patientId) => {
        setIsLoader(true)
        const response = await getDiagnosisDetailAPI(userUtil?.userData?.center_id, patientId, params?.diagnosis_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let spinalData = response?.data?.spinal
            spinalData = spinalData.map(Number);
            let rheumatoid_arthritis = response?.data?.rheumatoid_arthritis
            rheumatoid_arthritis = rheumatoid_arthritis.map(Number);

            let dataToStore = {
                spinal: spinalData,
                rheumatoid_arthritis: rheumatoid_arthritis
            }
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
            setQuestionValue(dataToStore)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')

        if (params?.diagnosis_id) {
            handleSubmitUserEdit();
        } else {
            handleSubmitUser();
        }


    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
        }
        questionValue['id'] = params?.diagnosis_id
        questionValue['customCreatedAt'] = customCreatedAt

        const response = await submitDiagnosisEditAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Diagnosis Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/patient/dashboard/${params?.patient_id}`)
            // history.push(`/patient/diagnosis/list/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        questionValue['customCreatedAt'] = customCreatedAt
        const response = await submitDiagnosisAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Diagnosis Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/diagnosis/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (e, data, id = null) => {
        console.log(data.key, 'data.key')
        console.log(id, 'id')
        var selectedValues = [];

        const checkbox = document.getElementById(data.key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        console.log(checkbox, 'checkbox')
        if (checkbox) {
            checkbox.checked = true;
            console.log(checkbox.checked, 'checkbox')
        } else {
            console.error('Checkbox not found');
        }

        var checkboxes = document.getElementsByName(data?.key);

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                selectedValues.push(checkboxes[i].value);
            }
        }
        questionValue[data?.key] = selectedValues
        setQuestionValue(questionValue)
        console.log(questionValue, 'selectedValues selectedValues')
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Diagnosis", url: `/patient/diagnosis/list/${params?.patient_id}` },
                        { label: params?.diagnosis_id ? "Edit Diagnosis" : "Add Diagnosis" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.diagnosis_id ? "Edit" : "Add"}{" "} Diagnosis</CardTitle>
                            {diagnosisQuestions && diagnosisQuestions.map((item, index) => {
                                return (
                                    <>
                                        {!params?.diagnosis_id ?
                                            <>
                                                <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                    <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                    <p className="cdi-label-description">{item?.description}</p>
                                                    {item?.options && Object.keys(item?.options).map(itemOption => {
                                                        let key = Object.keys(item?.options[itemOption])
                                                        console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                        let optionItem = item?.options[itemOption][key]
                                                        let optionValue = item?.options[itemOption][key]?.value
                                                        let optionName = item?.options[itemOption][key]?.name
                                                        let optionKey = item?.options[itemOption][key]?.key

                                                        console.log(questionValue, 'questionValue')

                                                        let isChecked = false;

                                                        let SelectedValues = questionValue[item?.key]
                                                        if (SelectedValues) {
                                                            SelectedValues = SelectedValues.map(Number)
                                                            if (SelectedValues) {
                                                                for (let s of SelectedValues) {
                                                                    if (s == optionValue) {
                                                                        isChecked = true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <FormGroup check style={{ marginLeft: '30px' }}>
                                                                <Label check>
                                                                    <Input name={item?.key} defaultChecked={isChecked} defaultValue={optionValue} type={"checkbox"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                    {optionName}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    })}

                                                </FormGroup>

                                            </>
                                            :
                                            <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                {item?.options && Object.keys(item?.options).map(itemOption => {
                                                    let key = Object.keys(item?.options[itemOption])
                                                    console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                    let optionItem = item?.options[itemOption][key]
                                                    let optionValue = item?.options[itemOption][key]?.value
                                                    let optionName = item?.options[itemOption][key]?.name
                                                    let optionKey = item?.options[itemOption][key]?.key

                                                    console.log(questionValue, 'questionValue')

                                                    let isChecked = false;

                                                    let SelectedValues = questionValue[item?.key]
                                                    if (SelectedValues) {
                                                        SelectedValues = SelectedValues.map(Number)
                                                        if (SelectedValues) {
                                                            for (let s of SelectedValues) {
                                                                if (s == optionValue) {
                                                                    isChecked = true
                                                                }
                                                            }
                                                        }
                                                    }
                                                    console.log(isChecked, 'isChecked isChecked isChecked isChecked')
                                                    return (
                                                        <FormGroup check style={{ marginLeft: '30px' }}>
                                                            <Label check>
                                                                <Input name={item?.key} id={item?.key + "_" + optionValue} checked={isChecked} defaultValue={optionValue} type={"checkbox"}
                                                                    onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                />
                                                                {optionName}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })}

                                            </FormGroup>
                                        }
                                    </>
                                )
                            })}
                            <FormGroup>
                                <Label for="cus_created_at">Custom Created At</Label>
                                <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                            </FormGroup>
                            {!params?.diagnosis_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DiagnosisAdd;
