import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { addTestCategoriesAPI, upateTestCategoriesAPI, getSpecificTestAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import userUtil from '../../utils/UserUtils';
const TestCategoriesEdit = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [rolesList, setRolesList] = useState([]);
    const [premissionList, setPermissionList] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [testName, setTestName] = useState("");
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    useEffect(() => {

        if (params?.test_category_id) {
            getSpecificTest()
        }
    }, [params?.role_key]);

    const handleSubmit = async ()=>{

        if(params?.test_category_id){
            upateTestCategory()
            return
        }
        setIsLoader(true)
        let dataToSend = {
            id: params?.test_category_id,
            name: testName
        }
        const response = await addTestCategoriesAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {

            toast("Test Category addded Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            setTimeout(function(){
                history.push(`/test-categories`)
            },3000)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        } 
    }
    const upateTestCategory = async ()=>{
        let dataToSend = {
            id: params?.test_category_id,
            name: testName
        }
        setIsLoader(true)
        const response = await upateTestCategoriesAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Test Category updated Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            setTimeout(function(){
                history.push(`/test-categories`)
            },3000)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        } 
    }
    const getSpecificTest = async ()=>{
        setIsLoader(true)
        const response = await getSpecificTestAPI(params?.test_category_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
           setTestName(response?.data?.name)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        } 
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Tests", url: "/test-categories" },
                        { label: params?.test_category_id ? "Edit Test categories" : "Add Test categories"} 
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.test_category_id ? "Edit" : "Add"}{" "} Test</CardTitle>

                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={testName} onChange={(e)=>{setTestName(e.target.value)}}
                                    placeholder="Enter Test" />
                            </FormGroup>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>{params?.test_category_id?"Upadte":"Submit"}</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default TestCategoriesEdit;
