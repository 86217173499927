import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getStaffListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
const CenterList = () => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [staffList, setStaffList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    const [centerName, setCenterName] = useState(localStorage.getItem('centerName'));
    useEffect(() => {
        if (centerId) {
            getStaffList(centerId, page, rowsPerPage)
        }
    }, [centerId, page]);
    const getStaffList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getStaffListAPI(centerId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setStaffList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        // debugger;
        setPage(newPage - 1);

    };
    const handleAddStaffClick = () => {
        history.push('/member/add')
    }
    const handleClickEdit = (e, id) => {
        history.push(`/member/edit/${id}`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            {role == "SUPER_ADMIN" ?
                <BreadCrumb items={[
                    { label: "Dashboard", url: "/" },
                    { label: "Center List", url: "/centers" },
                    { label: `Dashboard (${centerName})`, url: `/center/dashboard/${centerId}` },
                    { label: `Members` },
                ]} />
                :
                <BreadCrumb items={[
                    { label: "Dashboard", url: "/" },
                    { label: "Members" },
                ]} />
            }
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Members List
                        {(permissions.includes("MEMBER_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddStaffClick()}>
                                Add Member
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Member Type</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    {(permissions.includes("MEMBER_UPDATE") || role == "SUPER_ADMIN") && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {staffList && staffList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.name}</td>
                                        <td>{item?.role?.name}</td>
                                        <td>{item?.email}</td>
                                        <td>{item?.phone}</td>
                                        {(permissions.includes("MEMBER_UPDATE") || role == "SUPER_ADMIN") && (
                                            <td>
                                                <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                            </td>
                                        )}

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={count / rowsPerPage}
                            onPageChange={handlePageChange}
                        />

                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CenterList;
