export const QuestionList = [
    {
        questionHeading: "Sclerosis - Lateral View",
        questions: [
            {
                sort: 1,
                question: 'C2-C3',
                key: 'c2_c3_sclerosis_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 2,
                question: 'C3-C4',
                key: 'c3_c4_sclerosis_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 3,
                question: 'C4-C5',
                key: 'c4_c5_sclerosis_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 4,
                question: 'C5-C6',
                key: 'c5_c6_sclerosis_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 5,
                question: 'C6-C7',
                key: 'c6_c7_sclerosis_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
        ]
    }, {
        questionHeading: "Triple Line Sign - Lateral View",
        questions: [
            {
                sort: 1,
                question: 'C2-C3',
                key: 'c2_c3_triple_line_sign_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 2,
                question: 'C3-C4',
                key: 'c3_c4_triple_line_sign_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 3,
                question: 'C4-C5',
                key: 'c4_c5_triple_line_sign_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 4,
                question: 'C5-C6',
                key: 'c5_c6_triple_line_sign_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 5,
                question: 'C6-C7',
                key: 'c6_c7_triple_line_sign_lateral_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
        ]
    }, {
        questionHeading: "Triple Line Sign - Extension View",
        questions: [
            {
                sort: 1,
                question: 'C2-C3',
                key: 'c2_c3_triple_line_sign_extension_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 2,
                question: 'C3-C4',
                key: 'c3_c4_triple_line_sign_extension_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 3,
                question: 'C4-C5',
                key: 'c4_c5_triple_line_sign_extension_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 4,
                question: 'C5-C6',
                key: 'c5_c6_triple_line_sign_extension_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 5,
                question: 'C6-C7',
                key: 'c6_c7_triple_line_sign_extension_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
        ]
    },
    {
        questionHeading: "Triple Line Sign - Flexion View",
        questions: [
            {
                sort: 1,
                question: 'C2-C3',
                key: 'c2_c3_triple_line_sign_flexion_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 2,
                question: 'C3-C4',
                key: 'c3_c4_triple_line_sign_flexion_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 3,
                question: 'C4-C5',
                key: 'c4_c5_triple_line_sign_flexion_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 4,
                question: 'C5-C6',
                key: 'c5_c6_triple_line_sign_flexion_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
            {
                sort: 5,
                question: 'C6-C7',
                key: 'c6_c7_triple_line_sign_flexion_view',
                description: 'Only One option can be selected',
                type: 'single-select',
                options: ['0', '1'],
            },
        ]
    }


]
