import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getAllPermissionsAPI, getRolesListAPI, updatePermissionAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import userUtil from '../../utils/UserUtils';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [rolesList, setRolesList] = useState([]);
    const [premissionList, setPermissionList] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    useEffect(() => {

        if (params?.role_key) {
            getAllPermissions()
            getRolesList()
        }
    }, [params?.role_key]);
    useEffect(() => {
        if (rolesList && premissionList) {
            for (let role of rolesList) {
                if (role?.key == params?.role_key) {
                    setSelectedRole(role)
                }
            }
        }
    }, [rolesList, premissionList]);


    const getAllPermissions = async () => {
        setIsLoader(true)
        const response = await getAllPermissionsAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPermissionList(response?.data?.rows)
        }
    }
    const getRolesList = async () => {
        setIsLoader(true)
        const response = await getRolesListAPI(centerId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setRolesList(response?.data)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = async (e, permissionId) => {
        // setIsLoader(true)
        let dataToSend = {
            role: params?.role_key,
            permission_id: permissionId
        }
        const response = await updatePermissionAPI(centerId, dataToSend, cancelTokenSource.token)
        // setIsLoader(false)
        if (response.success) {
            toast("Permission updated Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const checkIfClicked = async (permissionId) => {
        if (selectedRole) {
            if (selectedRole?.permissions) {
                for (let a of selectedRole?.permissions) {
                    if (a?.perission?.id == permissionId) {
                        return true
                    }
                }
            }
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Roles", url: "/roles" },
                        { label: "Edit Role" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.role_key ? "Edit" : "Add"}{" "} Role</CardTitle>

                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={selectedRole?.name} disabled
                                    placeholder="Enter Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Permissions</Label>
                                {console.log(selectedRole, 'selectedRole selectedRole')}
                                <Row>
                                    {selectedRole && premissionList && premissionList.map((item, index) => {
                                        let isChecked = false
                                        if (selectedRole) {
                                            if (selectedRole?.permissions) {
                                                for (let a of selectedRole?.permissions) {
                                                    if (a?.permission_id == item?.id) {
                                                        isChecked = true
                                                    }
                                                }
                                            }
                                        }


                                        return (
                                            <div className="col-xl-6 col-sm-12 col-sm-12 col-xs-12">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" defaultChecked={isChecked} onChange={(e) => { handleClick(e, item?.id) }} /> {item?.permission_name}
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        )
                                    })}

                                </Row>
                            </FormGroup>

                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
