
class UserUtil {

    userData = null;
    userDataPermission = null;
    constructor() {
        //console.log('SessionUtil sessionId', this.sessionId);
    }
    setUserData(userData){
        this.userData = userData;
    }
    setUserDataPermission(permissions){
        this.userDataPermission = permissions;
    }
    getUserData(){
        return this.userData;
    }
    getUserDataPermissions(){
        return this.userDataPermission;
    }
}

const userUtil = new UserUtil();
export default userUtil;
