import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { Slider } from "react-burgers";

import cx from 'classnames';

import {
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Button
} from 'reactstrap';

import {
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';

const AppMobileMenu = ({ enableMobileMenu, setEnableMobileMenu,enableMobileMenuSmall, setEnableMobileMenuSmall }) => {
    const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(false);

    const toggleMobileSidebar = () => {
        setEnableMobileMenu(!enableMobileMenu);
    };

    const toggleMobileSmall = () => {
        setEnableMobileMenuSmall(!enableMobileMenuSmall);
    };

    return (
        <Fragment>
            <div className="app-header__mobile-menu">
                <div onClick={toggleMobileSidebar}>
                    <Slider
                        active={enableMobileMenu}
                        type="elastic"
                        onClick={() => setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile)}
                    />
                </div>
            </div>
            <div className="app-header__menu">
                <span onClick={toggleMobileSmall}>
                    <Button
                        size="sm"
                        className={cx("btn-icon btn-icon-only", { active: activeSecondaryMenuMobile })}
                        color="primary"
                        onClick={() => setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile)}
                    >
                        <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV} /></div>
                    </Button>
                </span>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMobileMenu);
