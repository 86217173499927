import { processRequest } from "./Network";
import axios, { CancelTokenSource } from "axios";
let baseURLType = window.BaseUrl;
if (localStorage.getItem("x-auth-token")) axios.defaults.headers = { "x-auth-token": localStorage.getItem("x-auth-token") };


let baseURL = localStorage.getItem('base_url')
let entityId = localStorage.getItem('entity_id')
if (baseURL === null && isSubDomain() === true && window.location.host.split('.')[0] != 'portal') {
  // location.reload()
}

function isSubDomain() {
  let url = window.location.href;
  if (url.split('.').length == 2) {
    return false;
  } else {
    return true;
  }
}

export function getErrorResponse(error) {
  console.log(error, "error error    error ");
  let customResponse = [];
  try {
    if (error.data?.masssage == "timeout exceeded") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "timeout" };
      customResponse.user_message = "Timeout Exceeded";
    } else if (error && error?.toJSON().message === "Network Error") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "network" };
      customResponse.user_message = "Internet problem";
    } else {
      customResponse = error?.response.data;
      !!error && console.error(`FAILED API = ${error.response.config.url} | Error Code = ${customResponse.status_code?.value} | System Message = ${customResponse.system_message}`);
      !!!error && console.log("FAILED API with undefined error");
    }
  } catch (e) {
    console.log(e, "error catch");
    customResponse.success = false;
    customResponse.status_code = { key: "failed", value: -1, name: "network" };
    customResponse.user_message = "Internet problem";
  }
  return customResponse;
}


export const logoutAPI = async (cancelToken) => {
  const url = `/auth/user/logout`;
  const request = { type: "POST", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const loginAPI = async (dataToSend) => {
  const url = `login`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request);
    if (response.data.success == true && response.headers != null && response.headers['x-auth-token'] != null) {
      axios.defaults.headers = { 'x-auth-token': response.headers['x-auth-token'] };
      localStorage.setItem('user-token', response.headers['x-auth-token']);
    }
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getUserDetailAPI = async (cancelToken) => {
  const url = `getLoginUserDetail`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const forgotPasswordAPI = async (data,cancelToken) => {
  const url = `passwordReset`;
  const request = { type: "POST", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const resetPasswordAPI = async (data,cancelToken) => {
  const url = `resetPassword`;
  const request = { type: "POST", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const VerifyTokenAPI = async (data,cancelToken) => {
  const url = `validatePasswordResetToken`;
  const request = { type: "POST", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
