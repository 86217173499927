import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitUserForCenterAPI, getUserDetailByUserIDAPI, submitUserForCenterEditAPI } from './../../../../api/network/Api';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../../api/helperComponents/Loader';
import BreadCrumb from '../../../../api/helperComponents/BreadCrumb';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [nic, setNic] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [nicError, setNicError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };


    useEffect(() => {
        if (params?.user_id) {
            getUserDetail(params?.user_id)
        }
    }, [params?.user_id]);

    const getUserDetail = async (userId) => {
        setIsLoader(true)
        const response = await getUserDetailByUserIDAPI(params?.center_id, userId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setFirstName(response?.data?.name)
            setEmail(response?.data?.email)
            setPhone(response?.data?.phone)
            setAddress(response?.data?.address)
            setNic(response?.data?.nic)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        if (firstName == '' || email == '' || phone == '' || address == '' || nic == '') {
            if (firstName == '') {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName == '') {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (email == '') {
                setEmailError(true)
            } else {
                if (isValidEmail(email)) {
                    setEmailError(false)
                } else {
                    setEmailError(true)
                    return;
                }

            }
            if (phone == '') {
                setPhoneError(true)
            } else {
                setPhoneError(false)
            }
            if (address == '') {
                setAddressError(true)
            } else {
                setAddressError(false)
            }
            if (password == '') {
                setPasswordError(true)
            } else {
                if (password.length >= 6) {
                    setPasswordError(false)
                } else {
                    setPasswordError(true)
                    return;
                }
            }
            if (nic == '') {
                setNicError(true)
            } else {
                setNicError(false)
            }
        } else {
            if (isValidEmail(email)) {
                setEmailError(false)
            } else {
                setEmailError(true)
                return;
            }

            const regex = /^(?:\+92|0)[0-9]{10}$/;
            if (!regex.test(phone)) {
                setPhoneError(true);
                return;
            }

            const regexNic = /^\d{5}-?\d{7}-?\d{1}$/;
            console.log(nic, 'nic')
            if (!regexNic.test(nic)) {
                setNicError(true);
                return;
            }
            if (params?.user_id) {
                handleSubmitUserEdit();
            } else {
                handleSubmitUser();
            }

        }
    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.user_id,
            name: firstName,
            phone: phone,
            address: address,
            password: password == '' ? null : password,
            nic: nic
        }
        const response = await submitUserForCenterEditAPI(params?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("User Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/center/user/list/${params?.center_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: firstName,
            email: email,
            phone: phone,
            address: address,
            password: password,
            nic: nic
        }
        const response = await submitUserForCenterAPI(params?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("User Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/center/user/list/${params?.center_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleChangeContactNumber = (value) => {
        console.log(value, 'value')
        const inputValue = value;
        const regex = /^(?:\+92|0)[0-9]{10}$/;

        if (regex.test(inputValue)) {
            setPhoneError(false);
            setPhone(inputValue);
        } else {
            setPhone(inputValue);
            setPhoneError(true);
        }
    };
    const handleChangeCNIC = (value) => {
        console.log(value, 'value')
        const regex = /^\d{5}-?\d{7}-?\d{1}$/;

        if (regex.test(value)) {
            console.log('accepted')
            setNic(value);
            setNicError(false);
        } else {
            console.log('not accepted')
            setNic(value);
            setNicError(true);
        }
    };
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Centers", url: "/centers" },
                        { label: "Users", url: `/center/user/list/${params?.center_id}` },
                        { label: params?.user_id ? "Edit User" : "Add User" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.user_id ? "Edit" : "Add"}{" "}User</CardTitle>

                            <FormGroup>
                                <Label for="f_name">Name</Label>
                                <Input type="text" name="f_name" id="f_name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Enter Name" />
                                {firstNameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" defaultValue={email} onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter Email" readOnly={params?.user_id ? true : false} autoComplete="new-email" />
                                {emailError && (
                                    <span className="field-error">Enter Valid Email to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Contact Number</Label>
                                <Input type="number" name="phone" id="phone" defaultValue={phone} onChange={(e) => handleChangeContactNumber(e.target.value)}
                                    placeholder="Enter Contact Number" autoComplete="new-phone" />
                                <p className="cdi-label-description">Contact No. Format: 0300*******</p>
                                {phoneError && (
                                    <span className="field-error">Enter Contact Number to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <Input type="text" name="address" id="address" defaultValue={address} onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Enter Address" autoComplete="new-address" />
                                {addressError && (
                                    <span className="field-error">Enter Address to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" id="password" defaultValue={password} onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter Password" autoComplete="new-password" />
                                {passwordError && (
                                    <span className="field-error">Enter Password to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="nic">NIC</Label>
                                <Input type="number" name="nic" id="nic" defaultValue={nic} onChange={(e) => handleChangeCNIC(e.target.value)}
                                    placeholder="Enter NIC" autoComplete="new-nic" />
                                <p className="cdi-label-description">NIC Format: 35202********</p>
                                {nicError && (
                                    <span className="field-error">Enter NIC to Continue</span>
                                )}
                            </FormGroup>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
