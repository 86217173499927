import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitMachineAPI, getMachineDetailAPI, submitMachineEditAPI, getCenterListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import userUtil from '../../utils/UserUtils';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [statusError, setStatusError] = useState(false);
    const [serialNumber, setSerialNumber] = useState("");
    const [nameError, setNameError] = useState(false);
    const [serialNumberError, setSerialNumberError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [centerList, setCenterList] = useState([]);
    const [centerIdError, setCenterIdError] = useState(false);
    const [centerId, setCenterId] = useState(null);
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    useEffect(() => {
        if (params?.machine_id) {
            getMachineDetail(params?.machine_id)
        }
    }, [params?.machine_id]);
    useEffect(() => {
        getCenterList()
    }, []);

    const getCenterList = async () => {
        setIsLoader(true)
        const response = await getCenterListAPI(0, 10000, cancelTokenSource.token)
        console.log(response?.data?.rows, 'esponse?.data?.rows')
        setIsLoader(false)
        if (response.success) {
            console.log(response?.data?.rows, 'esponse?.data?.rows')
            setCenterList(response?.data?.rows)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getMachineDetail = async (machineId) => {
        setIsLoader(true)
        const response = await getMachineDetailAPI(machineId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setName(response?.data?.name)
            setCenterId(response?.data?.center_id)
            setSerialNumber(response?.data?.version)
            setStatus(response?.data?.status)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        if (name == '' || serialNumber == '' || status == "" || centerId == "") {
            if (name == '') {
                setNameError(true)
            } else {
                setNameError(false)
            }
            if (serialNumber == '') {
                setSerialNumberError(true)
            } else {
                setSerialNumberError(false)
            }
            if (status == '') {
                setStatusError(true)
            } else {
                setStatusError(false)
            }
            if (centerId == '') {
                setCenterIdError(true)
            } else {
                setCenterIdError(false)
            }
        } else {
            if (params?.machine_id) {
                handleSubmitUserEdit();
            } else {
                handleSubmitUser();
            }

        }
    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.machine_id,
            name: name,
            version: serialNumber,
            status: status,
            centerId:centerId
        }
        const response = await submitMachineEditAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Machine Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/machines')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: name,
            version: serialNumber,
            status: status,
            centerId:centerId
        }
        const response = await submitMachineAPI( dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Machine Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/machines')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Machines", url: "/machines" },
                        { label: params?.machine_id ? "Edit Machine" : "Add Machine" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.machine_id ? "Edit" : "Add"}{" "} Machine</CardTitle>

                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Name" />
                                {nameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="l_name">Center</Label>
                                <Input type="select" name="centerId" id="centerId" defaultValue={centerId} onChange={(e) => setCenterId(e.target.value)}
                                    placeholder="Enter Version" >
                                    <option value="">Select Center</option>
                                    {centerList && centerList.map((item, index) => (
                                        <option value={item?.id} selected={centerId == item?.id ?true:false}>{item?.name}</option>
                                    ))}
                                </Input>
                                {centerIdError && (
                                    <span className="field-error">Select Center to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="l_name">Version</Label>
                                <Input type="text" name="serial_no" id="serial_no" defaultValue={serialNumber} onChange={(e) => setSerialNumber(e.target.value)}
                                    placeholder="Enter Version" />
                                {serialNumberError && (
                                    <span className="field-error">Enter Version to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Status</Label>
                                <Input type="select" name="select" id="exampleSelect" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select Status</option>
                                    <option value={true} selected={status}>Active</option>
                                    <option value={false} selected={status}>In Active</option>
                                </Input>
                                {statusError && (
                                    <span className="field-error">Select Status to Continue</span>
                                )}
                            </FormGroup>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
