import React, { Fragment } from 'react';
import {
    DropdownToggle, DropdownMenu,
    Nav, Button, NavItem, NavLink,
    UncontrolledTooltip, UncontrolledButtonDropdown
} from 'reactstrap';
import { toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
import { useHistory } from 'react-router-dom';
import userUtil from './../../../utils/UserUtils';
const UserBox = () => {
    const history = useHistory();
    const notify2 = () => {
        toast("You don't have any new items in your calendar for today! Go out and play!", {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: 'bottom-center',
            type: 'success'
        });
    };
    const handleLogout = ()=>{
        console.log('handleLogout called')
        // return;
        localStorage.clear();
        userUtil.setUserData(null)
        history.push('/login')
    }

    return (
        <Fragment>
            <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                    <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown}/>
                                </DropdownToggle>
                                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                                    <Nav vertical>
                                        <NavItem>
                                            <NavLink href="#" onClick={()=>handleLogout()}>
                                                Logout
                                            </NavLink>
                                        </NavItem>
                                        
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left ms-3 header-user-info">
                            <div className="widget-heading">
                                {JSON.parse(localStorage.getItem('userDetail'))?.name}
                            </div>
                            <div className="widget-subheading">
                                {JSON.parse(localStorage.getItem('userDetail'))?.role?.name}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserBox;
