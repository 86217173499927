import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Button,
    Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getCenterListAPI, deleteCenterAPI, getArchivedCenterListAPI, addTreatmentBoughtAPI } from './../../../../api/network/Api';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../../api/helperComponents/Pagination';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../api/helperComponents/Loader';
import BreadCrumb from '../../../../api/helperComponents/BreadCrumb';
import Tabs from 'react-responsive-tabs';
import { dateTimeFormate } from './../../../../api/helperComponents/dateTimeFormat';
import userUtil from './../../../../utils/UserUtils';
const CenterList = () => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [centerList, setCenterList] = useState([]);
    const [centerListCount, setCenterListCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedTab, setSelectedTab] = useState(0);
    const [isLoader, setIsLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [centerTreatmentHistory, setCenterTreatmentHistory] = useState([]);
    const [addTreatmentBought, setAddTreatmentBought] = useState(false);
    const [centerId, setCenterId] = useState(null);
    const [treatmentBought, setTreatmentBought] = useState('');
    const [treatmentBoughtError, setTreatmentBoughtError] = useState(null);

    useEffect(() => {
        getCenterList(page, rowsPerPage)
    }, []);
    useEffect(() => {
        getCenterList(page, rowsPerPage)
    }, [page]);
    const getCenterList = async (page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getCenterListAPI(page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCenterList(response?.data?.rows)
            setCenterListCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getArchivedCenterList = async (page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getArchivedCenterListAPI(page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCenterList(response?.data?.rows)
            setCenterListCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddCenterClick = () => {
        history.push('/center/add')
    }
    const handleClickUser = (e, id) => {
        history.push(`/center/user/list/${id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/center/edit/${id}`)
    }
    const handleClickUserDelete = async (e, id) => {
        setIsLoader(true)
        const response = await deleteCenterAPI(id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Center deleted successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            getCenterList(page, rowsPerPage)

        } else {
            toast(response.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const tabsContent = [
        {
            title: 'List',
            content: 'centerList'
        },
        {
            title: 'Archived',
            content: 'centerListArchived'
        },
    ];

    const getTabs = () => {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            key: index,
        }));
    }
    const handleClickTab = (e) => {
        setSelectedTab(e)
        if (e == 0) {
            getCenterList(0, 10)
        } else {
            getArchivedCenterList(0, 10)
        }
        console.log(e, 'handleClickTab')
    }
    const handleTreatmentHistory = (e, centerData) => {
        setCenterId(centerData?.id)
        setCenterTreatmentHistory(centerData?.treatment_bought_history)
        setShowModal(true)
    }
    const handleSubmitTreatment = async (e) => {
        if (treatmentBought == '') {
            setTreatmentBoughtError(true)
            return;
        } else {
            setTreatmentBoughtError(false)
        }
        setIsLoader(true)
        let dataToSend = {
            treatments_bought: treatmentBought,
            center_id: centerId
        }
        const response = await addTreatmentBoughtAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setAddTreatmentBought(false)
            setTreatmentBought('')
            toast("Treatment Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            getCenterList(page, rowsPerPage)
            setShowModal(false)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }

    }
    const handleClickDashboard = (e, centerId, centerName) => {
        let userDetail = localStorage.getItem('userDetail')
        localStorage.setItem('centerName', centerName)
        if (userDetail) {
            userDetail = JSON.parse(localStorage.getItem('userDetail'))
            userDetail.center_id = centerId
            localStorage.setItem('userDetail', JSON.stringify(userDetail))
            userUtil.setUserData(userDetail)
        }
        history.push(`/center/dashboard/${centerId}`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Centers" }
            ]} />
            <Tabs tabsWrapperClass="body-tabs body-tabs-layout" onChange={(e) => { handleClickTab(e) }} transform={false} showInkBar={true} items={getTabs()} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">
                        {selectedTab == 0 ? "Center List" : "Archived Center List"}
                        {selectedTab == 0 && (
                            <button className="btn btn-primary" onClick={() => handleAddCenterClick()}>
                                Add Center
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th>Treatments Bought</th>
                                    <th>Treatments Used</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {centerList && centerList.map((item, index) => (
                                    <tr key={index}>
                                        <td><span style={{ cursor: 'pointer', color: 'blue' }} onClick={(e) => { handleClickDashboard(e, item?.id, item?.name) }}>{item?.name}</span></td>
                                        <td>{item?.location}</td>
                                        <td>{item?.treatments_bought}</td>
                                        <td>{item?.treatments_used}</td>
                                        <td>
                                            <Button disabled className="mb-2 me-2" color={item?.status ? 'success' : 'danger'}>
                                                {item?.status ? 'Active' : 'In Active'}
                                            </Button>
                                        </td>
                                        <td>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-user m-2" onClick={(e) => { handleClickUser(e, item.id) }}></i>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-usd m-2" onClick={(e) => { handleTreatmentHistory(e, item) }}></i>
                                            {selectedTab == 0 &&
                                                <i style={{ cursor: 'pointer', color: 'red' }} className="fa fa-trash" onClick={(e) => { handleClickUserDelete(e, item.id) }}></i>
                                            }
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {console.log(centerListCount, 'centerListCount')}
                    {console.log(rowsPerPage, 'rowsPerPage')}
                    {centerListCount > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={centerListCount / rowsPerPage}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
            <Modal isOpen={showModal} toggle={(e) => { setShowModal(!showModal) }} style={{ minWidth: '90%' }}>
                <ModalHeader toggle={(e) => { setShowModal(!showModal) }} charCode="Y" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Transaction History</div>
                        <button className="btn btn-primary" onClick={(e) => { setAddTreatmentBought(!addTreatmentBought) }}>Add Treatments</button>
                    </div>

                </ModalHeader>
                <ModalBody>
                    {addTreatmentBought && (
                        <div className="mb-4 mt-4">
                            <FormGroup>
                                <Label for="treatment_bought">Treatment Bought</Label>
                                <Input type="number" name="treatment_bought" id="treatment_bought" defaultValue={treatmentBought} onChange={(e) => setTreatmentBought(e.target.value)}
                                    placeholder="Enter Treatment Bought" />

                                {treatmentBoughtError && (
                                    <span className="field-error">Enter Treatments to Continue</span>
                                )}
                                <div>
                                    <button className="btn btn-primary mt-4" onClick={(e) => { handleSubmitTreatment(e) }}>Submit</button>
                                </div>
                            </FormGroup>
                        </div>
                    )}
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Treatment Bought</th>
                                    {/* <th>Price</th> */}
                                    <th>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {centerTreatmentHistory && centerTreatmentHistory.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.treatment_bought}</td>
                                        {/* <td>{item?.price}</td> */}
                                        <td>{dateTimeFormate(item?.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => { setShowModal(!showModal) }}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CenterList;
