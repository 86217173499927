import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import './assets/base.css';
import Main from './Pages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom';
import axios, { CancelTokenSource } from "axios";

const store = configureStore();
const rootElement = document.getElementById('root');
if (localStorage.getItem('user-token')) {
  axios.defaults.headers = { 'x-auth-token': localStorage.getItem('user-token') };
}
const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      {/* <HashRouter> */}
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Component} />
        </Switch>
      </BrowserRouter>
      {/* </HashRouter> */}
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./Pages/Main', () => {
    const NextApp = require('./Pages/Main').default;
    renderApp(NextApp);
  });
}
unregister();

// registerServiceWorker();

