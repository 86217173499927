import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Slider } from 'react-burgers';
import AppMobileMenu from '../AppMobileMenu';
import {
    setEnableClosedSidebar,
    setEnableMobileMenu,
    setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';

const HeaderLogo = ({
    enableClosedSidebar,
    setEnableClosedSidebar,
}) => {
    const [active, setActive] = useState(false);

    const toggleEnableClosedSidebar = () => {
        console.log(!enableClosedSidebar, 'enableClosedSidebar')
        setEnableClosedSidebar(!enableClosedSidebar);
        if (window.innerWidth < 1024) {
            var appContainer = document.querySelector('.app-container');
console.log(appContainer,'appContainer')
console.log(enableClosedSidebar,'enableClosedSidebar')
            if (appContainer) {
                if (!enableClosedSidebar) {
                    // Add more classes
                    // closed-sidebar closed-sidebar-mobile
                    // appContainer.classList.add('closed-sidebar', 'closed-sidebar-mobile');

                    // Remove a class
                    appContainer.classList.remove('closed-sidebar', 'closed-sidebar-mobile');
                }else{
                    appContainer.classList.add('closed-sidebar', 'closed-sidebar-mobile');
                }
            }
        }
    };

    return (
        <Fragment>
            {console.log(active, '!active')}
            <div className="app-header__logo">
                {/* <div className="logo-src" /> */}
                <div className="ihc-logo">
                    <h3 className="ihc-name">{window.innerWidth < 1024 ? "OS" : "OrthoSuite"}</h3>
                </div>
                <div className="header__pane" style={{marginLeft:'10px'}}>
                    <div onClick={toggleEnableClosedSidebar}>
                        <Slider
                            active={enableClosedSidebar}
                            type="elastic"
                            onClick={() => setActive(!active)}
                        />
                    </div>
                </div>
            </div>
            <AppMobileMenu />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
    setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
