import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

// DASHBOARDS

import SuperAdminDashboard from './SuperAdmin/';
import CenterAdminDashboard from './CenterAdmin/';
import StaffAdminDashboard from './StaffAdmin/';

// Layout

const Dashboards = ({ match }) => (
    <Fragment>
        {JSON.parse(localStorage.getItem('userDetail'))?.role?.name == "SUPER_ADMIN" ?
            <SuperAdminDashboard />
            : JSON.parse(localStorage.getItem('userDetail'))?.role?.name == "ADMIN" ?
                <CenterAdminDashboard />
                :
                <StaffAdminDashboard />
        }
    </Fragment>
);

export default Dashboards;