import React, { useState, useEffect, useRef } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, FormGroup, Label, Input
} from 'reactstrap';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getDashboardDetailAPI, getProfessionListAPI, getLatestDpepAPI, getDpepDetailAPI, getProgressReportAPI, getPatientDeepProgressReportAPI, getPatientTreatmentProgressReportAPI, getPatientDischargeDetailAPI, addDischargeFormAPI, getIfDischargeAPI, updatePatientStatusAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { Bar } from 'react-chartjs-2';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import generatePDF, { Resolution, Margin } from 'react-to-pdf';
library.add(
    faSpinner,
);
const PDFOptions = {
    // default is `save`
    filename: 'page.pdf',
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    // resolution: Resolution.MEDIUM,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: 'letter',
        // default is 'portrait'
        orientation: 'landscape',
    },
    // canvas: {
    //     // default is 'image/jpeg' for better size performance
    //     mimeType: 'image/png',
    //     qualityRatio: 1
    // },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        // pdf: {
        //     compress: true
        // },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: true
        }
    },
};
const optionsDPEPChart = {
    plugin: {
        legend: {
            display: true,
            position: 'top',
            align: 'end',
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                title: (tooltipItems) => {
                    return tooltipItems[0].xLabel;
                },
                label: (tooltipItem, data) => {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    return `Count: ${tooltipItem.yLabel} | ${dataset.description}`;
                },
            },
        },
    }
};

const PatientList = (props) => {
    const history = useHistory()
    const params = useParams()
    const canvasRef = useRef(null);
    const canvasRefFirst = useRef(null);
    const canvasRefSecond = useRef(null);
    const cancelTokenSource = axios.CancelToken.source();
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientDashboardData, setPatientDashboardData] = useState(null);
    const [professionList, setProfessionList] = useState([]);
    const [latestDpepData, setLatestDpepData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryFirst, setSelectedCategoryFirst] = useState(null);
    const [selectedCategorySecond, setSelectedCategorySecond] = useState(null);
    const [selectedCoords, setSelectedCoords] = useState([]);
    const [selectedCoordsFirst, setSelectedCoordsFirst] = useState(null);
    const [selectedCoordsSecond, setSelectedCoordsSecond] = useState(null);
    const [dpepFirstData, setDpepFirstData] = useState(null);
    const [dpepSecondData, setDpepSecondData] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [isLoaderCompareDpepSecond, setIsLoaderCompareDpepSecond] = useState(false);
    const [isLoaderCompareDpepFirst, setIsLoaderCompareDpepFirst] = useState(false);
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [alphabet, setAlphabet] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']);
    const [coordsPoint, setCoordsPoint] = useState([
        {
            "key": 1,
            "name": "Right Frontal Head",
            "coords": "411,124,414,242,341,242,337,228,334,214,340,198,343,171,351,147,364,135,384,124"
        },
        {
            "key": 2,
            "name": "Left Frontal Head",
            "coords": "418,122,418,240,486,243,489,233,493,217,487,201,487,182,480,154,464,135,443,124"
        },
        {
            "key": 3,
            "name": "Right Face",
            "coords": "365,287,411,323,409,244,344,241"
        },
        {
            "key": 4,
            "name": "Left Face",
            "coords": "416,244,418,322,462,289,472,269,476,253,482,244,452,242"
        },
        {
            "key": 5,
            "name": "Right Supraclavicular/Neck",
            "coords": "368,298,411,329,412,383,316,361"
        },
        {
            "key": 6,
            "name": "Left Supraclavicular/Neck",
            "coords": "416,328,415,383,515,361,466,332,462,298"
        },
        {
            "key": 7,
            "name": "Right Upper Chest",
            "coords": "319,367,326,402,315,443,294,478,382,481,408,477,414,407,411,392,401,382"
        },
        {
            "key": 8,
            "name": "Left Upper Chest",
            "coords": "416,390,418,477,530,483,505,447,497,411,497,383,500,371"
        },
        {
            "key": 9,
            "name": "Right Lower Chest",
            "coords": "293,489,414,486,412,543,401,547,398,565,386,583,366,612,337,620,328,630,303,647,294,597,289,562"
        },
        {
            "key": 10,
            "name": "Left Lower Chest",
            "coords": "418,484,416,543,429,543,440,568,459,605,500,633,525,644,534,619,533,582,537,539,534,502,530,489"
        },
        {
            "key": 11,
            "name": "Right Hypochondrium",
            "coords": "371,614,371,652,309,647"
        },
        {
            "key": 12,
            "name": "Epigastrium",
            "coords": "401,555,426,549,430,566,441,586,445,600,444,616,447,645,426,645,401,643,380,643,376,617"
        },
        {
            "key": 13,
            "name": "Left Hypochondrium",
            "coords": "451,605,451,647,509,648"
        },
        {
            "key": 14,
            "name": "Right Lumbar",
            "coords": "303,651,373,655,372,718,334,723,291,720,290,702"
        },
        {
            "key": 15,
            "name": "Umbilical Area",
            "coords": "375,652,448,654,448,722,376,722"
        },
        {
            "key": 16,
            "name": "Left Lumbar",
            "coords": "448,651,523,651,537,690,541,722,450,720"
        },
        {
            "key": 17,
            "name": "Right Iliac Fossa",
            "coords": "287,725,372,726,371,780,314,761,282,772"
        },
        {
            "key": 18,
            "name": "Hypogastrium",
            "coords": "375,725,450,727,448,786,423,820,414,861,391,810,373,789"
        },
        {
            "key": 19,
            "name": "Left Iliac Fossa",
            "coords": "450,725,541,725,548,771,501,761,473,767,451,785"
        },
        {
            "key": 20,
            "name": "Right Shoulder",
            "coords": "315,366,273,369,247,383,233,405,221,434,221,450,248,450,268,454,280,465,289,480,315,443,319,407,321,391"
        },
        {
            "key": 21,
            "name": "Left Shoulder",
            "coords": "501,366,545,365,569,376,586,394,598,412,608,448,580,446,562,448,547,464,536,479,509,446,500,411"
        },
        {
            "key": 22,
            "name": "Right Arm",
            "coords": "218,453,210,504,201,540,185,580,211,586,232,586,254,600,262,615,275,573,283,544,285,508,286,479,272,464,251,451"
        },
        {
            "key": 23,
            "name": "Left Arm",
            "coords": "608,454,620,516,644,579,615,579,584,582,569,602,552,582,545,557,543,521,541,493,545,465,570,451"
        },
        {
            "key": 24,
            "name": "Right Elbow",
            "coords": "176,595,164,624,160,648,179,667,196,675,219,674,241,668,251,648,251,629,253,610,237,595,212,588"
        },
        {
            "key": 25,
            "name": "Left Elbow",
            "coords": "566,612,570,643,586,659,616,670,643,663,656,650,661,633,655,608,647,595,631,586,609,584,588,591"
        },
        {
            "key": 26,
            "name": "Right Forearm",
            "coords": "157,655,133,772,160,769,176,772,187,790,200,763,215,738,226,708,240,679,200,680"
        },
        {
            "key": 27,
            "name": "Left Forearm",
            "coords": "581,666,634,783,647,781,658,770,670,770,684,772,695,777,688,751,679,718,672,677,668,650,633,669"
        },
        {
            "key": 28,
            "name": "Right Wrist",
            "coords": "125,794,128,818,143,830,162,830,180,822,183,800,155,793,135,781"
        },
        {
            "key": 29,
            "name": "Left Wrist",
            "coords": "644,795,644,812,644,822,659,829,668,836,684,833,701,820,705,801,693,784,674,791"
        },
        {
            "key": 30,
            "name": "Right Palm",
            "coords": "119,809,90,833,65,866,72,870,85,865,100,849,108,848,83,908,80,926,92,923,105,891,114,877,112,899,104,933,108,945,133,884,135,899,129,926,136,940,148,908,155,884,158,891,158,913,161,924,173,872,182,833,144,833"
        },
        {
            "key": 31,
            "name": "Left Palm",
            "coords": "708,808,741,834,756,852,762,866,752,869,720,852,729,876,738,893,752,923,740,924,717,880,709,887,713,908,722,933,719,940,695,890,687,897,697,931,687,934,674,883,668,888,670,923,662,918,645,834,690,836"
        },
        {
            "key": 32,
            "name": "Right Groin",
            "coords": "280,773,276,788,297,805,326,825,371,836,397,845,393,818,371,793,339,769,308,765"
        },
        {
            "key": 33,
            "name": "Left Groin",
            "coords": "423,844,450,840,479,833,520,811,541,797,550,783,540,766,518,765,493,765,475,772,455,786,437,809,429,823"
        },
        {
            "key": 34,
            "name": "Right Front Thigh",
            "coords": "275,797,319,826,373,843,407,851,405,929,398,981,391,1035,384,1072,357,1083,329,1090,307,1085,287,1076,276,1059,268,972,266,905"
        },
        {
            "key": 35,
            "name": "Left Front Thigh",
            "coords": "419,849,419,890,423,954,433,1030,437,1066,455,1084,476,1090,498,1092,516,1091,533,1085,543,1074,550,1052,554,1002,562,947,559,909,555,865,557,818,551,794,497,829"
        },
        {
            "key": 36,
            "name": "Right Knee",
            "coords": "279,1077,276,1113,279,1144,279,1176,291,1167,312,1163,339,1163,362,1172,371,1178,376,1131,382,1092,383,1077,336,1092"
        },
        {
            "key": 37,
            "name": "Left Knee",
            "coords": "441,1079,450,1132,454,1176,484,1164,512,1161,533,1169,548,1185,550,1132,550,1107,550,1079,495,1099"
        },
        {
            "key": 38,
            "name": "Right Leg",
            "coords": "280,1189,276,1219,282,1251,289,1307,303,1382,308,1405,323,1398,341,1397,358,1401,365,1392,362,1360,365,1333,368,1286,377,1242,376,1207,368,1183,350,1169,323,1164,300,1171"
        },
        {
            "key": 39,
            "name": "Left Leg",
            "coords": "455,1184,448,1248,458,1294,466,1367,468,1396,490,1387,509,1387,520,1396,529,1381,532,1339,544,1265,550,1209,545,1185,520,1167,486,1163"
        },
        {
            "key": 40,
            "name": "Right Ankle",
            "coords": "311,1413,303,1427,307,1438,319,1451,340,1456,351,1448,364,1438,364,1421,355,1409,332,1414"
        },
        {
            "key": 41,
            "name": "Left Ankle",
            "coords": "469,1398,487,1406,507,1406,516,1401,522,1420,520,1431,511,1437,504,1442,491,1446,472,1441,465,1423,461,1413"
        },
        {
            "key": 42,
            "name": "Right Foot",
            "coords": "304,1446,321,1457,343,1462,358,1451,366,1460,371,1481,371,1499,364,1513,347,1513,319,1514,291,1514,279,1505"
        },
        {
            "key": 43,
            "name": "Left Foot",
            "coords": "466,1444,457,1468,457,1490,459,1507,476,1512,494,1515,515,1515,538,1515,550,1504,544,1489,526,1468,523,1451,522,1435,500,1447"
        },
        {
            "key": 44,
            "name": "Right Occipital Head",
            "coords": "1163,109,1163,235,1227,235,1234,219,1238,202,1235,188,1230,153,1216,126,1192,112"
        },
        {
            "key": 45,
            "name": "Left Occipital Head",
            "coords": "1160,110,1160,233,1094,232,1084,210,1088,194,1094,174,1091,153,1106,129,1128,112"
        },
        {
            "key": 46,
            "name": "Right Neck",
            "coords": "1162,236,1163,350,1257,347,1218,318,1209,286,1210,265,1224,235"
        },
        {
            "key": 47,
            "name": "Left Neck",
            "coords": "1159,237,1160,349,1059,352,1102,324,1113,295,1113,276,1098,235"
        },
        {
            "key": 48,
            "name": "Right Scapular",
            "coords": "1163,355,1280,354,1307,360,1330,375,1346,403,1355,436,1356,461,1356,477,1342,493,1320,500,1288,496,1267,484,1163,405"
        },
        {
            "key": 49,
            "name": "Left Scapular",
            "coords": "1159,353,1159,403,1044,492,1016,498,987,491,969,475,965,452,971,414,983,388,1002,362,1035,349"
        },
        {
            "key": 50,
            "name": "Right Backoff chest",
            "coords": "1163,409,1162,504,1280,643,1288,552,1284,519,1280,494"
        },
        {
            "key": 51,
            "name": "Left Backoff chest",
            "coords": "1159,404,1162,504,1045,640,1037,559,1042,496"
        },
        {
            "key": 52,
            "name": "Right Loin",
            "coords": "1163,509,1163,704,1213,772,1242,758,1270,756,1292,761,1286,666,1281,650"
        },
        {
            "key": 53,
            "name": "Left Loin",
            "coords": "1160,512,1160,704,1110,763,1060,750,1033,756,1037,698,1038,658"
        },
        {
            "key": 54,
            "name": "Right Back Arm",
            "coords": "1360,481,1378,550,1399,591,1416,642,1436,768,1423,781,1403,789,1384,782,1360,749,1343,700,1323,645,1306,585,1293,556,1284,521,1282,498,1325,503"
        },
        {
            "key": 55,
            "name": "Left Back Arm",
            "coords": "963,482,942,556,916,618,884,770,898,785,919,791,944,784,970,724,1005,627,1033,550,1034,527,1037,495,1001,498"
        },
        {
            "key": 56,
            "name": "Right Dorsum Hand",
            "coords": "1438,779,1442,791,1470,804,1484,825,1503,850,1509,861,1495,865,1470,847,1475,870,1489,903,1493,920,1479,910,1461,874,1452,871,1463,902,1470,932,1454,932,1435,870,1434,893,1445,924,1439,933,1427,911,1416,875,1414,893,1416,918,1409,920,1402,885,1392,838,1388,806,1382,789,1410,789"
        },
        {
            "key": 57,
            "name": "Left Dorsum Hand",
            "coords": "938,789,934,802,930,821,922,877,913,920,903,917,908,875,901,890,887,933,880,931,880,908,885,871,876,884,869,921,859,932,855,914,872,867,858,865,856,882,837,920,826,915,841,874,856,843,837,859,824,861,815,857,834,828,860,803,883,777,905,792"
        },
        {
            "key": 58,
            "name": "Right Gluteal",
            "coords": "1293,766,1305,884,1303,899,1280,918,1255,932,1218,928,1187,913,1170,884,1170,857,1174,821,1188,797,1216,775,1249,756"
        },
        {
            "key": 59,
            "name": "Left Gluteal",
            "coords": "1027,759,1016,890,1028,912,1046,924,1069,931,1102,931,1123,919,1141,901,1155,876,1150,840,1144,815,1127,793,1099,758"
        },
        {
            "key": 60,
            "name": "Right Back of Thigh",
            "coords": "1168,885,1183,1053,1195,1071,1228,1085,1265,1084,1283,1074,1294,1059,1303,1016,1307,946,1307,902,1246,940,1203,922"
        },
        {
            "key": 61,
            "name": "Left Back of Thigh",
            "coords": "1152,887,1146,984,1139,1044,1127,1063,1106,1077,1077,1080,1046,1073,1028,1058,1020,1017,1013,965,1019,902,1064,938,1126,922,1126,922"
        },
        {
            "key": 62,
            "name": "Right Calf",
            "coords": "1293,1178,1296,1226,1288,1280,1278,1344,1270,1383,1262,1394,1243,1396,1227,1395,1210,1387,1212,1327,1205,1294,1198,1258,1198,1221,1203,1178,1221,1160,1252,1153,1274,1160"
        },
        {
            "key": 63,
            "name": "Left Calf",
            "coords": "1030,1177,1024,1224,1030,1252,1037,1291,1044,1341,1049,1380,1058,1398,1071,1406,1085,1405,1101,1398,1107,1391,1112,1348,1119,1308,1121,1276,1124,1227,1121,1180,1109,1166,1071,1153,1053,1156"
        },
        {
            "key": 64,
            "name": "Right Heel",
            "coords": "1202,1477,1202,1499,1207,1512,1235,1513,1271,1517,1291,1509,1291,1489,1278,1474,1263,1462,1235,1459"
        },
        {
            "key": 65,
            "name": "Left Heel",
            "coords": "1051,1470,1035,1487,1028,1507,1037,1514,1062,1516,1084,1514,1113,1510,1123,1488,1103,1467,1076,1459"
        },
        {
            "key": 66,
            "name": "Sacrococcyx",
            "coords": "1159,708,1207,770,1178,805,1169,834,1166,862,1156,855,1139,800,1113,768"
        }
    ]);
    const [categories, setCategories] = useState([
        {
            name: "Numbness (Activity)",
            key: "A",
            db_key: "numbness_activity",
            color: '#ffff0094'
        },
        {
            name: "Numbness (Constant)",
            key: "B",
            db_key: "numbness_constant",
            color: '#0b1f67a1'
        }, {
            name: "Numbness (Sleep)",
            key: "C",
            db_key: "numbness_sleep",
            color: '#ff9a00a1'
        }, {
            name: "Pain",
            key: "D",
            db_key: "pain",
            color: '#ca0000a1'
        }, {
            name: "Tenderness",
            key: "E",
            db_key: "tenderness",
            color: '#00ca20a1'
        }, {
            name: "Swelling",
            key: "F",
            db_key: "swelling",
            color: '#ae00caa1'
        }, {
            name: "Radicular Pain",
            key: "G",
            db_key: "radicular_pain",
            color: '#ca0071a1'
        }, {
            name: "Deformity",
            key: "H",
            db_key: "deformity",
            color: '#00ffff8c'
        }, {
            name: "Wound",
            key: "I",
            db_key: "wound",
            color: '#ff00ff73'
        }, {
            name: "Disability",
            key: "J",
            db_key: "disability",
            color: '#964b007d'
        }, {
            name: "Radiculopathy Sensory",
            key: "K",
            db_key: "radiculopathy_sensory",
            color: '#80808096'
        },
        {
            name: "Radiculopathy Motor",
            key: "L",
            db_key: "radiculopathy_motor",
            color: '#0080807d'
        },
    ]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dpepZoom, setDpepZoom] = useState('37%');
    const [dpepZoomCompare, setDpepZoomCompare] = useState('35%');
    const [progressReport, setProgressReport] = useState(null);
    const [dischargeDetailData, setDischargeDetailData] = useState(null);
    const [overAllProgressScore, setOverAllProgressScore] = useState(0);
    const [progressReportIndividual, setProgressReportIndividual] = useState(null);
    const [patientDeepProgressReport, setPatientDeepProgressReport] = useState(null);
    const [patientTreatmentProgressReport, setPatientTreatmentProgressReport] = useState(null);
    const [DPEPReportChartData, setDPEPReportChartData] = useState(null);
    const [treatmentReportChartData, setTreatmentReportChartData] = useState(null);
    const [isDischrageModal, setIsDischrageModal] = useState(false);
    const [isDownloadDPF, setIsDownloadDPF] = useState(false);
    const [initialDiagnosis, setInitialDiagnosis] = useState([]);
    const [finalDiagnosis, setFinalDiagnosis] = useState([]);
    const [dischargeTreatment, setDischargeTreatment] = useState({});
    const [isDischarge, setIsDischarge] = useState(false);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (progressReport) {
            let totalScore = progressReport?.report?.cervical_range_motion + progressReport?.report?.cervical_spine +
                progressReport?.report?.deep + progressReport?.report?.l_sacral_spine +
                progressReport?.report?.leg_level + progressReport?.report?.leg_weakness +
                progressReport?.report?.s_i + progressReport?.report?.shoulder_level +
                progressReport?.report?.shoulder_weakness + progressReport?.report?.thoracic_spine
            setOverAllProgressScore(totalScore)

            let cervical_range_motion = calculatePercentage(progressReport?.report?.cervical_range_motion, progressReport?.weightage?.cervical_range_motion)
            let cervical_spine = calculatePercentage(progressReport?.report?.cervical_spine, progressReport?.weightage?.cervical_spine)
            let deep = calculatePercentage(progressReport?.report?.deep, progressReport?.weightage?.deep)
            let l_sacral_spine = calculatePercentage(progressReport?.report?.l_sacral_spine, progressReport?.weightage?.l_sacral_spine)
            let leg_level = calculatePercentage(progressReport?.report?.leg_level, progressReport?.weightage?.leg_level)
            let leg_weakness = calculatePercentage(progressReport?.report?.leg_weakness, progressReport?.weightage?.leg_weakness)
            let s_i = calculatePercentage(progressReport?.report?.s_i, progressReport?.weightage?.s_i)
            let shoulder_level = calculatePercentage(progressReport?.report?.shoulder_level, progressReport?.weightage?.shoulder_level)
            let shoulder_weakness = calculatePercentage(progressReport?.report?.shoulder_weakness, progressReport?.weightage?.shoulder_weakness)
            let thoracic_spine = calculatePercentage(progressReport?.report?.thoracic_spine, progressReport?.weightage?.thoracic_spine)
            let data = {
                cervical_range_motion,
                cervical_spine,
                deep,
                l_sacral_spine,
                leg_level,
                leg_weakness,
                s_i,
                shoulder_level,
                shoulder_weakness,
                thoracic_spine
            }
            setProgressReportIndividual(data)
        }
    }, [progressReport]);
    function calculatePercentage(score, weightage) {
        if (weightage !== 0) {
            return (score / weightage) * 100;
        } else {
            // Handle the case where weightage is 0 to avoid division by zero
            return "Cannot divide by zero (weightage is 0).";
        }
    }
    const handleScreenWidthChange = () => {
        if (windowWidth >= 768) {
            setDpepZoom('16%')
            setDpepZoomCompare('11%')
        }
        if (windowWidth >= 1024) {
            setDpepZoom('27%')
            setDpepZoomCompare('20%')
        }
        if (windowWidth >= 1120) {
            setDpepZoom('31%')
            setDpepZoomCompare('20%')
        }

        if (windowWidth >= 1344) {
            setDpepZoom('32%')
            setDpepZoomCompare('26.5%')
        }
        if (windowWidth >= 1527) {
            setDpepZoom('37%')
            setDpepZoomCompare('35%')
        }
        if (windowWidth >= 1680) {
            setDpepZoom('37%')
            setDpepZoomCompare('35%')
        }




        console.log('Screen width changed to:', windowWidth);
    };

    useEffect(() => {
        handleScreenWidthChange();
    }, [windowWidth]);
    useEffect(() => {
        if (params?.patient_id) {
            getDashboardDetail(params?.patient_id)
            getProfessions()
            getLatestDPEP(params?.patient_id)
            getProgressReport(params?.patient_id)
            getPatientDeepProgressReport(params?.patient_id)
            getPatientTreatmentProgressReport(params?.patient_id)
            getPatientDischargeDetail(params?.patient_id)
            getIfDischarge(params?.patient_id)
        }
    }, [params?.patient_id]);
    const getProfessions = async () => {
        setIsLoader(true)
        const response = await getProfessionListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setProfessionList(response?.data)
        }
    }
    const getLatestDPEP = async (patientId) => {
        setIsLoader(true)
        const response = await getLatestDpepAPI(centerId, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setSelectedCoords(response?.data)
        }
    }
    const getProgressReport = async (patientId) => {
        setIsLoader(true)
        const response = await getProgressReportAPI(centerId, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setProgressReport(response?.data)
        }
    }
    const getPatientDischargeDetail = async (patientId) => {
        // setIsLoader(true)
        const response = await getPatientDischargeDetailAPI(centerId, patientId, cancelTokenSource.token)
        // setIsLoader(false)
        console.log(response, 'response getPatientDischargeDetail')
        if (response.success) {
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['pulsar_sessions']: response?.data?.grade?.sessions,
            }));
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['treatment_duration_from']: response?.data?.grade?.treatment?.from,
            }));
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['treatment_duration_to']: response?.data?.grade?.treatment?.to,
            }));
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['patient_grade']: response?.data?.grade?.grade,
            }));
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['cdi_initial']: getTotalCdiScore(response?.data?.grade?.cdi?.initial),
            }));
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['cdi_latest']: getTotalCdiScore(response?.data?.grade?.cdi?.latest),
            }));
            let diagnosisInitialSpinal = getNameofSpinal(response?.data?.grade?.dignosis?.initial?.spinal)
            let diagnosisInitialRheumatoidArthritis = getNameofRheumatoidArthritis(response?.data?.grade?.dignosis?.initial?.rheumatoid_arthritis)
            let diagnosisInitialAll = [...diagnosisInitialSpinal, ...diagnosisInitialRheumatoidArthritis]
            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['dignosis_initial']: diagnosisInitialAll,
            }));
            let diagnosisLatestSpinal = getNameofSpinal(response?.data?.grade?.dignosis?.latest?.spinal)
            let diagnosisLatestRheumatoidArthritis = getNameofRheumatoidArthritis(response?.data?.grade?.dignosis?.latest?.rheumatoid_arthritis)
            let diagnosisLatestAll = [...diagnosisLatestSpinal, ...diagnosisLatestRheumatoidArthritis]

            setDischargeTreatment((prevData) => ({
                ...prevData,
                ['dignosis_latest']: diagnosisLatestAll,
            }));
            setDischargeDetailData(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getNameofSpinal = (ids) => {
        console.log(ids, 'ids')
        let allDdata = [
            { SPONDYLOSIS: { key: 'SPONDYLOSIS', value: 1, name: 'Spondylosis' } },
            { SPONDYLOSIS: { key: 'DDD', value: 2, name: 'DDD' } },
            { DISC_BULGE: { key: 'DISC_BULGE', value: 3, name: 'Disc Bulge' } },
            { DISC_PROTRUSION: { key: 'DISC_PROTRUSION', value: 4, name: 'Disc Protrusion' } },
            { SPONDYLOLISTHESIS: { key: 'SPONDYLOLISTHESIS', value: 5, name: 'Spondylolisthesis' } },
            { SCOLIOSIS: { key: 'SCOLIOSIS', value: 6, name: 'Scoliosis' } },
            { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 7, name: 'Degenerative Scoliosis' } },
            { OSTEOPOROSIS: { key: 'OSTEOPOROSIS', value: 8, name: 'Osteoporosis' } },
            { OCHRONOSIS: { key: 'OCHRONOSIS', value: 9, name: 'Ochronosis' } },
            { COCCYDYNIA: { key: 'COCCYDYNIA', value: 10, name: 'Coccydynia' } },
            { ANKYLOSIS: { key: 'ANKYLOSIS', value: 11, name: 'Ankylosis' } },
            { SPONDYLITIS: { key: 'SPONDYLITIS', value: 12, name: 'Spondylitis' } },
            { SPINAL_STENOSIS: { key: 'SPINAL_STENOSIS', value: 13, name: 'Spinal Stenosis' } },
            { RADICULITIS: { key: 'RADICULITIS', value: 14, name: 'Radiculitis' } },
            { RADICULOPATHY: { key: 'RADICULOPATHY', value: 15, name: 'Radiculopathy' } },
            { MYELOPATHY: { key: 'MYELOPATHY', value: 16, name: 'Myelopathy' } },
            { SPINOCEREBELLAR_DISEASE: { key: 'SPINOCEREBELLAR_DISEASE', value: 17, name: 'Spinocerebellar Disease' } },
            { MOTOR_NEURON_DISEASE: { key: 'MOTOR_NEURON_DISEASE', value: 18, name: 'Motor Neuron Disease' } },
            { SYRINGOMYELIA: { key: 'SYRINGOMYELIA', value: 19, name: 'Syringomyelia' } },
            { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 20, name: 'Cervical Dystonia' } },
            { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 21, name: 'Not Applicable' } },
            { OTHER: { key: 'OTHER', value: 22, name: 'Other' } },
        ]
        ids = ids.map(Number);
        const filteredData = allDdata.filter(item => {
            const value = Object.values(item)[0].value;
            return ids.includes(value);
        });
        console.log(filteredData, 'filteredData')
        // return filteredData
        const names = filteredData.map(item => Object.values(item)[0].name);
        console.log(names, 'names')
        return names
    }
    const getNameofRheumatoidArthritis = (ids) => {
        let allDdata = [
            { JUVINILE: { key: 'JUVINILE', value: 1, name: 'Juvinile' } },
            { PAUCI_ARTICULAR: { key: 'PAUCI_ARTICULAR', value: 2, name: 'Pauci Articular' } },
            { POLY_ARTICUALAR: { key: 'POLY_ARTICUALAR', value: 3, name: 'Poly Articular' } },
            { RA_POSITIVE: { key: 'RA_POSITIVE', value: 4, name: '*RA +ve' } },
            { ANTI_CPP_POSITIVE: { key: 'ANTI_CPP_POSITIVE', value: 5, name: 'Anti-CCP +ye' } },
            { RA_NEGATIVE: { key: 'RA_NEGATIVE', value: 6, name: '*RA-ve' } },
            { ANTI_CPP_NEGATIVE: { key: 'ANTI_CPP_NEGATIVE', value: 7, name: 'Anti-CCP -ve' } },
            { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 8, name: 'Not Applicable' } },
        ]
        ids = ids.map(Number);
        const filteredData = allDdata.filter(item => {
            const value = Object.values(item)[0].value;
            return ids.includes(value);
        });
        const names = filteredData.map(item => Object.values(item)[0].name);
        return names
    }
    const getIfDischarge = async (patientId) => {
        // setIsLoader(true)
        const response = await getIfDischargeAPI(centerId, patientId, cancelTokenSource.token)
        // setIsLoader(false)
        if (response.success) {
            setIsDischarge(checkZeroValue(response?.data))
        }

    }
    function checkZeroValue(obj) {
        for (let key in obj) {
            if (obj[key] === 0) {
                return false;
            }
        }
        return true;
    }

    const getPatientDeepProgressReport = async (patientId) => {
        setIsLoader(true)
        const response = await getPatientDeepProgressReportAPI(centerId, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientDeepProgressReport(response?.data?.report)
            let label = []
            let dataSet = []
            if (response?.data?.report) {
                for (let a in response?.data?.report) {
                    label.push(`visit # ${parseInt(a) + 1}`)
                    dataSet.push(response?.data?.report[a]?.total)
                }
            }
            const dynamicDatasets = createDatasets(response?.data?.report, categories);
            console.log(dynamicDatasets, 'dynamicDatasets');
            let data = {
                labels: label,
                datasets: [
                    {
                        label: 'Over All Report',
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        borderCapStyle: 'round',
                        data: dataSet
                    },
                    ...dynamicDatasets
                ]
            };
            console.log(data, 'dataSet dataSet dataSet dpep')
            setDPEPReportChartData(data)
        }
    }
    const createDatasets = (dataArray, categoryList) => {
        const datasets = [];



        categoryList.forEach(category => {
            const dataForItem = [];
            const dbKey = category.db_key;
            let categorylabel = category?.name
            let categoryColor = category?.color
            let selectedPoints = ''

            dataArray.forEach((dataItem, index) => {
                const count = dataItem.category[dbKey].length;
                dataForItem.push(count);
                selectedPoints = dataItem.category[dbKey].join(',')
            });
            datasets.push({
                label: categorylabel,
                backgroundColor: categoryColor,
                borderColor: categoryColor,
                borderWidth: 1,
                hoverBackgroundColor: categoryColor,
                hoverBorderColor: categoryColor,
                borderCapStyle: 'round',
                data: dataForItem,
                hidden: true,
                description: `Custom description for ${selectedPoints}`
            });



        });

        return datasets;
    };
    const getPatientTreatmentProgressReport = async (patientId) => {
        setIsLoader(true)
        const response = await getPatientTreatmentProgressReportAPI(centerId, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientTreatmentProgressReport(response?.data?.report)
            let dataSet = [];
            let labels = [];
            let dataSetValueCervicalRangeMotion = [];
            let dataSetValueCervicalSpine = [];
            let dataSetValueLegLevel = [];
            let dataSetValueLegWeakness = [];
            let dataSetValueLumberSpine = [];
            let dataSetValueSI = [];
            let dataSetValueShoulderLevel = [];
            let dataSetValueShoulderWeakness = [];
            let dataSetValueThoracicSpine = [];

            if (response?.data?.report) {
                for (let a in response?.data?.report) {
                    labels.push(`Visit # ${parseInt(a) + 1}`)
                    dataSetValueCervicalRangeMotion.push(response?.data?.report[a]?.cervical_range_motion?.score)
                    dataSetValueCervicalSpine.push(response?.data?.report[a]?.cervical_spine?.score)
                    dataSetValueLegLevel.push(response?.data?.report[a]?.leg_level?.score)
                    dataSetValueLegWeakness.push(response?.data?.report[a]?.leg_weakness?.score)
                    dataSetValueLumberSpine.push(response?.data?.report[a]?.lumbar_spine?.score)
                    dataSetValueSI.push(response?.data?.report[a]?.s_i?.score)
                    dataSetValueShoulderLevel.push(response?.data?.report[a]?.shoulder_level?.score)
                    dataSetValueShoulderWeakness.push(response?.data?.report[a]?.shoulder_weakness?.score)
                    dataSetValueThoracicSpine.push(response?.data?.report[a]?.thoracic_spine?.score)
                }
            }
            let datasets = [
                {
                    label: 'Cervical Range Motion',
                    backgroundColor: '#cae8e7',
                    borderColor: '#cae8e7',
                    borderWidth: 1,
                    hoverBackgroundColor: '#cae8e7',
                    hoverBorderColor: '#cae8e7',
                    borderCapStyle: 'round',
                    data: dataSetValueCervicalRangeMotion,

                },
                {
                    label: 'Cervical Spine',
                    backgroundColor: '#569200',
                    borderColor: '#569200',
                    borderWidth: 1,
                    hoverBackgroundColor: '#569200',
                    hoverBorderColor: '#569200',
                    borderCapStyle: 'round',
                    data: dataSetValueCervicalSpine,
                    hidden: true
                },
                {
                    label: 'Leg Level',
                    backgroundColor: '#3542e7',
                    borderColor: '#3542e7',
                    borderWidth: 1,
                    hoverBackgroundColor: '#3542e7',
                    hoverBorderColor: '#3542e7',
                    borderCapStyle: 'round',
                    data: dataSetValueLegLevel,
                    hidden: true
                },
                {
                    label: 'Leg Weakness',
                    backgroundColor: '#f546d2',
                    borderColor: '#f546d2',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f546d2',
                    hoverBorderColor: '#f546d2',
                    borderCapStyle: 'round',
                    data: dataSetValueLegWeakness,
                    hidden: true
                },
                {
                    label: 'Lumber Spine',
                    backgroundColor: '#88a602',
                    borderColor: '#88a602',
                    borderWidth: 1,
                    hoverBackgroundColor: '#88a602',
                    hoverBorderColor: '#88a602',
                    borderCapStyle: 'round',
                    data: dataSetValueLumberSpine,
                    hidden: true
                },
                {
                    label: 'SI',
                    backgroundColor: '#a64502',
                    borderColor: '#a64502',
                    borderWidth: 1,
                    hoverBackgroundColor: '#a64502',
                    hoverBorderColor: '#a64502',
                    borderCapStyle: 'round',
                    data: dataSetValueSI,
                    hidden: true
                },
                {
                    label: 'Shoulder Level',
                    backgroundColor: '#f1a978',
                    borderColor: '#f1a978',
                    borderWidth: 1,
                    hoverBackgroundColor: '#f1a978v',
                    hoverBorderColor: '#f1a978',
                    borderCapStyle: 'round',
                    data: dataSetValueShoulderLevel,
                    hidden: true
                },
                {
                    label: 'Shoulder Weakness',
                    backgroundColor: '#08be6e',
                    borderColor: '#08be6e',
                    borderWidth: 1,
                    hoverBackgroundColor: '#08be6e',
                    hoverBorderColor: '#08be6e',
                    borderCapStyle: 'round',
                    data: dataSetValueShoulderWeakness,
                    hidden: true
                },
                {
                    label: 'Thoracic Spine',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    borderCapStyle: 'round',
                    data: dataSetValueThoracicSpine,
                    hidden: true
                }
            ];
            let data = {
                labels,
                datasets: datasets
            };
            console.log(data, 'dataSet dataSet dataSet treatment')
            setTreatmentReportChartData(data)
        }
    }
    const getDashboardDetail = async (patientId) => {
        setIsLoader(true)
        const response = await getDashboardDetailAPI(centerId, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientDashboardData(response?.data)
            localStorage.setItem('patientNameID', response?.data?.name + '(' + response?.data?.id + ')')
            setPatientNameID(response?.data?.name + '(' + response?.data?.id + ')')
            // setPatientList(response?.data?.rows)
            // setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleAddPatientClick = () => {
        history.push('/patient/add')
    }
    const highlightCoords = (coordsArray, color) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        coordsArray.forEach(coords => {
            coords = coords.split(",");
            ctx.beginPath();
            ctx.moveTo(coords[0], coords[1]);

            for (let i = 2; i < coords.length; i += 2) {
                ctx.lineTo(coords[i], coords[i + 1]);
            }

            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();

            // Highlight selected coordinates
            if (selectedCoords[selectedCategory]) {
                ctx.fillStyle = color;
                selectedCoords[selectedCategory].forEach(selectedCoord => {
                    if (selectedCoord === coords[0]) {
                        ctx.beginPath();
                        ctx.moveTo(coords[0], coords[1]);

                        for (let i = 2; i < coords.length; i += 2) {
                            ctx.lineTo(coords[i], coords[i + 1]);
                        }

                        ctx.closePath();
                        ctx.fill();
                    }
                });
            }
        });
    };
    const highlightCoordsCompare = (coordsArray, color) => {
        const canvas = canvasRefFirst.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        coordsArray.forEach(coords => {
            coords = coords.split(",");
            ctx.beginPath();
            ctx.moveTo(coords[0], coords[1]);

            for (let i = 2; i < coords.length; i += 2) {
                ctx.lineTo(coords[i], coords[i + 1]);
            }

            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();

            // Highlight selected coordinates
            if (selectedCoordsFirst[selectedCategoryFirst]) {
                ctx.fillStyle = color;
                selectedCoordsFirst[selectedCategoryFirst].forEach(selectedCoord => {
                    if (selectedCoord === coords[0]) {
                        ctx.beginPath();
                        ctx.moveTo(coords[0], coords[1]);

                        for (let i = 2; i < coords.length; i += 2) {
                            ctx.lineTo(coords[i], coords[i + 1]);
                        }

                        ctx.closePath();
                        ctx.fill();
                    }
                });
            }
        });
    };
    const highlightCoordsCompareSecond = (coordsArray, color) => {
        const canvas = canvasRefSecond.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        coordsArray.forEach(coords => {
            coords = coords.split(",");
            ctx.beginPath();
            ctx.moveTo(coords[0], coords[1]);

            for (let i = 2; i < coords.length; i += 2) {
                ctx.lineTo(coords[i], coords[i + 1]);
            }

            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();

            // Highlight selected coordinates
            if (selectedCoordsSecond[selectedCategorySecond]) {
                ctx.fillStyle = color;
                selectedCoordsSecond[selectedCategorySecond].forEach(selectedCoord => {
                    if (selectedCoord === coords[0]) {
                        ctx.beginPath();
                        ctx.moveTo(coords[0], coords[1]);

                        for (let i = 2; i < coords.length; i += 2) {
                            ctx.lineTo(coords[i], coords[i + 1]);
                        }

                        ctx.closePath();
                        ctx.fill();
                    }
                });
            }
        });
    };

    const handleMouseOut = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
    const handleMouseOutFirst = () => {
        const canvas = canvasRefFirst.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
    const handleMouseOutSecond = () => {
        const canvas = canvasRefSecond.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
    const handleClickCoords = (e, item) => {

        if (selectedCoords[selectedCategory]) {
            if (selectedCoords[selectedCategory].includes(item?.key)) {
                selectedCoords[selectedCategory] = selectedCoords[selectedCategory].filter(key => key !== item?.key);
            } else {
                selectedCoords[selectedCategory].push(item?.key);
            }
            let formattedData = {};

            for (const key in selectedCoords) {
                console.log(selectedCoords[key], 'selectedCoords[key]')
                if (selectedCoords[key].length > 0) {
                    formattedData[key] = selectedCoords[key];
                }
            }
            setSelectedCoords(formattedData)
            console.log(formattedData, 'selectedCoords');
        } else {
            console.error("Please select a category first.");
        }
        getSelectedCoordsAgainstCategory(selectedCategory)
    }

    const getSelectedCoordsAgainstCategory = (categoryKey) => {
        let getSelectedCoordsPoints = selectedCoords[categoryKey]
        getSelectedCoordsPoints = getSelectedCoordsPoints.map(item => Number(item.area));
        console.log(getSelectedCoordsPoints, 'getSelectedCoordsPoints')
        console.log(coordsPoint, 'coordsPoint')
        let allCoordsForCategory = []
        if (getSelectedCoordsPoints) {
            if (getSelectedCoordsPoints.length > 0) {
                for (let coordsP of getSelectedCoordsPoints) {
                    for (let i = 0; i < coordsPoint.length; i++) {
                        if (coordsPoint[i].key === coordsP) {
                            // return coordsPoint[i].coords;
                            allCoordsForCategory.push(coordsPoint[i].coords)
                            // getSelectedCoords(coordsPoint[i].coords)
                        }
                    }
                }
            }
        }
        console.log(allCoordsForCategory, 'allCoordsForCategory')
        let color = '';
        if (categories) {
            for (let cate of categories) {
                if (cate.db_key == categoryKey) {
                    color = cate.color
                }
            }
        }
        console.log(color, 'color')
        highlightCoords(allCoordsForCategory, color)
    }
    const getSelectedCoordsAgainstCategoryCompare = (categoryKey) => {
        let getSelectedCoordsPoints = selectedCoordsFirst[categoryKey]
        getSelectedCoordsPoints = getSelectedCoordsPoints.map(item => Number(item.area));
        console.log(getSelectedCoordsPoints, 'getSelectedCoordsPoints')
        console.log(coordsPoint, 'coordsPoint')
        let allCoordsForCategory = []
        if (getSelectedCoordsPoints) {
            if (getSelectedCoordsPoints.length > 0) {
                for (let coordsP of getSelectedCoordsPoints) {
                    for (let i = 0; i < coordsPoint.length; i++) {
                        if (coordsPoint[i].key === coordsP) {
                            // return coordsPoint[i].coords;
                            allCoordsForCategory.push(coordsPoint[i].coords)
                            // getSelectedCoords(coordsPoint[i].coords)
                        }
                    }
                }
            }
        }
        console.log(allCoordsForCategory, 'allCoordsForCategory')
        let color = '';
        if (categories) {
            for (let cate of categories) {
                if (cate.db_key == categoryKey) {
                    color = cate.color
                }
            }
        }
        console.log(color, 'color')
        highlightCoordsCompare(allCoordsForCategory, color)
    }
    const getSelectedCoordsAgainstCategoryCompareSecond = (categoryKey) => {
        let getSelectedCoordsPoints = selectedCoordsSecond[categoryKey]
        getSelectedCoordsPoints = getSelectedCoordsPoints.map(item => Number(item.area));
        console.log(getSelectedCoordsPoints, 'getSelectedCoordsPoints')
        console.log(coordsPoint, 'coordsPoint')
        let allCoordsForCategory = []
        if (getSelectedCoordsPoints) {
            if (getSelectedCoordsPoints.length > 0) {
                for (let coordsP of getSelectedCoordsPoints) {
                    for (let i = 0; i < coordsPoint.length; i++) {
                        if (coordsPoint[i].key === coordsP) {
                            // return coordsPoint[i].coords;
                            allCoordsForCategory.push(coordsPoint[i].coords)
                            // getSelectedCoords(coordsPoint[i].coords)
                        }
                    }
                }
            }
        }
        console.log(allCoordsForCategory, 'allCoordsForCategory')
        let color = '';
        if (categories) {
            for (let cate of categories) {
                if (cate.db_key == categoryKey) {
                    color = cate.color
                }
            }
        }
        console.log(color, 'color')
        highlightCoordsCompareSecond(allCoordsForCategory, color)
    }
    const handleClickCategory = (category) => {
        console.log(selectedCoords, 'selectedCoords selectedCoords')
        if (selectedCoords[category?.db_key] === undefined) {
            selectedCoords[category?.db_key] = [];
            handleMouseOut()
        }
        if (selectedCoords[category?.db_key].length == 0) {
            handleMouseOut()
        }
        console.log(selectedCoords, 'selectedCoords')
        setSelectedCategory(category?.db_key)
        setSelectedCoords(selectedCoords)
        console.log(category?.db_key, 'category?.key')
        getSelectedCoordsAgainstCategory(category?.db_key)
        // if (Object.keys(latestDpepData).length > 0) {
        //     // handleCall()
        // }
    }
    const handleClickCategoryCompare = (category) => {
        console.log(selectedCoords, 'selectedCoords selectedCoords')
        if (selectedCoordsFirst[category?.db_key] === undefined) {
            selectedCoordsFirst[category?.db_key] = [];
            handleMouseOutFirst()
        }
        if (selectedCoordsFirst[category?.db_key].length == 0) {
            handleMouseOutFirst()
        }
        console.log(selectedCoordsFirst, 'selectedCoords')
        setSelectedCategoryFirst(category?.db_key)
        setSelectedCoordsFirst(selectedCoordsFirst)
        console.log(category?.db_key, 'category?.key')
        getSelectedCoordsAgainstCategoryCompare(category?.db_key)
        // if (Object.keys(latestDpepData).length > 0) {
        //     // handleCall()
        // }
    }
    const handleClickCategoryCompareSecond = (category) => {
        console.log(selectedCoords, 'selectedCoords selectedCoords')
        if (selectedCoordsSecond[category?.db_key] === undefined) {
            selectedCoordsSecond[category?.db_key] = [];
            handleMouseOutSecond()
        }
        if (selectedCoordsSecond[category?.db_key].length == 0) {
            handleMouseOutSecond()
        }
        console.log(selectedCoordsSecond, 'selectedCoords')
        setSelectedCategorySecond(category?.db_key)
        setSelectedCoordsSecond(selectedCoordsSecond)
        console.log(category?.db_key, 'category?.key')
        getSelectedCoordsAgainstCategoryCompareSecond(category?.db_key)
        // if (Object.keys(latestDpepData).length > 0) {
        //     // handleCall()
        // }
    }
    function calculateSubScore(level, data) {
        console.log(data, 'data')
        console.log(data[`${level}_olisthesis_lateral`], 'level')
        const lateral = Number(data[`${level}_olisthesis_lateral`]);
        const flexion = Number(data[`${level}_olisthesis_flexion`]);
        const extension = Number(data[`${level}_olisthesis_extension`]);
        const anterior = Number(data[`${level}_olisthesis_anterior`]);
        const posterior = Number(data[`${level}_olisthesis_posterior`]);
        const discSpaceNarrowing = Number(data[`${level}_disc_space_narrowing`]);

        const maxFlexionExtension = Math.max(flexion, extension);
        const maxAnteriorPosterior = Math.max(anterior, posterior);

        return lateral + maxFlexionExtension + maxAnteriorPosterior + discSpaceNarrowing;
    }
    const getTotalCdiScore = (item) => {
        if (item) {
            return calculateSubScore('c2_c3', item) + calculateSubScore('c3_c4', item) + calculateSubScore('c4_c5', item) + calculateSubScore('c5_c6', item) + calculateSubScore('c6_c7', item)
        } else {
            return '0';
        }
    }
    const handleClickEdit = (e) => {
        history.push(`/patient/edit/${params?.patient_id}`)
    }
    const handleClickPateintHistory = (e) => {
        history.push(`/patient/medical-histories/${params?.patient_id}`)
    }
    const handleClicktreatment = (e) => {
        history.push(`/patient/treatments/list/${params?.patient_id}`)
    }
    const handleClickDpep = (e) => {
        history.push(`/patient/dpeps/list/${params?.patient_id}`)
    }
    const handleClickCDI = (e) => {
        history.push(`/patient/cdis/list/${params?.patient_id}`)
    }
    const handleClickECDI = (e) => {
        history.push(`/patient/extended-cdis/list/${params?.patient_id}`)
    }
    const handleClickDiagnosis = (e) => {
        history.push(`/patient/diagnosis/list/${params?.patient_id}`)
    }
    const handleClickTendinopathies = (e) => {
        history.push(`/patient/tendinopathies/list/${params?.patient_id}`)
    }
    const handleClickCategorization = (e) => {
        history.push(`/patient/categorization/list/${params?.patient_id}`)
    }
    const handleClickSuggestedTreatment = (e) => {
        history.push(`/patient/suggested-treatment/list/${params?.patient_id}`)
    }
    const handleClickTripleLineForm = (e) => {
        history.push(`/patient/triple-line/list/${params?.patient_id}`)
    }
    const handleClickLabTest = (e) => {
        history.push(`/patient/lab-test/list/${params?.patient_id}`)
    }
    const handleClickDischarge = (e) => {
        setIsDischrageModal(true)
    }
    const getNameOfEcdi = (nameId) => {
        if (nameId == '0') {
            return 'Normal'
        }
        if (nameId == '1') {
            return 'Instability'
        }
        if (nameId == '2') {
            return 'Mild'
        }
        if (nameId == '3') {
            return 'Moderate'
        }
        if (nameId == '4') {
            return 'Severe'
        }
        if (nameId == '5') {
            return 'Very Severe'
        }
    }
    const getDeformityGradeOfEcdi = (nameId) => {
        if (nameId == '0') {
            return '0 - Normal'
        }
        if (nameId == '1') {
            return 'I - Olisthesis'
        }
        if (nameId == '2') {
            return 'I - A (Dynamic)'
        }
        if (nameId == '3') {
            return 'I - B (Dynamic)'
        }
        if (nameId == '4') {
            return 'I - C (Dynamic)'
        }
        if (nameId == '5') {
            return 'II - A (Static)'
        }
        if (nameId == '6') {
            return 'II - B (Static)'
        }
        if (nameId == '7') {
            return 'II - C (Static)'
        }
        if (nameId == '8') {
            return 'III - Rigid'
        }
    }
    const getNameOfProfession = (professtionId) => {
        if (professionList) {
            for (let a of professionList) {
                if (a?.id == professtionId) {
                    return a?.name
                }
            }
        }
    }
    const getBMICaregory = (bmiScore) => {
        let category = '';
        if (bmiScore < 18.5) {
            category = "Underweight";
        } else if (bmiScore >= 18.5 && bmiScore <= 24.9) {
            category = "Normal weight";
        } else if (bmiScore >= 25.0 && bmiScore <= 29.9) {
            category = "Overweight";
        } else {
            category = "Obesity";
        }
        return category;
    }
    const handleCompareDpep = (e) => {
        setDpepFirstData(null)
        setDpepSecondData(null)
        setModalShow(true)
    }
    const handleSelectDpepFirst = async (e) => {
        setIsLoaderCompareDpepFirst(true)
        setSelectedCategoryFirst(null)
        setSelectedCoordsFirst(null)
        if (e.target.value != '') {
            const response = await getDpepDetailAPI(centerId, params?.patient_id, e.target.value, cancelTokenSource.token)
            setIsLoaderCompareDpepFirst(false)
            if (response.success) {
                setSelectedCoordsFirst(response?.data)
            }
        } else {
            setDpepFirstData(null)
        }
    }
    const handleSelectDpepSecond = async (e) => {
        setSelectedCategorySecond(null)
        setSelectedCoordsSecond(null)
        setIsLoaderCompareDpepSecond(true)
        if (e.target.value != '') {
            const response = await getDpepDetailAPI(centerId, params?.patient_id, e.target.value, cancelTokenSource.token)
            setIsLoaderCompareDpepSecond(false)
            if (response.success) {
                setSelectedCoordsSecond(response?.data)
            }
        } else {
            setDpepSecondData(null)
        }
    }
    const handleClickInitialDiagnosis = (e, key, id) => {
        console.log(key, 'data.key')
        console.log(id, 'id')
        var selectedValues = [];

        const checkbox = document.getElementById(key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        console.log(checkbox, 'checkbox')
        if (checkbox) {
            checkbox.checked = true;
            console.log(checkbox.checked, 'checkbox')
        } else {
            console.error('Checkbox not found');
        }

        var checkboxes = document.getElementsByName(key);

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                selectedValues.push(checkboxes[i].value);
            }
        }

        setInitialDiagnosis(selectedValues)
        console.log(selectedValues, 'selectedValues selectedValues')
    }
    const handleClickFinalDiagnosis = (e, key, id) => {
        console.log(key, 'data.key')
        console.log(id, 'id')
        var selectedValues = [];

        const checkbox = document.getElementById(key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        console.log(checkbox, 'checkbox')
        if (checkbox) {
            checkbox.checked = true;
            console.log(checkbox.checked, 'checkbox')
        } else {
            console.error('Checkbox not found');
        }

        var checkboxes = document.getElementsByName(key);

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                selectedValues.push(checkboxes[i].value);
            }
        }

        setFinalDiagnosis(selectedValues)
        console.log(selectedValues, 'selectedValues selectedValues')
    }
    const handleDischargeTreatment = (key, value) => {
        setDischargeTreatment((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }
    const handleSubmitDischargeData = (e) => {
        // setIsDischrageModal(false)
        if (!validDischargeData()) {
            alert('Fill All Fields')
            return;
        }
        let dataToSend = {
            initial_diagnosis: dischargeDetailData?.grade?.dignosis?.initial?.spinal.concat(dischargeDetailData?.grade?.dignosis?.initial?.rheumatoid_arthritis),
            finial_diagnosis: dischargeDetailData?.grade?.dignosis?.latest?.spinal.concat(dischargeDetailData?.grade?.dignosis?.latest?.rheumatoid_arthritis),
            steroid_injection: dischargeTreatment?.steroid_injection,
            hyaluronidase_prp_inj: dischargeTreatment?.hyaluronidase_prp_inj,
            medicines: dischargeTreatment?.medicines,
            physiotherapy: dischargeTreatment?.physiotherapy,
            surgery: dischargeTreatment?.surgery,
            others: dischargeTreatment?.others,
            subjective_improvement: dischargeTreatment?.subjective_improvement,
            objective_improvement: dischargeTreatment?.objective_improvement,
            disability_index_initial: dischargeTreatment?.disability_index_initial,
            disability_index_final: dischargeTreatment?.disability_index_final,
            radiological_improvement_score_initial: dischargeTreatment?.cdi_initial,
            radiological_improvement_score_final: dischargeTreatment?.cdi_latest,
            patient_grade: dischargeTreatment?.patient_grade,
            treatment_duration_from: dischargeTreatment?.treatment_duration_from,
            treatment_duration_to: dischargeTreatment?.treatment_duration_to?dischargeTreatment?.treatment_duration_to:dischargeTreatment?.treatment_duration_from,
            pulsar_sessions: dischargeTreatment?.pulsar_sessions,
            customCreatedAt: customCreatedAt
        }
        addDischargeForm(dataToSend)
    }
    const validDischargeData = () => {
        // if (initialDiagnosis.length == 0) {
        //     console.log('validDischargeData 1')
        //     return false;
        // }
        // if (finalDiagnosis.length == 0) {
        //     console.log('validDischargeData 2')
        //     return false;
        // }
        console.log(dischargeTreatment, 'dischargeTreatment dischargeTreatmentdischargeTreatmentdischargeTreatmentdischargeTreatment')
        if (!dischargeTreatment?.disability_index_final && dischargeTreatment?.disability_index_final == '') {
            console.log('validDischargeData 3')
            return false;
        }
        if (!dischargeTreatment?.disability_index_initial && dischargeTreatment?.disability_index_initial == '') {
            console.log('validDischargeData 4')
            return false;
        }
        if (!dischargeTreatment?.hyaluronidase_prp_inj && dischargeTreatment?.hyaluronidase_prp_inj == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.medicines && dischargeTreatment?.medicines == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.objective_improvement && dischargeTreatment?.objective_improvement == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.others && dischargeTreatment?.others == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.physiotherapy && dischargeTreatment?.physiotherapy == '') {
            console.log('validDischargeData 1')
            return false;
        }
        // if (!dischargeTreatment?.radiological_improvement_score_final && dischargeTreatment?.radiological_improvement_score_final == '') {
        //     console.log('validDischargeData 1')
        //     return false;
        // }
        // if (!dischargeTreatment?.radiological_improvement_score_initial && dischargeTreatment?.radiological_improvement_score_initial == '') {
        //     console.log('validDischargeData 1')
        //     return false;
        // }
        if (!dischargeTreatment?.pulsar_sessions && dischargeTreatment?.pulsar_sessions == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.steroid_injection && dischargeTreatment?.steroid_injection == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.subjective_improvement && dischargeTreatment?.subjective_improvement == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.surgery && dischargeTreatment?.surgery == '') {
            console.log('validDischargeData 1')
            return false;
        }
        if (!dischargeTreatment?.treatment_duration_from && dischargeTreatment?.treatment_duration_from == '') {
            console.log('validDischargeData 1')
            return false;
        }
        // if (dischargeTreatment?.treatment_duration_to && dischargeTreatment?.treatment_duration_to == '') {
        //     return false;
        // }
        return true
    }
    const addDischargeForm = async (data) => {
        setIsLoader(true)
        setIsDischrageModal(false)
        const response = await addDischargeFormAPI(centerId, params?.patient_id, data, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Discharge Form Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            setIsDownloadDPF(true)
        } else {
            setIsDischrageModal(true)
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleChangeStatus = async (e) => {
        const data = {
            id: params?.patient_id,
            status: e.target.value
        }
        const response = await updatePatientStatusAPI(centerId, data, cancelTokenSource.token)
        if (response.success) {
            toast("Patient Status Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
        } else {
            toast("Some Thing Went Wrong", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getTargetElement = () => document.getElementById('discharge-pdf-container');
    let diagnosisData = ["Spinal Asymmetry", "Spinal Disorder", "Appendicular Disorder", "Neuritis", "Neuropathy", "Sympathetic", "Systemic Disorders", "Other"]
    const handleDownloaddPDF = (e) => {
        document.getElementById("discharge-pdf-container").style.display = 'block'
        setTimeout(function () {
            generatePDF(getTargetElement, {filename: patientNameID})
        }, 500)
        setTimeout(function () {
            document.getElementById("discharge-pdf-container").style.display = 'none'
            setIsDownloadDPF(false)
        }, 500)

    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: "Patient Dashboard" },
                { label: patientNameID },
            ]} />
            <Card className="main-card mb-3">
                <CardTitle className="flex-space-between">
                    <div>Action Buttons</div>
                    <div>
                        <select className="form-control" onChange={(e) => handleChangeStatus(e)} defaultValue={patientDashboardData?.status}>
                            <option value="ACTIVE" selected={patientDashboardData?.status == "ACTIVE"}>Active</option>
                            <option value="COMPLIANT" selected={patientDashboardData?.status == "COMPLIANT"}>Compliant</option>
                            <option value="NON_COMPLIANT" selected={patientDashboardData?.status == "NON_COMPLIANT"}>Non Compliant</option>
                            <option value="LAMA" selected={patientDashboardData?.status == "LAMA"}>LAMA</option>

                        </select>
                    </div>

                </CardTitle>
                {/* <button style={{display:'none'}} onClick={() => generatePDF(getTargetElement, PDFOptions)}>Generate PDF</button> */}

                <CardBody>
                    <div className="d-flex flex-wrap" style={{ zoom: '90%', gap: '10px' }}>

                        {(permissions.includes("PATIENT_CREATE") || permissions.includes("PATIENT_UPDATE") || permissions.includes("PATIENT_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickEdit(e) }}>Profile</button>
                        )}
                        {(permissions.includes("PATIENT_MEDICAL_HISTORY_CREATE") || permissions.includes("PATIENT_MEDICAL_HISTORY_UPDATE") || permissions.includes("PATIENT_MEDICAL_HISTORY_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickPateintHistory(e) }}>Medical History</button>
                        )}
                        {(permissions.includes("PATIENT_DEEP_FORM_CREATE") || permissions.includes("PATIENT_DEEP_FORM_UPDATE") || permissions.includes("PATIENT_DEEP_FORM_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickDpep(e) }}>DPEP</button>
                        )}
                        {(permissions.includes("PATIENT_CDI_CREATE") || permissions.includes("PATIENT_CDI_UPDATE") || permissions.includes("PATIENT_CDI_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickCDI(e) }}>CDI</button>
                        )}
                        {(permissions.includes("PATIENT_TRIPLE_SIGN_CREATE") || permissions.includes("PATIENT_TRIPLE_SIGN_UPDATE") || permissions.includes("PATIENT_TRIPLE_SIGN_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickTripleLineForm(e) }}>Triple Line Form</button>
                        )}
                        {(permissions.includes("PATIENT_ECDI_CREATE") || permissions.includes("PATIENT_ECDI_UPDATE") || permissions.includes("PATIENT_ECDI_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickECDI(e) }}>ECDI</button>
                        )}
                        {(permissions.includes("PATIENT_DIAGNOSIS_CREATE") || permissions.includes("PATIENT_DIAGNOSIS_UPDATE") || permissions.includes("PATIENT_DIAGNOSIS_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickDiagnosis(e) }}>Diagnosis</button>
                        )}
                        {(permissions.includes("PATIENT_CSREF_FORM_6_CREATE") || permissions.includes("PATIENT_CSREF_FORM_6_UPDATE") || permissions.includes("PATIENT_CSREF_FORM_6_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickTendinopathies(e) }}>Tendinopathies And Bursitis</button>
                        )}
                        {(permissions.includes("PATIENT_CATEGORIZATION_CREATE") || permissions.includes("PATIENT_CATEGORIZATION_UPDATE") || permissions.includes("PATIENT_CATEGORIZATION_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickCategorization(e) }}>Categorization</button>
                        )}
                        {(permissions.includes("PATIENT_CSREF_FORM_5_CREATE") || permissions.includes("PATIENT_CSREF_FORM_5_UPDATE") || permissions.includes("PATIENT_CSREF_FORM_5_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickSuggestedTreatment(e) }}>Suggested Treatment</button>
                        )}
                        {(permissions.includes("PATIENT_TREATMENT_CREATE") || permissions.includes("PATIENT_TREATMENT_UPDATE") || permissions.includes("PATIENT_TREATMENT_READ") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClicktreatment(e) }}>Treatment</button>
                        )}
                        {(permissions.includes("CREATE_PATIENT_DOCUMENT") || permissions.includes("LIST_PATIENT_DOCUMENT") || role == "SUPER_ADMIN") && (
                        <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickLabTest(e) }}>Lab Test</button>
                        )}
                        {isDischarge && (
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickDischarge(e) }}>Discharge</button>
                        )}
                            
                    </div>
                </CardBody>
            </Card>
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left pe-2 fsize-1">
                                        <div className="widget-numbers mt-0 fsize-3 text-success">{overAllProgressScore.toFixed(2) + '%'}</div>
                                    </div>
                                </div>
                                <div className="widget-content-left fsize-1">
                                    <div className="text-muted opacity-6">Over All Progress Score</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-9">
                    <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content p-0" style={{ width: '100%' }}>
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Cervical Range Motion: <span className="text-success"><strong>{progressReportIndividual?.cervical_range_motion?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Cervical Spine: <span className="text-success"><strong>{progressReportIndividual?.cervical_spine?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">DPEP: <span className="text-success"><strong>{progressReportIndividual?.deep?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Cervical Spine: <span className="text-success"><strong>{progressReportIndividual?.l_sacral_spine?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Leg Level: <span className="text-success"><strong>{progressReportIndividual?.leg_level?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Leg Weakness: <span className="text-success"><strong>{progressReportIndividual?.leg_weakness?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Sacro-illiac Joints: <span className="text-success"><strong>{progressReportIndividual?.s_i?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Shoulder Level: <span className="text-success"><strong>{progressReportIndividual?.shoulder_level?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Shoulder Weakness: <span className="text-success"><strong>{progressReportIndividual?.shoulder_weakness?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <div className="text-muted opacity-8">Thoracic Spine: <span className="text-success"><strong>{progressReportIndividual?.thoracic_spine?.toFixed(2) + "%"}</strong></span></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left pe-2 fsize-1">
                                        <div className="widget-numbers mt-0 fsize-3 text-success">{patientDashboardData?.getTreatmentCounts}</div>
                                    </div>
                                </div>
                                <div className="widget-content-left fsize-1">
                                    <div className="text-muted opacity-6">Total Visits</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card-shadow-danger mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left pe-2 fsize-1">
                                        <div className={`widget-numbers mt-0 fsize-3 ${patientDashboardData?.grade?.grade == '1' ? 'text-success' : patientDashboardData?.grade?.grade == '2' ? 'text-info' : patientDashboardData?.grade?.grade == '3' ? 'text-warning' : 'text-danger'}`}>{patientDashboardData?.grade?.grade}</div>
                                    </div>
                                </div>
                                <div className="widget-content-left fsize-1">
                                    <div className="text-muted opacity-6">Grade</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card-shadow-success mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left pe-2 fsize-1">
                                        <div className="widget-numbers mt-0 fsize-3 text-success">{patientDashboardData?.bmi}
                                            <span
                                                style={{ fontSize: '16px', color: getBMICaregory(patientDashboardData?.bmi) == "Underweight" ? "red" : getBMICaregory(patientDashboardData?.bmi) == "Normal weight" ? "green" : getBMICaregory(patientDashboardData?.bmi) == "Overweight" ? "orange" : "red" }}
                                            >({getBMICaregory(patientDashboardData?.bmi)})</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="widget-content-left fsize-1">
                                    <div className="text-muted opacity-6">BMI</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card" style={{ backgroundColor: '#cfe9e7' }}>
                        <div className="widget-content">
                            <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left pe-2 fsize-1">
                                        <div className="widget-numbers mt-0 fsize-3 text-danger">{patientDashboardData && getTotalCdiScore(patientDashboardData?.getCDI)}</div>
                                    </div>
                                </div>
                                <div className="widget-content-left fsize-1">
                                    <div className="text-muted opacity-6">CDI Score</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-lg-4">
                    <Card className="main-card mb-3">
                        <CardTitle className="flex-space-between">Basic Info</CardTitle>

                        <CardBody>
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Name:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Age:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.age}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            CNIC:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.nic}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Date Of Birth:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.dob ? patientDashboardData?.dob.split('T')[0] : ''}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Weight:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.weight}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Height:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.height}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            BMI:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData?.bmi}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            Profession:
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            {patientDashboardData && getNameOfProfession(patientDashboardData?.profession)}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </CardBody>
                    </Card>
                    <Card className="main-card mb-3">
                        <CardTitle className="flex-space-between">Extended CDI</CardTitle>
                        <CardBody>
                            {patientDashboardData && patientDashboardData?.latestECDI && (
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <span class="cdi-label-description">Lateral Static</span>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Straightening:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.lateral_static_straightening}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Reversal:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.lateral_static_reversal}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Lazy S:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.lateral_static_lazy_s}
                                            </div>
                                        </div>
                                        <span class="cdi-label-description">Flexion</span>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Straightening:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.flexion_straightening}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Reversal:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.flexion_reversal}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Lazy S:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.flexion_lazy_s}
                                            </div>
                                        </div>
                                        <span class="cdi-label-description">Extension</span>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Straightening:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.extension_straightening}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Reversal:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.extension_reversal}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Lazy S:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData?.latestECDI?.extension_lazy_s}
                                            </div>
                                        </div>
                                        <span class="cdi-label-description">Deformity Grade</span>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Deformity Grade:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData && getDeformityGradeOfEcdi(patientDashboardData?.latestECDI?.deformity_grade)}
                                            </div>
                                        </div>
                                        <span class="cdi-label-description">Type</span>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                Type:
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                {patientDashboardData && getNameOfEcdi(patientDashboardData?.latestECDI?.type)}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div className="col-md-8 col-lg-8">
                    <div className="col-md-12 col-lg-12">
                        <Card className="main-card mb-3">
                            <CardTitle className="flex-space-between">Latest DPEP
                                <button className="btn btn-primary" onClick={(e) => { handleCompareDpep(e) }}>Compare</button>
                            </CardTitle>
                            {selectedCoords && (
                                <CardBody>
                                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                        <div>
                                            {categories && categories.map((item, index) => (
                                                <Card key={index}
                                                    // className="dpep-card"
                                                    className={selectedCategory == item?.db_key ? "selected-dpep-card" : "dpep-card"}
                                                    onClick={() => { handleClickCategory(item) }}
                                                >
                                                    <div className="d-flex dpep-category" style={{ width: '150px', padding: '5px 5px 5px 5px' }}>
                                                        <div style={{ fontSize: '10px' }}><span className="circle-link"
                                                            style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '2px' }}
                                                        >{alphabet[index]}</span>{item?.name}</div>
                                                        <div style={{ fontSize: '12px' }}>
                                                            <span className="circle-link"
                                                                style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '0px', backgroundColor: `${item?.color}` }}
                                                            >{selectedCoords[item?.db_key]?.length}
                                                            </span>

                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}



                                        </div>
                                        <div style={{ zoom: dpepZoom }}>

                                            <div className="image-container" style={{ position: 'relative' }}>
                                                <img src="/images/dpep-skeleton.png" usemap="#image-map" id='dpep-skeleton-image' />
                                                <map name="image-map">
                                                    {coordsPoint && coordsPoint.map((item, index) => {
                                                        return (
                                                            <area key={index}
                                                                // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                                                                // onClick={(e) => { handleClickCoords(e, item) }} 
                                                                alt={item?.key} title={item?.name} href="javascript:void(0);" coords={item?.coords} shape="poly" />
                                                        )
                                                    })}
                                                </map>
                                                <canvas
                                                    ref={canvasRef}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        pointerEvents: 'none',
                                                    }}
                                                    width={2000}
                                                    height={2000}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            )}
                        </Card>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <Card className="main-card mb-3">
                        <CardTitle className="flex-space-between">Latest Treatment</CardTitle>
                        <CardBody>
                            {patientDashboardData && patientDashboardData?.getLatestTreatment && (
                                <div style={{
                                    marginTop: '30px',
                                    padding: '10px',
                                    backgroundColor: '#47aaa830',
                                    borderRadius: '15px'
                                }}
                                // style={{ borderRadius: '15px', border: '1px solid silver',padding:'10px' }}
                                >
                                    <Container>
                                        <Row className="treatment-liest-container">
                                            {/* <Row>
                                                <Col md="3" className="border-1">
                                                    <p>Pateint Name: {patientDashboardData?.getLatestTreatment?.patient_info?.name}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Age: {patientDashboardData?.getLatestTreatment?.patient_info?.age}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Contact #: {patientDashboardData?.getLatestTreatment?.patient_info?.contact_number}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Date Of Birth: {patientDashboardData?.getLatestTreatment?.patient_info?.dob ?patientDashboardData?.getLatestTreatment?.patient_info?.dob.split('T')[0]:''}</p>
                                                </Col>
                                            </Row> */}
                                            <Col md="12" className="border-1 ">
                                                <p>Position of Examining a balance: <span className="patient_treatment_selected">{patientDashboardData?.getLatestTreatment?.position_examination_balance}</span></p>
                                            </Col>
                                            <Col md="3" style={{ padding: '0px 0px 10px 10px' }}>
                                                <Row className={`border-1`}>
                                                    <Col md="5" className="border-1" style={{ height: '70px' }}>
                                                        <p>Visit: {(patientDashboardData?.getLatestTreatment?.visit_number)}</p>
                                                    </Col>
                                                    <Col md="7" className="border-1" style={{ height: '70px' }}>
                                                        <p>Date</p>
                                                        <p>{patientDashboardData?.getLatestTreatment?.date}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1`} style={{ borderTop: '0px' }}>
                                                    <Col md="12" style={{ height: '161px', padding: '5px' }}>
                                                        <p>Remarks</p>
                                                        <p>{patientDashboardData?.getLatestTreatment?.remark}</p>
                                                    </Col>

                                                </Row>

                                            </Col>
                                            <Col md="2" style={{ marginRight: '0px', paddingRight: '0px' }}>
                                                <div className={`center-align border-1`} style={{ borderLeft: '0px' }}>Atlas Rotation</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', textAlign: 'center' }}>
                                                        <p>Right</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.atlas_rotation_right == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.atlas_rotation_right == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px', textAlign: 'center' }}>
                                                        <p>Left</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.atlas_rotation_left == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.atlas_rotation_left == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '76px' }}>Cervical Range of Motion</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    R
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_right == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_right == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_right == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_right == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_right == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>



                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    L
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_left == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_left == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_left == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_left == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${patientDashboardData?.getLatestTreatment?.cervical_range_motion_left == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0  margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.shoulder_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.shoulder_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Pelvic Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.pelvic_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.pelvic_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Length Difference</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.leg_length_diff_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.leg_length_diff_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.shoulder_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.shoulder_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.leg_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.leg_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '30px' }}>Edema of Thoracic Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T1</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t1}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T2</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t2}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T3</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t3}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T4</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t4}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T5</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t5}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T6</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t6}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T7</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t7}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T8</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t8}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T9</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t9}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T10</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t10}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T11</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t11}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T12</p>
                                                            <p>{patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 == 'Nil' ? 'Nil' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 == 'Mild' ? 'Mild' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 == 'Moderate' ? 'Mode' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 == 'Severe' ? 'S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12 == 'Very Severe' ? 'V S' : patientDashboardData?.getLatestTreatment?.edema_thoracic_spine_t12}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="3" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Edemas</div>
                                                <div className={`center-align border-1`}>Cervical Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.cervical_range_motion_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {patientDashboardData?.getLatestTreatment?.cervical_range_motion_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>L. Sacral Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L1</p>
                                                        <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.l_sacral_spine_l1}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L2</p>
                                                        <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.l_sacral_spine_l2}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L3</p>
                                                        <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.l_sacral_spine_l3}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L4</p>
                                                        <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.l_sacral_spine_l4}</p>
                                                    </Col>
                                                    <Col md="3" className="center-align padding-0 border-1 margin-0">
                                                        <p>L5</p>
                                                        <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.l_sacral_spine_l5}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="12" className="center-align padding-0 margin-0">
                                                            <p>Scaro-Illiac Joint</p>
                                                        </Col>

                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Right SI</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Left SI</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.right_si}</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{patientDashboardData?.getLatestTreatment?.left_si}</p>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <Card className="main-card mb-3">
                        <CardTitle className="flex-space-between">DPEP Chart Category Wise</CardTitle>
                        {console.log(DPEPReportChartData, 'DPEPReportChartData')}
                        <CardBody>
                            {DPEPReportChartData ? (
                                <Bar
                                    data={DPEPReportChartData}
                                    // options={optionsDPEPChart}
                                    width={100}
                                    height={50}

                                />
                            ) :
                                <div className="d-flex justify-content-center">
                                    <FontAwesomeIcon
                                        icon={['fas', 'spinner']}
                                        pulse
                                        fixedWidth
                                        size="4x"
                                        color="#cfe9e7"
                                    />
                                </div>
                            }
                        </CardBody>

                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <Card className="main-card mb-3">
                        <CardTitle className="flex-space-between">Treatment Chart Score</CardTitle>
                        <CardBody>
                            {treatmentReportChartData ? (
                                <Bar
                                    data={treatmentReportChartData}
                                    // options={optionsDPEPChart}
                                    width={100}
                                    height={50}

                                />
                            ) :
                                <div className="d-flex justify-content-center">
                                    <FontAwesomeIcon
                                        icon={['fas', 'spinner']}
                                        pulse
                                        fixedWidth
                                        size="4x"
                                        color="#cfe9e7"
                                    />
                                </div>
                            }
                        </CardBody>

                    </Card>
                </div>
            </div>
            <Modal isOpen={modalShow} toggle={(e) => { setModalShow(!modalShow) }} style={{ minWidth: '90%' }}>
                <ModalHeader toggle={(e) => { setModalShow(!modalShow) }} charCode="Y">Compare DPEP</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-6">
                            <select className="form-control" onChange={(e) => { handleSelectDpepFirst(e) }}>
                                <option value="">Select Dpep Visit</option>
                                {patientDashboardData?.getDeepPeepList?.rows && patientDashboardData?.getDeepPeepList?.rows.map((item, index) => (
                                    <option value={item?.id}>{`Visit ${index + 1}`}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-sm-6">
                            <select className="form-control" onChange={(e) => { handleSelectDpepSecond(e) }}>
                                <option value="">Select Dpep Visit</option>
                                {patientDashboardData?.getDeepPeepList?.rows && patientDashboardData?.getDeepPeepList?.rows.map((item, index) => (
                                    <option value={item?.id}>{`Visit ${index + 1}`}</option>
                                ))}
                            </select>
                        </div>
                        <hr className="mt-4" />
                        <div className="col-md-6 col-lg-6">
                            {console.log(selectedCoordsFirst, 'selectedCoordsFirst')}
                            <Card className="main-card mb-3">
                                {isLoaderCompareDpepFirst && <Loader />}
                                {selectedCoordsFirst && (
                                    <CardBody>
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                {categories && categories.map((item, index) => (
                                                    <Card key={index}
                                                        // className="dpep-card"
                                                        className={selectedCategoryFirst == item?.db_key ? "selected-dpep-card" : "dpep-card"}
                                                        onClick={() => { handleClickCategoryCompare(item) }}
                                                    >
                                                        <div className="d-flex dpep-category" style={{ width: '150px', padding: '5px 5px 5px 5px' }}>
                                                            <div style={{ fontSize: '10px' }}><span className="circle-link"
                                                                style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '2px' }}
                                                            >{alphabet[index]}</span>{item?.name}</div>
                                                            <div style={{ fontSize: '12px' }}>
                                                                <span className="circle-link"
                                                                    style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '0px', backgroundColor: `${item?.color}` }}
                                                                >{selectedCoordsFirst[item?.db_key]?.length}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </Card>
                                                ))}



                                            </div>
                                            <div style={{ zoom: dpepZoomCompare }}>

                                                <div className="image-container" style={{ position: 'relative' }}>
                                                    <img src="/images/dpep-skeleton.png" usemap="#image-map" id='dpep-skeleton-image' />
                                                    <map name="image-map">
                                                        {coordsPoint && coordsPoint.map((item, index) => {
                                                            return (
                                                                <area key={index}
                                                                    // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                                                                    // onClick={(e) => { handleClickCoords(e, item) }} 
                                                                    alt={item?.key} title={item?.name} href="javascript:void(0);" coords={item?.coords} shape="poly" />
                                                            )
                                                        })}
                                                    </map>
                                                    <canvas
                                                        ref={canvasRefFirst}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            pointerEvents: 'none',
                                                        }}
                                                        width={2000}
                                                        height={2000}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                )}
                            </Card>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <Card className="main-card mb-3">
                                {isLoaderCompareDpepSecond && <Loader />}
                                {selectedCoordsSecond && (
                                    <CardBody>
                                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                {categories && categories.map((item, index) => (
                                                    <Card key={index}
                                                        // className="dpep-card"
                                                        className={selectedCategorySecond == item?.db_key ? "selected-dpep-card" : "dpep-card"}
                                                        onClick={() => { handleClickCategoryCompareSecond(item) }}
                                                    >
                                                        <div className="d-flex dpep-category" style={{ width: '150px', padding: '5px 5px 5px 5px' }}>
                                                            <div style={{ fontSize: '10px' }}><span className="circle-link"
                                                                style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '2px' }}
                                                            >{alphabet[index]}</span>{item?.name}</div>
                                                            <div style={{ fontSize: '12px' }}>
                                                                <span className="circle-link"
                                                                    style={{ width: '16px', height: '16px', lineHeight: '14px', marginRight: '0px', backgroundColor: `${item?.color}` }}
                                                                >{selectedCoordsSecond[item?.db_key]?.length}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </Card>
                                                ))}



                                            </div>
                                            <div style={{ zoom: dpepZoomCompare }}>

                                                <div className="image-container" style={{ position: 'relative' }}>
                                                    <img src="/images/dpep-skeleton.png" usemap="#image-map" id='dpep-skeleton-image' />
                                                    <map name="image-map">
                                                        {coordsPoint && coordsPoint.map((item, index) => {
                                                            return (
                                                                <area key={index}
                                                                    // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                                                                    // onClick={(e) => { handleClickCoords(e, item) }} 
                                                                    alt={item?.key} title={item?.name} href="javascript:void(0);" coords={item?.coords} shape="poly" />
                                                            )
                                                        })}
                                                    </map>
                                                    <canvas
                                                        ref={canvasRefSecond}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            pointerEvents: 'none',
                                                        }}
                                                        width={2000}
                                                        height={2000}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                )}
                            </Card>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => { setModalShow(!modalShow) }}>Close</Button>
                </ModalFooter>
            </Modal>


            {console.log(dischargeTreatment, 'dischargeTreatment')}
            {console.log(dischargeDetailData, 'dischargeDetailData')}
            {/* DischargeModal */}
            <Modal isOpen={isDischrageModal} toggle={(e) => { setIsDischrageModal(!isDischrageModal) }} style={{ minWidth: '90%' }}>
                <ModalHeader toggle={(e) => { setIsDischrageModal(!isDischrageModal) }} charCode="Y">Discharge Form</ModalHeader>
                <ModalBody>

                    <div className="row">
                        <FormGroup>
                            <Label for="cus_created_at">Custom Created At</Label>
                            <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                        </FormGroup>
                        <div className="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: '#49a1d4', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}><h6 className="mb-0">Initial Diagnois</h6></div>
                                <ul>
                                    {dischargeTreatment?.dignosis_initial && dischargeTreatment?.dignosis_initial.map((item, index) => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                                {/* <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"1"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 1) }}
                                        />
                                        Spinal Asymmetry
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"2"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 2) }}
                                        />
                                        Spinal Disorder
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"3"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 3) }}
                                        />
                                        Appendicular Disorder
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"4"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 4) }}
                                        />
                                        Neuritis
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"5"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 5) }}
                                        />
                                        Neuropathy
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"6"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 6) }}
                                        />
                                        Sympathetic
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"7"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 7) }}
                                        />
                                        Systemic Disorders
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"initial_diagnosis"} id={"initial_diagnosis_" + "0"} defaultValue={"8"} type={"checkbox"}
                                            onChange={(e) => { handleClickInitialDiagnosis(e, 'initial_diagnosis', 8) }}
                                        />
                                        Other
                                    </Label>
                                </FormGroup> */}
                                <div style={{ backgroundColor: '#49a1d4', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h6>Final Diagnosis</h6></div>
                                <ul>
                                    {dischargeTreatment?.dignosis_latest && dischargeTreatment?.dignosis_latest.map((item, index) => (
                                        <li>{item}</li>
                                    ))}
                                </ul>

                                {/* <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"1"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 1) }}
                                        />
                                        Spinal Asymmetry
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"2"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 2) }}
                                        />
                                        Spinal Disorder
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"3"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 3) }}
                                        />
                                        Appendicular Disorder
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"4"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 4) }}
                                        />
                                        Neuritis
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"5"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 5) }}
                                        />
                                        Neuropathy
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"6"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 6) }}
                                        />
                                        Sympathetic
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"7"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 7) }}
                                        />
                                        Systemic Disorders
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input name={"finial_diagnosis"} id={"finial_diagnosis" + "0"} defaultValue={"8"} type={"checkbox"}
                                            onChange={(e) => { handleClickFinalDiagnosis(e, 'finial_diagnosis', 8) }}
                                        />
                                        Other
                                    </Label>
                                </FormGroup> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: '#f1914a', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}><h6 className="mb-0">Treatments</h6></div>
                                <FormGroup>
                                    <Label for="pulsar_sessions">Total Pulsar Sessions Done</Label>
                                    <Input type="number" readOnly name="pulsar_sessions" id="pulsar_sessions" defaultValue={dischargeDetailData?.grade?.treatment?.total_treatments_done} onChange={(e) => handleDischargeTreatment('pulsar_sessions', e.target.value)}
                                        placeholder="Total Pulsar Sessions Done" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="steroid_injection">Steroid I/A Injection</Label>
                                    <Input type="textarea" name="steroid_injection" id="steroid_injection" onChange={(e) => handleDischargeTreatment('steroid_injection', e.target.value)}
                                        placeholder="Steroid I/A Injection" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="hyaluronidase_prp_inj">Hyaluronidase / PRP Inj.</Label>
                                    <Input type="textarea" name="hyaluronidase_prp_inj" id="hyaluronidase_prp_inj" onChange={(e) => handleDischargeTreatment('hyaluronidase_prp_inj', e.target.value)}
                                        placeholder="Hyaluronidase / PRP Inj." />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="steroid_injection">Medicines</Label>
                                    <Input type="textarea" name="medicines" id="medicines" onChange={(e) => handleDischargeTreatment('medicines', e.target.value)}
                                        placeholder="Medicines" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="steroid_injection">Physiotherapy</Label>
                                    <Input type="textarea" name="physiotherapy" id="physiotherapy" onChange={(e) => handleDischargeTreatment('physiotherapy', e.target.value)}
                                        placeholder="Physiotherapy" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="steroid_injection">Surgery</Label>
                                    <Input type="textarea" name="surgery" id="surgery" onChange={(e) => handleDischargeTreatment('surgery', e.target.value)}
                                        placeholder="Surgery" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="steroid_injection">Others</Label>
                                    <Input type="textarea" name="others" id="others" onChange={(e) => handleDischargeTreatment('others', e.target.value)}
                                        placeholder="Others" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: '#9ccb54', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}><h6 className="mb-0">Comments</h6></div>
                                <FormGroup>
                                    <Label for="grade">Patient Injury Grade</Label>
                                    <Input type="number" readOnly name="grade" id="grade" defaultValue={dischargeDetailData?.grade?.grade} onChange={(e) => handleDischargeTreatment('grade', e.target.value)}
                                        placeholder="Grade" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="grade">Duration Of Treatment</Label>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Input style={{ width: '45%' }} readOnly type="date" name="treatment_duration_from" id="treatment_duration_from" defaultValue={dischargeDetailData?.grade?.treatment?.from?.split('T')[0]} onChange={(e) => handleDischargeTreatment('treatment_duration_from', e.target.value)}
                                            placeholder="From" />
                                        <span>To</span>
                                        <Input style={{ width: '45%' }} readOnly type="date" name="treatment_duration_to" id="treatment_duration_to" defaultValue={dischargeDetailData?.grade?.treatment?.to?dischargeDetailData?.grade?.treatment?.to?.split('T')[0]:dischargeDetailData?.grade?.treatment?.from?.split('T')[0]} onChange={(e) => handleDischargeTreatment('treatment_duration_to', e.target.value)}
                                            placeholder="To" />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="subjective_improvement">Subjective Improvement %</Label>
                                    <Input type="number" name="subjective_improvement" id="subjective_improvement" onChange={(e) => handleDischargeTreatment('subjective_improvement', e.target.value)}
                                        placeholder="Subjective Improvement %" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="objective_improvement">Objective Improvement %</Label>
                                    <Input type="number" name="objective_improvement" id="objective_improvement" onChange={(e) => handleDischargeTreatment('objective_improvement', e.target.value)}
                                        placeholder="Objective Improvement %" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="objective_improvement">Disability Index %:</Label>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Input style={{ width: '45%' }} type="number" name="disability_index_initial" id="disability_index_initial" onChange={(e) => handleDischargeTreatment('disability_index_initial', e.target.value)}
                                            placeholder="Initial" />
                                        <Input style={{ width: '45%' }} type="number" name="disability_index_final" id="disability_index_final" onChange={(e) => handleDischargeTreatment('disability_index_final', e.target.value)}
                                            placeholder="Final" />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="objective_improvement">Radiological Improvement Score %:</Label>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>Initial</div>
                                            <Input style={{ width: '100%' }} type="number" name="radiological_improvement_score_initial" id="radiological_improvement_score_initial" 
                                            // onChange={(e) => handleDischargeTreatment('radiological_improvement_score_initial', e.target.value)}
                                                placeholder="Initial"
                                                readOnly
                                                defaultValue={dischargeTreatment?.cdi_initial}
                                            />
                                        </div>
                                        <div>
                                            <div>Final</div>
                                            <Input style={{ width: '100%' }} type="number" name="radiological_improvement_score_final" id="radiological_improvement_score_final" 
                                            // onChange={(e) => handleDischargeTreatment('radiological_improvement_score_final', e.target.value)}
                                                placeholder="Final"
                                                readOnly
                                                defaultValue={dischargeTreatment?.cdi_latest}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button color="success" onClick={(e) => { handleSubmitDischargeData(e) }}>Save</Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={isDownloadDPF} toggle={(e) => { setIsDownloadDPF(!isDischrageModal) }} style={{ minWidth: '30%' }}>
                <ModalHeader toggle={(e) => { setIsDownloadDPF(!isDownloadDPF) }} charCode="Y">Download PDF</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className="btn btn-primary" onClick={(e) => { handleDownloaddPDF(e) }}> Download Discharge Form </button>
                    </div>
                </ModalBody>
            </Modal>
            <div id="discharge-pdf-container" style={{ display: 'none' }}>
                <div style={{ border: "4px solid silver", padding: '10px',margin:'10px' }}>
                    <div className='row mb-4'>
                        <div className="col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Name:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Age:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.age}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    CNIC:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.nic}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Date Of Birth:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.dob ? patientDashboardData?.dob.split('T')[0] : ''}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">

                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Weight:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.weight}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Height:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.height}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    BMI:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData?.bmi}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    Profession:
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    {patientDashboardData && getNameOfProfession(patientDashboardData?.profession)}
                                </div>
                            </div>
                        </div>
                    </div>
                    {console.log(initialDiagnosis, 'initialDiagnosisinitialDiagnosis')}
                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: 'rgb(73, 161, 212)', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <h6 class="mb-0">Initial Diagnois</h6>
                                </div>
                                <ul>
                                    {dischargeTreatment?.dignosis_initial && dischargeTreatment?.dignosis_initial.map((item, index) => (
                                        <li>{item}</li>
                                    ))}

                                </ul>
                                <div style={{ backgroundColor: 'rgb(73, 161, 212)', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h6>Final Diagnosis</h6>
                                </div>
                                <ul>
                                    {dischargeTreatment?.dignosis_latest && dischargeTreatment?.dignosis_latest.map((item, index) => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: 'rgb(241, 145, 74)', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <h6 class="mb-0">Treatments</h6>
                                </div>
                                <p>Total Pulsar Sessions Done: <strong>{dischargeDetailData?.grade?.treatment?.total_treatments_done}</strong></p>
                                <p>Steroid I/A Injection: <strong>{dischargeTreatment?.steroid_injection}</strong></p>
                                <p>Hyaluronidase / PRP Inj. <strong>{dischargeTreatment?.hyaluronidase_prp_inj}</strong></p>
                                <p>Medicines <strong>{dischargeTreatment?.medicines}</strong></p>
                                <p>Physiotherapy <strong>{dischargeTreatment?.physiotherapy}</strong></p>
                                <p>Surgery <strong>{dischargeTreatment?.surgery}</strong></p>
                                <p>Others <strong>{dischargeTreatment?.others}</strong></p>

                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div style={{ border: '1px solid silver', padding: '0px 10px' }}>
                                <div style={{ backgroundColor: 'rgb(156, 203, 84)', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <h6 class="mb-0">Comments</h6>
                                </div>
                                <p>Patient Injury Grade <strong>{dischargeDetailData?.grade?.grade}</strong></p>
                                <p>Duration Of Treatment <strong>{dischargeTreatment?.treatment_duration_from ? dischargeTreatment?.treatment_duration_from.split('T')[0] : null} To {dischargeTreatment?.treatment_duration_to ? dischargeTreatment?.treatment_duration_to.split('T')[0] : null}</strong></p>
                                <p>Subjective Improvement <strong>{dischargeTreatment?.subjective_improvement}%</strong></p>
                                <p>Objective Improvement <strong>{dischargeTreatment?.objective_improvement}%</strong></p>
                                <p>Disability Index :<strong>Initial:{dischargeTreatment?.disability_index_initial}%, Final:{dischargeTreatment?.disability_index_final}%</strong></p>
                                <p>Radiological Improvement Score :<strong>Initial: {dischargeTreatment?.cdi_initial}%, Final: {dischargeTreatment?.cdi_latest}%</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default PatientList;
