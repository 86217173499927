import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Container
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getPatientTreatmentListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
const CenterList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [treatmentData, setTreatmentData] = useState({});
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);

    useEffect(() => {
        if (params?.patient_id) {
            getPatientTreatmentList(centerId, params?.patient_id, page, rowsPerPage)
        }
    }, [params?.patient_id]);
    useEffect(() => {
        getPatientTreatmentList(centerId, params?.patient_id, page, rowsPerPage)
    }, [page]);
    const getPatientTreatmentList = async (centerId, pateintId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getPatientTreatmentListAPI(centerId, pateintId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddPatientClick = () => {
        history.push('/patient/add')
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/edit/${id}`)
    }
    const handleClickPateintHistory = (e, id) => {
        history.push(`/patient/medical-histories/${id}`)
    }
    const handleClicktreatment = (e, id) => {
        history.push(`/patient/treatments/list/${id}`)
    }
    const handleAddPatientTreatmentClick = () => {
        history.push(`/patient/treatments/add/${params?.patient_id}`)
    }
    const handleEditPatientTreatmentClick = (treatment_id) => {
        history.push(`/patient/treatments/edit/${treatment_id}`)
    }
    const checkCurrentDate = (date) => {
        const givenDate = new Date(date);
        const currentDate = new Date();
        if (givenDate.toISOString().split('T')[0] === currentDate.toISOString().split('T')[0]) {
            return true
        } else {
            return false
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: " Treatment List" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Treatment List
                        {(permissions.includes("PATIENT_TREATMENT_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={(e) => handleAddPatientTreatmentClick()}>
                                Add Patient Treatment
                            </button>
                        )}
                    </CardTitle>
                    {/* <div className="treatment-list-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td rowSpan={"3"}>Visit #</td>
                                    <td rowSpan={"3"}>Date</td>
                                    <td colspan="4">Atlas Rotation</td>
                                    <td colspan="4">Shoulder Level</td>
                                    <td colspan="4">Shoulder Weakness</td>
                                    <td colspan="5">Edamas Cervical Spine</td>
                                </tr>
                                <tr>
                                    <td colspan="2">Right</td>
                                    <td colspan="2">Left</td>
                                    <td colspan="2">Right</td>
                                    <td colspan="2">Left</td>
                                    <td colspan="2">Right</td>
                                    <td colspan="2">Left</td>
                                    <td colspan="3">Right</td>
                                    <td colspan="2">Left</td>
                                </tr>
                                <tr>

                                    <td>Ant</td>
                                    <td>Post</td>
                                    <td>Ant</td>
                                    <td>Post</td>
                                    <td colspan="2">Shoulder Level right</td>
                                    <td colspan="2">Shoulder Level Left</td>
                                    <td colspan="2">Shoulder Weekness right</td>
                                    <td colspan="2">Shoulder Weekness Left</td>
                                    <td colspan="3">cervical spine right</td>
                                    <td colspan="2">cervical spine Left</td>
                                </tr>
                                <tr>
                                    <td rowspan="4" colspan="2">Remarks</td>
                                    <td rowspan="2" colspan="6">Cervical Range of Motion</td>
                                    <td  colspan="2">Pelvic Level</td>
                                    <td  colspan="2">Leg Weekness</td>
                                    <td  colspan="7">L. Sacral Spine</td>
                                </tr>
                                <tr>
                                    <td>Right</td>
                                    <td>Left</td>
                                    <td>Right</td>
                                    <td>Left</td>
                                    <td>L1</td>
                                    <td>L2</td>
                                    <td>L3</td>
                                    <td>L4</td>
                                    <td colspan="5">L5</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>1<p>90°</p></td>
                                    <td>2<p>60</p></td>
                                    <td>3<p>30°</p></td>
                                    <td>4<p>{"<30°"}</p></td>
                                    <td>5<p>{"<=10°"}</p></td>
                                    <td colspan="2">Leg Length Diff</td>
                                    <td colspan="6">Edama of Thoracic Spine</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>1<p>90°</p></td>
                                    <td>2<p>60</p></td>
                                    <td>3<p>30°</p></td>
                                    <td>4<p>{"<30°"}</p></td>
                                    <td>5<p>{"<=10°"}</p></td>
                                    <td>Leg Length Right</td>
                                    <td>Leg Length Left</td>
                                    <td>T1</td>
                                    <td>T2</td>
                                    <td>T3</td>
                                    <td>T4</td>
                                    <td>T5</td>
                                    <td>T6</td>
                                    
                                </tr>
                                <tr>
                                    <td>T7</td>
                                    <td>T8</td>
                                    <td>T9</td>
                                    <td>T10</td>
                                    <td>T11</td>
                                    <td>T2</td>
                                    <td>Right SI</td>
                                    <td>Left SI</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {patientList && patientList.map((item, index) => {
                            return (
                                <div style={{
                                    marginTop: '30px',
                                    padding: '10px',
                                    backgroundColor: '#47aaa830',
                                    borderRadius: '15px'
                                }}
                                // style={{ borderRadius: '15px', border: '1px solid silver',padding:'10px' }}
                                >
                                    <Container>
                                        <Row className="treatment-liest-container">
                                            {/* <Row>
                                                <Col md="3" className="border-1">
                                                    <p>Pateint Name: {item?.patient_info?.name}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Age: {item?.patient_info?.age}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Contact #: {item?.patient_info?.contact_number}</p>
                                                </Col>
                                                <Col md="3" className="border-1">
                                                    <p>Date Of Birth: {item?.patient_info?.dob ?item?.patient_info?.dob.split('T')[0]:''}</p>
                                                </Col>
                                            </Row> */}
                                            <Col md="10" className="border-1 ">
                                                <p>Position of Examining a balance: <span className="patient_treatment_selected">{item?.position_examination_balance}</span></p>
                                            </Col>
                                            <Col md="2" className="border-1 " style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                {checkCurrentDate(item?.date) && (permissions.includes("PATIENT_TREATMENT_UPDATE") || role == "SUPER_ADMIN") && (
                                                    <button className="btn btn-primary" onClick={(e) => handleEditPatientTreatmentClick(item?.id)}>
                                                        Edit
                                                    </button>
                                                )}
                                            </Col>
                                            <Col md="3" style={{ padding: '0px 0px 10px 10px' }}>
                                                <Row className={`border-1`}>
                                                    <Col md="5" className="border-1" style={{ height: '70px' }}>
                                                        <p>Visit: {item?.visit_number}</p>
                                                    </Col>
                                                    <Col md="7" className="border-1" style={{ height: '70px' }}>
                                                        <p>Date</p>
                                                        <p>{item?.date}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1`} style={{ borderTop: '0px' }}>
                                                    <Col md="12" style={{ height: '161px', padding: '5px' }}>
                                                        <p>Remarks</p>
                                                        <p>{item?.remark}</p>
                                                    </Col>

                                                </Row>

                                            </Col>
                                            <Col md="2" style={{ marginRight: '0px', paddingRight: '0px' }}>
                                                <div className={`center-align border-1`} style={{ borderLeft: '0px' }}>Atlas Rotation</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', textAlign: 'center' }}>
                                                        <p>Right</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_right == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_right == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="6" className="padding-0 border-1 margin-0" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px', textAlign: 'center' }}>
                                                        <p>Left</p>
                                                        <Row className={`border-1 margin-0`} style={{ borderRight: '0px' }}>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_left == "Anterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Ant
                                                                </div>
                                                            </Col>
                                                            <Col md="6" className="padding-0 border-1" style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}>
                                                                <div
                                                                    className={`center-align border-1 custom-radio ${item?.atlas_rotation_left == "Posterior" ? 'patient_treatment_selected' : ''}`}>
                                                                    Post
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '76px' }}>Cervical Range of Motion</div>
                                                <Row className="margin-0">
                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    R
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_right == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>



                                                    <Col md="12" className="padding-0">
                                                        <Row className="margin-0">
                                                            <Col md="2" className="padding-0">
                                                                <div style={{ height: '38px', padding: '5px' }}
                                                                    className={`center-align border-1 custom-radio`}>
                                                                    L
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>1</p>
                                                                    <p>90°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Mild" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>2</p>
                                                                    <p>60°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Moderate" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>3</p>
                                                                    <p>30°</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>4</p>
                                                                    <p>{"<30°"}</p>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" className="padding-0">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    flexDirection: 'column'
                                                                }}
                                                                    className={`center-align border-1 custom-radio ${item?.cervical_range_motion_left == "Very Severe" ? 'patient_treatment_selected' : ''}`}>
                                                                    <p>5</p>
                                                                    <p>{"<=10°"}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0  margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {item?.shoulder_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 margin-0">

                                                        <div
                                                            className={`center-align  custom-radio yellow-text`}>
                                                            {item?.shoulder_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Pelvic Level</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.pelvic_level_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.pelvic_level_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Length Difference</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_length_diff_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_length_diff_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="2" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Shoulder Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.shoulder_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.shoulder_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>Leg Weakness</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_weakness_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className=" padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>
                                                        <div
                                                            className={`center-align border-1 custom-radio yellow-text`}>
                                                            {item?.leg_weakness_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`} style={{ height: '30px' }}>Edema of Thoracic Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${item?.edema_thoracic_spine_t1 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T1</p>
                                                            <p>{item?.edema_thoracic_spine_t1 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t1 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t1 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t1 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t1 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t1}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align ${item?.edema_thoracic_spine_t2 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T2</p>
                                                            <p>{item?.edema_thoracic_spine_t2 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t2 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t2 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t2 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t2 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t2}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t3 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T3</p>
                                                            <p>{item?.edema_thoracic_spine_t3 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t3 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t3 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t3 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t3 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t3}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t4 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T4</p>
                                                            <p>{item?.edema_thoracic_spine_t4 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t4 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t4 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t4 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t4 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t4}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t5 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T5</p>
                                                            <p>{item?.edema_thoracic_spine_t5 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t5 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t5 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t5 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t5 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t5}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t6 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T6</p>
                                                            <p>{item?.edema_thoracic_spine_t6 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t6 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t6 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t6 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t6 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t6}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t7 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T7</p>
                                                            <p>{item?.edema_thoracic_spine_t7 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t7 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t7 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t7 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t7 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t7}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t8 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T8</p>
                                                            <p>{item?.edema_thoracic_spine_t8 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t8 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t8 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t8 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t8 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t8}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t9 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T9</p>
                                                            <p>{item?.edema_thoracic_spine_t9 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t9 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t9 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t9 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t9 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t9}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t10 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T10</p>
                                                            <p>{item?.edema_thoracic_spine_t10 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t10 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t10 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t10 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t10 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t10}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t11 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T11</p>
                                                            <p>{item?.edema_thoracic_spine_t11 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t11 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t11 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t11 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t11 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t11}</p>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <div
                                                            className={`center-align  ${item?.edema_thoracic_spine_t12 != "Nil" ? 'patient_treatment_selected' : ''}`}>
                                                            <p>T12</p>
                                                            <p>{item?.edema_thoracic_spine_t12 == 'Nil' ? 'Nil' : item?.edema_thoracic_spine_t12 == 'Mild' ? 'Mild' : item?.edema_thoracic_spine_t12 == 'Moderate' ? 'Mode' : item?.edema_thoracic_spine_t12 == 'Severe' ? 'S' : item?.edema_thoracic_spine_t12 == 'Very Severe' ? 'V S' : item?.edema_thoracic_spine_t12}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="3" className="padding-0" style={{ marginRight: '0px' }}>
                                                <div className={`center-align border-1`}>Edemas</div>
                                                <div className={`center-align border-1`}>Cervical Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Right</p>

                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">
                                                        <p style={{ textAlign: 'center' }}>Left</p>

                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {item?.cervical_range_motion_right}
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="center-align padding-0 border-1 margin-0">

                                                        <div
                                                            className={`center-align custom-radio yellow-text`}>
                                                            {item?.cervical_range_motion_left}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className={`center-align border-1`}>L. Sacral Spine</div>
                                                <Row className={`border-1 margin-0`}>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L1</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l1}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L2</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l2}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L3</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l3}</p>
                                                    </Col>
                                                    <Col md="2" className="center-align padding-0 border-1 margin-0">
                                                        <p>L4</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l4}</p>
                                                    </Col>
                                                    <Col md="3" className="center-align padding-0 border-1 margin-0">
                                                        <p>L5</p>
                                                        <p className="yellow-text">{item?.l_sacral_spine_l5}</p>
                                                    </Col>
                                                </Row>
                                                <Row className={`border-1 margin-0`}>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="12" className="center-align padding-0 margin-0">
                                                            <p>Scaro-Illiac Joint</p>
                                                        </Col>

                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Right SI</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p>Left SI</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className={`border-1 margin-0`}>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{item.right_si}</p>
                                                        </Col>
                                                        <Col md="6" className="center-align padding-0 margin-0">
                                                            <p className="yellow-text">{item.left_si}</p>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )
                        })}
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CenterList;
