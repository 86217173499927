import React, { useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';
import Nav from '../AppNav/VerticalNavWrapper';
import { setEnableMobileMenu } from '../../reducers/ThemeOptions';

const AppSidebar = ({
  enableBackgroundImage,
  enableSidebarShadow,
  enableMobileMenu,
  backgroundColor,
  backgroundImageOpacity,
  setEnableMobileMenu
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <>
      {mobileMenuOpen && <div className="sidebar-mobile-overlay" onClick={() => setMobileMenuOpen(false)} />}
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-sidebar", backgroundColor, {'sidebar-shadow': enableSidebarShadow})}
          appear={true} timeout={1500} enter={false} exit={false}>
          <div>
            <HeaderLogo />
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Nav />
              </div>
            </PerfectScrollbar>
            <div
              className={cx("app-sidebar-bg", backgroundImageOpacity)}>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

const mapStateToProps = state => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
