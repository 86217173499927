import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getDiagnosisListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
const DiagnosisList = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const params = useParams()
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);

    useEffect(() => {
        if (centerId) {
            getDiagnosisList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getDiagnosisList(centerId, page, rowsPerPage)
    }, [page]);
    const getDiagnosisList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getDiagnosisListAPI(centerId, params?.patient_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDiagnosisList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddDiagnosisClick = () => {
        history.push(`/patient/diagnosis/add/${params?.patient_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/diagnosis/edit/${params?.patient_id}/${id}`)
    }
    const getNameofRheumatoidArthritis = (ids) => {
        let allDdata = [
            { JUVINILE: { key: 'JUVINILE', value: 1, name: 'Juvinile' } },
            { PAUCI_ARTICULAR: { key: 'PAUCI_ARTICULAR', value: 2, name: 'Pauci Articular' } },
            { POLY_ARTICUALAR: { key: 'POLY_ARTICUALAR', value: 3, name: 'Poly Articular' } },
            { RA_POSITIVE: { key: 'RA_POSITIVE', value: 4, name: '*RA +ve' } },
            { ANTI_CPP_POSITIVE: { key: 'ANTI_CPP_POSITIVE', value: 5, name: 'Anti-CCP +ye' } },
            { RA_NEGATIVE: { key: 'RA_NEGATIVE', value: 6, name: '*RA-ve' } },
            { ANTI_CPP_NEGATIVE: { key: 'ANTI_CPP_NEGATIVE', value: 7, name: 'Anti-CCP -ve' } },
            { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 8, name: 'Not Applicable' } },
        ]
        ids = ids.map(Number);
        const filteredData = allDdata.filter(item => {
            const value = Object.values(item)[0].value;
            return ids.includes(value);
        });
        const names = filteredData.map(item => Object.values(item)[0].name).join(', ');
        return names
    }
    const getNameofSpinal = (ids) => {
        console.log(ids, 'ids')
        let allDdata = [
            { SPONDYLOSIS: { key: 'SPONDYLOSIS', value: 1, name: 'Spondylosis' } },
            { SPONDYLOSIS: { key: 'DDD', value: 2, name: 'DDD' } },
            { DISC_BULGE: { key: 'DISC_BULGE', value: 3, name: 'Disc Bulge' } },
            { DISC_PROTRUSION: { key: 'DISC_PROTRUSION', value: 4, name: 'Disc Protrusion' } },
            { SPONDYLOLISTHESIS: { key: 'SPONDYLOLISTHESIS', value: 5, name: 'Spondylolisthesis' } },
            { SCOLIOSIS: { key: 'SCOLIOSIS', value: 6, name: 'Scoliosis' } },
            { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 7, name: 'Degenerative Scoliosis' } },
            { OSTEOPOROSIS: { key: 'OSTEOPOROSIS', value: 8, name: 'Osteoporosis' } },
            { OCHRONOSIS: { key: 'OCHRONOSIS', value: 9, name: 'Ochronosis' } },
            { COCCYDYNIA: { key: 'COCCYDYNIA', value: 10, name: 'Coccydynia' } },
            { ANKYLOSIS: { key: 'ANKYLOSIS', value: 11, name: 'Ankylosis' } },
            { SPONDYLITIS: { key: 'SPONDYLITIS', value: 12, name: 'Spondylitis' } },
            { SPINAL_STENOSIS: { key: 'SPINAL_STENOSIS', value: 13, name: 'Spinal Stenosis' } },
            { RADICULITIS: { key: 'RADICULITIS', value: 14, name: 'Radiculitis' } },
            { RADICULOPATHY: { key: 'RADICULOPATHY', value: 15, name: 'Radiculopathy' } },
            { MYELOPATHY: { key: 'MYELOPATHY', value: 16, name: 'Myelopathy' } },
            { SPINOCEREBELLAR_DISEASE: { key: 'SPINOCEREBELLAR_DISEASE', value: 17, name: 'Spinocerebellar Disease' } },
            { MOTOR_NEURON_DISEASE: { key: 'MOTOR_NEURON_DISEASE', value: 18, name: 'Motor Neuron Disease' } },
            { SYRINGOMYELIA: { key: 'SYRINGOMYELIA', value: 19, name: 'Syringomyelia' } },
            { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 20, name: 'Cervical Dystonia' } },
            { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 21, name: 'Not Applicable' } },
            { OTHER: { key: 'OTHER', value: 22, name: 'Other' } },
        ]
        ids = ids.map(Number);
        const filteredData = allDdata.filter(item => {
            const value = Object.values(item)[0].value;
            return ids.includes(value);
        });
        console.log(filteredData, 'filteredData')
        // return filteredData
        const names = filteredData.map(item => Object.values(item)[0].name).join(', ');
        console.log(names, 'names')
        return names
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Diagnosis" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Diagnosis List
                        {(permissions.includes("PATIENT_DIAGNOSIS_CREATE") || role == 'SUPER_ADMIN') && (
                            <button className="btn btn-primary" onClick={() => handleAddDiagnosisClick()}>
                                Add Diagnosis
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Spinal</th>
                                    <th>Rheumatoid Arthritis</th>
                                    <th>Created At</th>
                                    {(permissions.includes("PATIENT_DIAGNOSIS_UPDATE") || role == "SUPER_ADMIN") && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {diagnosisList && diagnosisList.map((item, index) => {
                                    console.log(getNameofSpinal(item?.spinal), 'getNameofSpinal(item?.spinal)')
                                    // console.log(getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', '),'getNameofSpinal(item?.spinal)dfd')
                                    // let spinalName = getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', ')
                                    return (
                                        <tr key={index}>
                                            <td>{getNameofSpinal(item?.spinal)}</td>
                                            <td>{getNameofRheumatoidArthritis(item?.rheumatoid_arthritis)}</td>
                                            <td>{dateTimeFormate(item?.created_at)}</td>
                                            {(permissions.includes("PATIENT_DIAGNOSIS_UPDATE") || role == "SUPER_ADMIN") && (
                                                <td>
                                                    <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default DiagnosisList;
