import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getMachineListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
const MachineList = () => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [machineList, setMachineList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    // const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));

    useEffect(() => {
        // if (centerId) {
        getMachineList(page, rowsPerPage)
        // }
    }, []);
    const getMachineList = async (page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getMachineListAPI(page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setMachineList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddMachineClick = () => {
        history.push('/machine/add')
    }
    const handleClickEdit = (e, id) => {
        history.push(`/machine/edit/${id}`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Machines" },
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Machine List
                        <button className="btn btn-primary" onClick={() => handleAddMachineClick()}>
                            Add Machine
                        </button>
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Center Name</th>
                                    <th>Version</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineList && machineList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.name}</td>
                                        <td>{item?.center_info?.name}</td>
                                        <td>{item?.version}</td>
                                        {/* {permissions.includes("MACHINE_UPDATE") && ( */}
                                        <td>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                        </td>
                                        {/* )} */}


                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default MachineList;
