export const MainNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'Dashboard',
        to: '/dashboard',
    },
];

export const CenterNav = [
    {
        icon: 'pe-7s-like',
        label: 'Manage Center',
        to: '/centers',
    },
];
export const ProfessionNav = [
    {
        icon: 'pe-7s-like',
        label: 'Manage Profession',
        to: '/professions',
    },
];
export const StaffNav = [
    {
        icon: 'pe-7s-users',
        label: 'Manage Member',
        to: '/members',
    },
];
export const machineNav = [
    {
        icon: 'pe-7s-tools',
        label: 'Manage Machine',
        to: '/machines',
    },
];
export const patientNav = [
    {
        icon: 'pe-7s-users',
        label: 'Manage Patients',
        to: '/patients',
    },
];
export const TratmentNav = [
    {
        icon: 'pe-7s-users',
        label: 'Patients Treatments',
        to: '/patient-treatments',
    },
];
export const rolesNav = [
    {
        icon: 'pe-7s-users',
        label: 'Manage Roles',
        to: '/roles',
    },
];
export const ReportNav = [
    {
        icon: 'pe-7s-server',
        label: 'Reporting',
        to: '/reporting',
    },
];
export const TestCategoryNav = [
    {
        icon: 'pe-7s-server',
        label: 'Test',
        to: '/test-categories',
    },
];