import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitMemberForCenterAPI, getMemberDetailAPI, updateMemberForCenterAPI, getDegreeListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import userUtil from '../../utils/UserUtils';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [degreeList, setDegreeList] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [memberType, setMemberType] = useState("");
    const [nic, setNic] = useState("");
    const [degree, setDegree] = useState("");
    const [status, setStatus] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [memberTypeError, setMemberTypeError] = useState(false);
    const [nicError, setNicError] = useState(false);
    const [degreeError, setDegreeError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    useEffect(() => {
        getDegreeList()
        if (params?.staff_id) {
            getMemberDetail(params?.staff_id)
        }
    }, [params?.staff_id]);

    useEffect(() => {
        const inputElement = document.getElementById('email');
        if (inputElement) {
            inputElement.setAttribute('value', ' ');
        }
    }, []);
    useEffect(() => {
        const inputElement = document.getElementById('address');
        if (inputElement) {
            inputElement.setAttribute('value', ' ');
        }
    }, []);
    useEffect(() => {
        const inputElement = document.getElementById('password');
        if (inputElement) {
            inputElement.setAttribute('value', '');
        }
    }, []);

    const getMemberDetail = async (memberId) => {
        setIsLoader(true)
        const response = await getMemberDetailAPI(userUtil?.userData?.center_id, memberId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setFirstName(response?.data?.name)
            setEmail(response?.data?.email)
            setPhone(response?.data?.phone)
            setAddress(response?.data?.address)
            setMemberType(response?.data?.role?.name)
            setNic(response?.data?.nic)
            setDegree(response?.data?.degree)
            setStatus(response?.data?.status)

            // setDob(response?.data?.dob?.split('T')[0])
            // setPhone(response?.data?.contact_number)
            // setGender(response?.data?.gender.toString())
            // setAddress(response?.data?.address)
            // setProfession(response?.data?.profession)
            // setNic(response?.data?.nic)
        }
    }
    const getDegreeList = async () => {
        setIsLoader(true)
        const response = await getDegreeListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDegreeList(response?.data)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        if (firstName == '' || email == '' || phone == '' || address == '' || password == '' || memberType == '' || nic == '' || degree == '') {
            if (firstName == '') {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName == '') {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (email == '') {
                setEmailError(true)
            } else {
                if (isValidEmail(email)) {
                    setEmailError(false)
                } else {
                    setEmailError(true)
                    return;
                }

            }
            if (phone == '') {
                setPhoneError(true)
            } else {
                setPhoneError(false)
            }
            if (address == '') {
                setAddressError(true)
            } else {
                setAddressError(false)
            }
            if (password == '') {
                setPasswordError(true)
            } else {
                if (password.length >= 6) {
                    setPasswordError(false)
                } else {
                    setPasswordError(true)
                    return;
                }
            }
            if (memberType == '') {
                setMemberTypeError(true)
            } else {
                setMemberTypeError(false)
            }
            if (nic == '') {
                setNicError(true)
            } else {
                setNicError(false)
            }
            if (degree == '') {
                setDegreeError(true)
            } else {
                setDegreeError(false)
            }
        } else {
            if (isValidEmail(email)) {
                setEmailError(false)
            } else {
                setEmailError(true)
                return;
            }
            const regex = /^(?:\+92|0)[0-9]{10}$/;
            if (!regex.test(phone)) {
                setPhoneError(true);
                return;
            }
            const regexNic = /^\d{5}-?\d{7}-?\d{1}$/;
            console.log(nic, 'nic')
            if (!regexNic.test(nic)) {
                setNicError(true);
                return;
            }
            if (params?.staff_id) {
                if (status == '') {
                    setStatusError(true)
                    return;
                } else {
                    setStatusError(false)
                }
                handleUpdateUser();
            } else {
                handleSubmitUser();
            }

        }
    }
    const handleUpdateUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.staff_id,
            name: firstName,
            phone: phone,
            address: address,
            password: password == "" ? null : password,
            member_type: memberType,
            nic: nic,
            degree: degree,
            status: status
        }
        const response = await updateMemberForCenterAPI(userUtil?.userData?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Member Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/members')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: firstName,
            email: email,
            phone: phone,
            address: address,
            password: password,
            member_type: memberType,
            nic: nic,
            degree: degree,
        }
        const response = await submitMemberForCenterAPI(userUtil?.userData?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Member Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/members')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleChangeContactNumber = (value) => {
        console.log(value, 'value')
        const inputValue = value;
        const regex = /^(?:\+92|0)[0-9]{10}$/;

        if (regex.test(inputValue)) {
            setPhoneError(false);
            setPhone(inputValue);
        } else {
            setPhone(inputValue);
            setPhoneError(true);
        }
    };
    const handleChangeCNIC = (value) => {
        console.log(value, 'value')
        const regex = /^\d{5}-?\d{7}-?\d{1}$/;

        if (regex.test(value)) {
            console.log('accepted')
            setNic(value);
            setNicError(false);
        } else {
            console.log('not accepted')
            setNic(value);
            setNicError(true);
        }
    };
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Members", url: "/members" },
                        { label: params?.staff_id ? "Edit Member" : "Add Member" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.staff_id ? "Edit Member" : "Add Member"}</CardTitle>

                            <FormGroup>
                                <Label for="f_name"> Name</Label>
                                <Input type="text" name="f_name" id="f_name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Enter Name" />
                                {firstNameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" readOnly={params?.staff_id ? true : false} defaultValue={email} onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter Email" autoComplete="new-email" />
                                {emailError && (
                                    <span className="field-error">Enter Valid Email to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Contact No.</Label>
                                <Input type="number" name="phone" id="phone" defaultValue={phone} onChange={(e) => handleChangeContactNumber(e.target.value)}
                                    placeholder="Enter Contact Nummber" />
                                <p className="cdi-label-description">Contact No. Format: 0300*******</p>
                                {phoneError && (
                                    <span className="field-error">Enter Contact Number to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <Input type="text" name="address" id="address" defaultValue={address} onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Enter Address" autoComplete="new-address" />
                                {addressError && (
                                    <span className="field-error">Enter Address to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter Password" autoComplete="new-password" />
                                {passwordError && (
                                    <span className="field-error">Enter Password to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Member Type</Label>
                                <Input type="select" name="select" id="exampleSelect" defaultValue={memberType} onChange={(e) => setMemberType(e.target.value)}>
                                    <option value="">Select Type</option>
                                    <option value="STAFF" selected={memberType == "STAFF" ? true : false}>Staff</option>
                                    <option value="CONSULTANT_DOCTOR" selected={memberType == "CONSULTANT_DOCTOR" ? true : false}>Consultant Doctor</option>
                                    <option value="TREATMENT_DOCTOR" selected={memberType == "TREATMENT_DOCTOR" ? true : false}>Treatment Doctor</option>
                                </Input>
                                {memberTypeError && (
                                    <span className="field-error">Select Member Type to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="nic">NIC</Label>
                                <Input type="number" name="nic" id="nic" defaultValue={nic} onChange={(e) => handleChangeCNIC(e.target.value)}
                                    placeholder="Enter NIC" />
                                <p className="cdi-label-description">NIC Format: 35202********</p>
                                {nicError && (
                                    <span className="field-error">Enter NIC to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="degree">Degree</Label>
                                <Input type="select" name="degree" id="degree" defaultValue={degree} onChange={(e) => setDegree(e.target.value)}>
                                    <option value="">Select Degree</option>
                                    {degreeList && degreeList.map((item, index) => (
                                        <option value={item?.id} selected={degree == item?.id ? true : false}>{item?.name}</option>
                                    ))}
                                </Input>
                                {degreeError && (
                                    <span className="field-error">Enter Degree to Continue</span>
                                )}
                            </FormGroup>
                            {params?.staff_id &&
                                (
                                    <FormGroup>
                                        <Label for="status">Status</Label>
                                        <Input type="select" name="status" id="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">Select Status</option>
                                            <option value={true} selected={status === true}>Active</option>
                                            <option value={false} selected={status === false}>In Active</option>

                                        </Input>
                                        {statusError && (
                                            <span className="field-error">Select Status to Continue</span>
                                        )}
                                    </FormGroup>
                                )
                            }
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
