import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Container, Label, FormGroup, Input, Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
    getCenterListSuggestionAPI, getTreatmentDoneStatsAPI, getPatientStatusStatsAPI, getPatientRegistrationStatsAPI,
    getTreatmentDoneStatsDetailAPI, getPatientStatusStatsDetailAPI, getPatientRegistrationStatsDetailAPI

} from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import Autosuggest from 'react-autosuggest';
const SuperAdminReporting = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [timeFrame, setTimeFrame] = useState("Today");
    const [patientStatus, setPatientStatus] = useState("Active");
    const [treatmentDoneStats, setTreatmentDoneStats] = useState(null);
    const [patientStatusStats, setPatientStatusStats] = useState(null);
    const [patientRegistrationStats, setPatientRegistrationStats] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [detailTitle, setDetailTitle] = useState('');

    useEffect(() => {
        const elements = document.querySelectorAll('.react-autosuggest__suggestions-container');
        if (suggestions.length == 0) {
            elements.forEach(element => {
                element.style.display = 'none';
            });
        } else {
            elements.forEach(element => {
                element.style.display = 'block';
            });
        }
    }, [suggestions]);


    const onSuggestionsFetchRequested = async ({ value }) => {
        setIsLoader(true)
        setSearchQuery(value)
        const response = await getCenterListSuggestionAPI(value, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setSuggestions(response?.data?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    };
    const onSuggestionsClearRequested = (e) => {
        console.log(searchQuery, 'onSuggestionsClearRequested')
        if (searchQuery == "") {
            setSuggestions([])
            setSelectedCenter("")
        }
    };
    const onSuggestionSelected = (event, { suggestion }) => {
        console.log(suggestion?.id, 'suggestion onSuggestionSelected')
        setSelectedCenter(suggestion?.id)
        setPatientList(null)
    };
    const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

    const renderInputComponent = inputProps => (
        <input {...inputProps} />
    );
    const onChange = (event, { newValue }) => {
        setSearchQuery(newValue);
    };
    const getSuggestionValue = suggestion => suggestion.name;
    function createQueryString(filterType, centerId, startDate, endDate) {
        let queryString = '';

        if (filterType !== null) {
            queryString += `filter_type=${encodeURIComponent(filterType)}&`;
        }

        if (selectedCenter !== null && selectedCenter !== "") {
            queryString += `center_id=${encodeURIComponent(centerId)}&`;
        }

        if (startDate !== null) {
            queryString += `from_date=${encodeURIComponent(startDate)}&`;
        }

        if (endDate !== null) {
            queryString += `to_date=${encodeURIComponent(endDate)}&`;
        }

        // Remove the trailing '&' if the query string is not empty
        if (queryString.length > 0) {
            queryString = queryString.slice(0, -1);
        }

        return queryString;
    }
    const handleSearch = (e) => {
        setDetailData(null)
        const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
        getTreatmentDoneStats(queryString)
        getPatientStatusStats(queryString)
        getPatientRegistrationStats(queryString)
    }
    const getTreatmentDoneStats = async (queryString) => {
        setIsLoader(true)
        const response = await getTreatmentDoneStatsAPI(queryString, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setTreatmentDoneStats(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientStatusStats = async (queryString) => {
        setIsLoader(true)
        const response = await getPatientStatusStatsAPI(queryString + `&status=${patientStatus}`, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientStatusStats(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientRegistrationStats = async (queryString) => {
        setIsLoader(true)
        const response = await getPatientRegistrationStatsAPI(queryString, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientRegistrationStats(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getTreatmentDoneStatsDetail = async (queryString) => {
        setDetailTitle('Total Treatment Done')
        setIsLoader(true)
        const response = await getTreatmentDoneStatsDetailAPI(queryString + '&list=true&offset=0&limit=5000', cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientStatusStatsDetail = async (queryString) => {
        setDetailTitle(`Patient Status (${patientStatus})`)
        setIsLoader(true)
        const response = await getPatientStatusStatsDetailAPI(queryString + `&list=true&status=${patientStatus}&offset=0&limit=5000`, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientRegistrationStatsDetail = async (queryString) => {
        setIsLoader(true)
        setDetailTitle(`Total Patient Registered`)
        const response = await getPatientRegistrationStatsDetailAPI(queryString + '&list=true&offset=0&limit=5000', cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (key) => {
        if (key == 'treatmentDoneStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getTreatmentDoneStatsDetail(queryString)
        }
        if (key == 'patientStatusStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getPatientStatusStatsDetail(queryString)
        }
        if (key == 'patientRegistrationStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getPatientRegistrationStatsDetail(queryString)
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}

            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Reporting" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Reporting
                    </CardTitle>
                    <div>
                        <Row className="treatment-liest-container">
                            <Col md="3">
                                <Label>Search By Center Name</Label>
                                <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    onSuggestionSelected={onSuggestionSelected}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                        placeholder: 'Search By Center Name...',
                                        value: searchQuery, // Make sure 'value' is properly defined
                                        onChange: onChange,
                                        className: 'form-control'
                                    }}
                                />
                            </Col>
                            <Col md="3" >
                                <FormGroup>
                                    <Label for="f_name">Select Time Frame</Label>
                                    <Input type="select" name="time_frame" id="time_frame" defaultValue={timeFrame} onChange={(e) => { setTimeFrame(e.target.value) }} >
                                        <option value="">Select</option>
                                        <option value="Today" selected={timeFrame == "Today"}>Today</option>
                                        <option value="Yesterday" selected={timeFrame == "Yesterday"}>Yesterday</option>
                                        <option value="Last_7_days" selected={timeFrame == "Last 7 days"}>Last 7 days</option>
                                        <option value="Last_15_days" selected={timeFrame == "Last 15 days"}>Last 15 days</option>
                                        <option value="Last_month" selected={timeFrame == "Last Month"}>Last Month</option>
                                        <option value="custom_date" selected={timeFrame == "Custom Date"}>Custom Date</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            {timeFrame == "custom_date" && (
                                <>
                                    <Col md="3" >
                                        <FormGroup>
                                            <Label for="f_name">Start Date</Label>
                                            <Input type="date" name="s_date" id="s_date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                            <span style={{ color: 'red' }}>{startDateError}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" >
                                        <FormGroup>
                                            <Label for="f_name">End Date</Label>
                                            <Input type="date" name="e_date" id="e_date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                            <span style={{ color: 'red' }}>{endDateError}</span>
                                        </FormGroup>
                                    </Col>
                                </>
                            )}
                            <Col md="3">
                                <FormGroup>
                                    <Label for="f_name">Select Patient Status</Label>
                                    <Input type="select" name="patient_status" id="patient_status" defaultValue={patientStatus} onChange={(e) => { setPatientStatus(e.target.value) }} >

                                        <option value="Active" selected={patientStatus == "Active"}>Active</option>
                                        <option value="Completed" selected={patientStatus == "Completed"}>Completed</option>
                                        <option value="LAMA" selected={patientStatus == "LAMA"}>LAMA</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3" style={{ display: 'flex', alignItems: 'center', marginTop: '18px' }}>
                                <Button onClick={(e) => { handleSearch(e) }}>Search</Button>
                            </Col>
                        </Row>
                    </div>
                    {treatmentDoneStats && (
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('treatmentDoneStats') }}
                                            >
                                                <div className="icon-wrapper rounded-circle">
                                                    <div className="icon-wrapper-bg bg-white opacity-10" />
                                                    <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                </div>
                                                <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                    Total Treatment Done
                                                </div>
                                                <div className="widget-description text-white">
                                                    {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                    <span className="ps-1 fs-32">{treatmentDoneStats?.count}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('patientStatusStats') }}
                                            >
                                                <div className="icon-wrapper rounded-circle">
                                                    <div className="icon-wrapper-bg bg-white opacity-10" />
                                                    <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                </div>
                                                <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                    Patient Status <strong>({patientStatus}) </strong>
                                                </div>
                                                <div className="widget-description text-white">
                                                    {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                    <span className="ps-1 fs-32">{patientStatusStats?.count}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('patientRegistrationStats') }}
                                            >
                                                <div className="icon-wrapper rounded-circle">
                                                    <div className="icon-wrapper-bg bg-white opacity-10" />
                                                    <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                </div>
                                                <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                    Total Patient Registration
                                                </div>
                                                <div className="widget-description text-white">
                                                    {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                    <span className="ps-1 fs-32">{patientRegistrationStats?.count}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    )}
                </CardBody>
            </Card>
            {detailData && (
                <Card className="main-card mb-3">
                    <CardBody>
                        <CardTitle className="flex-space-between">{detailTitle}</CardTitle>
                        <div class="table-responsive">
                            <Table striped className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Status</th>
                                        <th>Center Name</th>
                                        <th>Treatment By</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailData && detailData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.patient_info?.name ? item?.patient_info?.name : item?.name}</td>
                                            <td>{item?.patient_info?.status ? item?.patient_info?.status : item?.status}</td>
                                            <td>{item?.center_info?.name}</td>
                                            <td>{item?.user_created?.name}</td>
                                            <td>{item?.created_at.split('T')[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default SuperAdminReporting;
