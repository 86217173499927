import React, { useState } from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const pages = Array.from({ length: Math.ceil(totalPages) }, (_, index) => index + 1);
  console.log(pages, 'pages')
  console.log(totalPages, 'totalPages')
  const getPageNumbers = () => {
    if (totalPages <= 7) {
      return pages;
    } else if (currentPage <= 4) {
      return [...pages.slice(0, 5), '...', totalPages];
    } else if (currentPage > totalPages - 4) {
      return [1, '...', ...pages.slice(totalPages - 5, totalPages)];
    } else {
      return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <button
        style={{ margin: '0px 5px' }}
        className="btn btn-primary"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {getPageNumbers().map((page, index) => (
        <button
          style={{ margin: '0px 5px' }}
          key={index}
          disabled={page == '...'}
          onClick={() => onPageChange(page)}
          className={currentPage === page ? 'active btn btn-primary' : ' btn btn-primary'}
        >
          {page}
        </button>
      ))}
      <button
        style={{ margin: '0px 5px' }}
        className="btn btn-primary"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
