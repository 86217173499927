import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { addPatientMedicalHistoryAPI, getPatientMedicalHistoryDetailAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import { QuestionList } from './questions-list';
import { Stepper } from 'react-form-stepper';
import Select from 'react-select';
// let questions = [
//     {
//         sort: 1,
//         question: 'Cranio Cervical Rotation Type',
//         key: 'cranio_cervical_rotation_type',
//         description: 'Tick all that apply',
//         type: 'single-select',
//         options: ['AN OL', 'AN OR', 'AN ON', 'AR OR', 'AR OL', 'AR ON', 'AL OL', 'AL OR', 'AL ON', 'A0 OL', 'A0 OR', 'A0 ON'],
//     }
// ];

// // Function to convert question format
// function convertQuestionFormat(question) {
//     let convertedOptions = question.options.map((option, index) => {
//         let optionObject = {};
//         optionObject[option.replace(/ /g, '_').toUpperCase()] = {
//             key: option.replace(/ /g, '_').toUpperCase(),
//             value: index + 1,
//             name: option,
//         };
//         return optionObject;
//     });

//     let convertedQuestion = {
//         question: question.question,
//         key: question.key,
//         description: question.description,
//         options: convertedOptions,
//     };
//     return convertedQuestion;
// }
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [questionValue, setQuestionValue] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [totalStep, setTotalStep] = useState(3);
    const [questionsStepWise, setQuestionsStepWise] = useState([]);
    const [showArthroscopy, setShowArthroscopy] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showAssociatedDiseasesArthroscopyOther, setShowAssociatedDiseasesArthroscopyOther] = useState(false);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [associatedDiseasesArthroscopy, setAssociatedDiseasesArthroscopy] = useState(
        {
            left_knee: false,
            right_knee: false,
            left_shoulder: false,
            right_shoulder: false,
            other: false,
            remark: null
        }
    );
    const [previousSurgeriesFractureFixation, setPreviousSurgeriesFractureFixation] = useState(
        {
            bone: "",
            area_of_bone: "",
            type_of_fixation: "",
            other: false,
            remark: ""
        }
    );
    const [previousSurgeriesSpine, setPreviousSurgeriesSpine] = useState(
        {
            decompression: false,
            fusion: false,
            discectomy: false,
            other: false,
            remark: ""
        }
    );
    const [previousSurgeriesCSection, setPreviousSurgeriesCSection] = useState(
        {
            c_1: false,
            c_2: false,
            c_3: false,
            c_4: false,
            c_5: false,
            other: false,
            remark: ""
        }
    );

    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);


    useEffect(() => {
        if (params?.medical_history_id) {
            getPatientMedicalHistoryDetail(params?.medical_history_id)

        }
    }, [params?.medical_history_id]);
    useEffect(() => {
        if (refresh) {
            setRefresh(false)

        }
    }, [refresh]);
    useEffect(() => {
        const totalQuestions = QuestionList.length;
        const questionsPerStep = [4, 4, 5]; // Define how many questions in each step

        const questionSteps = [];
        let currentStep = 0;
        let currentStepQuestions = [];

        QuestionList.forEach((question, index) => {
            if (currentStepQuestions.length >= questionsPerStep[currentStep]) {
                // Move to the next step
                questionSteps.push(currentStepQuestions);
                currentStepQuestions = [];
                currentStep++;
            }
            currentStepQuestions.push(question);

            // If this is the last question, push the remaining questions
            if (index === totalQuestions - 1) {
                questionSteps.push(currentStepQuestions);
            }
        });
        setQuestionsStepWise(questionSteps)
        console.log(questionSteps, 'questionSteps')
        console.log(QuestionList, 'QuestionList')
    }, []);

    const getPatientMedicalHistoryDetail = async () => {
        setIsLoader(true)
        const response = await getPatientMedicalHistoryDetailAPI(centerId, params?.patient_id, params?.medical_history_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setQuestionValue(response?.data)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const isvalid = () => {
        const aKeys = Object.keys(questionValue);
        const bKeys = QuestionList.map(item => item.key);
        return bKeys.every(key => aKeys.includes(key));
    }
    const handleSubmit = () => {
        // e.preventDefault()
        console.log(isvalid(), 'isvalid()')
        if (!isvalid()) {
            toast("Please Fill all the fields", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return
        } else {
            handleSubmitHistory()
        }
    }
    const handleSubmitHistory = async () => {
        setIsLoader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const response = await addPatientMedicalHistoryAPI(centerId, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Medical History added Successfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClickChild = (e, parentKey, key, childKey, checkRadio) => {
        if (parentKey == 'associated_diseases') {
            if (checkRadio) {
                associatedDiseasesArthroscopy[childKey] = e.target.checked
                questionValue[key] = associatedDiseasesArthroscopy;
                if (childKey == 'other') {
                    if (e.target.checked) {
                        setShowAssociatedDiseasesArthroscopyOther(true)
                    } else {
                        setShowAssociatedDiseasesArthroscopyOther(false)
                    }
                }
            } else {
                associatedDiseasesArthroscopy[childKey] = e.target.value
                questionValue[key] = associatedDiseasesArthroscopy;
            }
        }

        if (parentKey == 'previous_surgeries') {
            if (key == 'previous_surgeries_fracture_fixation') {
                if (checkRadio) {
                    previousSurgeriesFractureFixation[childKey] = e.target.value
                    questionValue[key] = previousSurgeriesFractureFixation;
                    if (childKey == 'other') {
                        if (e.target.checked) {
                            setShowAssociatedDiseasesArthroscopyOther(true)
                        } else {
                            setShowAssociatedDiseasesArthroscopyOther(false)
                        }
                    }
                } else {
                    previousSurgeriesFractureFixation[childKey] = e.target.value
                    questionValue[key] = previousSurgeriesFractureFixation;
                }
            }
            if (key == 'previous_surgeries_spine') {
                if (checkRadio) {
                    previousSurgeriesSpine[childKey] = e.target.checked
                    questionValue[key] = previousSurgeriesSpine;
                    if (childKey == 'other') {
                        if (e.target.checked) {
                            setShowAssociatedDiseasesArthroscopyOther(true)
                        } else {
                            setShowAssociatedDiseasesArthroscopyOther(false)
                        }
                    }
                } else {
                    previousSurgeriesSpine[childKey] = e.target.value
                    questionValue[key] = previousSurgeriesSpine;
                }
            }
            if (key == 'previous_surgeries_c_section') {
                if (checkRadio) {
                    previousSurgeriesCSection[childKey] = e.target.checked
                    questionValue[key] = previousSurgeriesCSection;
                    if (childKey == 'other') {
                        if (e.target.checked) {
                            setShowAssociatedDiseasesArthroscopyOther(true)
                        } else {
                            setShowAssociatedDiseasesArthroscopyOther(false)
                        }
                    }
                } else {
                    previousSurgeriesCSection[childKey] = e.target.value
                    questionValue[key] = previousSurgeriesCSection;
                }
            }
        }
    }
    const handleClick = (e, data, optionData) => {
        console.log(optionData, 'optionData')
        console.log(e, 'e')
        console.log(data, 'data')
        setRefresh(true)
        console.log(data, 'data')
        if (optionData == '') {
            let multiSelectTagData = e;
            let multiSelectTagDataValues = []
            if (multiSelectTagData) {
                if (multiSelectTagData.length > 0) {
                    for (let a of multiSelectTagData) {
                        multiSelectTagDataValues.push(a?.value)
                    }
                }
            }
            questionValue[data?.key] = multiSelectTagDataValues
        } else {
            if (data?.type == 'single-select') {
                questionValue[data?.key] = optionData
                setQuestionValue(questionValue)
            } else {
                var selectedValues = [];
                var checkboxes = document.getElementsByName(data?.key);

                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        console.log(checkboxes[i].value, 'checkboxes[i].value')
                        selectedValues.push(checkboxes[i].value);

                    }
                }
                if (data?.key == 'associated_diseases') {
                    if (optionData == 'Arthroscopy') {
                        setShowArthroscopy(e.target.checked)
                        if (!e.target.checked) {
                            delete questionValue['associated_diseases_arthroscopy']
                        } else {
                            questionValue['associated_diseases_arthroscopy'] = associatedDiseasesArthroscopy
                        }
                    }
                }
                if (data?.key == 'previous_surgeries') {
                    if (optionData == 'Fracture Fixation') {
                        setShowArthroscopy(e.target.checked)
                        if (!e.target.checked) {
                            delete questionValue['previous_surgeries_fracture_fixation']
                        } else {
                            questionValue['previous_surgeries_fracture_fixation'] = previousSurgeriesFractureFixation
                        }
                    }
                    if (optionData == 'Spine') {
                        setShowArthroscopy(e.target.checked)
                        if (!e.target.checked) {
                            delete questionValue['previous_surgeries_spine']
                        } else {
                            questionValue['previous_surgeries_spine'] = previousSurgeriesSpine
                        }
                    }
                    if (optionData == 'C-Section') {
                        setShowArthroscopy(e.target.checked)
                        if (!e.target.checked) {
                            delete questionValue['previous_surgeries_c_section']
                        } else {
                            questionValue['previous_surgeries_c_section'] = previousSurgeriesCSection
                        }
                    }
                }
                questionValue[data?.key] = selectedValues
                setQuestionValue(questionValue)
            }
        }
        console.log(questionValue, 'questionValue')
    }
    const isEmptyObject = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const handleBackButtonClick = () => {
        if (activeStep == 1) {
            history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            setActiveStep(activeStep - 1)
        }
    }
    const handleNextButtonClick = () => {
        if (activeStep == totalStep) {
            handleSubmit()
        } else {
            setActiveStep(activeStep + 1)
        }
    }
    const isCheckField = (parentKey, key, childKey) => {
        if (questionValue[parentKey]) {
            if (questionValue[parentKey][key]) {
                if (questionValue[parentKey][key][childKey]) {
                    console.log(questionValue[parentKey][key][childKey], 'questionValue[parentKey][key][childKey]')
                    return questionValue[parentKey][key][childKey];
                }
            }
        }
    }
    const getRandom = () => {
        return Math.floor(Math.random() * 9001)
    }
    const handleChangeCustomDate = (e)=>{
        questionValue['customCreatedAt'] = e.target.value
        setQuestionValue(questionValue)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                {console.log(questionValue, 'questionValue')}
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Medical History", url: `/patient/medical-histories/${params?.patient_id}` },
                        { label: " Add Medical History" },
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.medical_history_id ? "View" : "Add"}{" "} Patient Medical History</CardTitle>
                            <Stepper
                                steps={[{ label: '' }, { label: '' }, { label: '' }]}
                                activeStep={activeStep - 1}
                                styleConfig={{
                                    activeBgColor: '#47aaa8',
                                    inactiveBgColor: '#cfe8e7',
                                    completedBgColor: '#128886',
                                }}
                            />
                            {/* <Row>
                                {questionsStepWise && questionsStepWise.map((questionItem, QuestionIndex) => (
                                    <>
                                        {activeStep == parseInt(QuestionIndex + 1) &&
                                            <>
                                                {questionItem && questionItem.map((item, index) => {
                                                    console.log(item, 'item item ')
                                                    return (
                                                        <Col xs={12} md={6} lg={6} xl={6} className="d-flex">
                                                            <div className="flex-fill">
                                                                <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                                    <legend className="col-form-label">{item?.sort}{". "}{item?.question}</legend>

                                                                    {item?.options && item?.options.map((optionItem, optionIndex) => {
                                                                        let isCheck = false;
                                                                        if (item?.type === 'multi-select') {
                                                                            if (params?.medical_history_id && !isEmptyObject(questionValue)) {
                                                                                if (questionValue[item?.key].includes(optionItem)) {
                                                                                    console.log('ansbdjashjasbhdjas')
                                                                                    isCheck = true;
                                                                                }
                                                                            }
                                                                        } else {
                                                                            if (questionValue[item?.key] === optionItem) {
                                                                                isCheck = true;
                                                                            }
                                                                        }
                                                                        return (
                                                                            <FormGroup check style={{ marginLeft: '30px' }} key={optionIndex}>
                                                                                <Label check>
                                                                                    <Input name={item?.key} checked={isCheck} defaultValue={optionItem} type={item?.type === "single-select" ? "radio" : "checkbox"} onChange={(e) => { handleClick(e, item, optionItem) }} />
                                                                                    {" "}{optionItem}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        );
                                                                    })}
                                                                </FormGroup>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                            </>
                                        }
                                    </>

                                ))}
                            </Row> */}
                            {/* <hr className="my-5" />
                            <div className="row align-items-center">
                                <Col xs="auto">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            handleBackButtonClick(1);
                                        }}
                                    >
                                        {activeStep == 1 ? "Cancel" : "Back"}
                                    </button>
                                </Col>
                                <Col className="text-center">
                                </Col>
                                <Col xs="auto">
                                    <button className="btn btn-primary" onClick={(e) => handleNextButtonClick()}>
                                        {activeStep == totalStep ? "Submit" : "Next"}
                                    </button>
                                </Col>
                            </div> */}



                            {/* {activeStep === 1 && stepOne} */}
                            {console.log(QuestionList, 'QuestionList')}
                            <Row>
                                {refresh}
                                {QuestionList && QuestionList.map((item, index) => {
                                    let startQuestion = 1;
                                    let endQuestion = 1;
                                    if (activeStep == 1) {
                                        startQuestion = 1
                                        endQuestion = 5
                                    }
                                    if (activeStep == 2) {
                                        startQuestion = 6
                                        endQuestion = 10
                                    }
                                    if (activeStep == 3) {
                                        startQuestion = 11
                                        endQuestion = 13
                                    }
                                    let optionValues = item?.options
                                    let dataOptionValue = []
                                    for (let a of optionValues) {
                                        dataOptionValue.push({ value: a, label: a })
                                    }
                                    let dataOptionDefaultValue = []
                                    if (params?.medical_history_id && !isEmptyObject(questionValue)) {
                                        for (let a of questionValue[item?.key]) {
                                            dataOptionDefaultValue.push({ value: a, label: a })
                                        }
                                    }

                                    if (item?.sort >= startQuestion && item?.sort <= endQuestion) {
                                        return (
                                            <Col xs={12} md={activeStep == 3 ? '12' : '6'} lg={activeStep == 3 ? '12' : '6'} xl={activeStep == 3 ? '12' : '6'}>
                                                {index >= 10 ?
                                                    <div>
                                                        <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                            <legend className="col-form-label">{item?.sort}{". "}{item?.question}</legend>
                                                            <Select
                                                                isMulti
                                                                defaultValue={dataOptionDefaultValue}
                                                                name="colors"
                                                                options={dataOptionValue}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={(e) => { handleClick(e, item, '') }}
                                                            />

                                                            {index == 12 && (
                                                                <FormGroup style={{ marginTop: '10px' }}>
                                                                    <legend className="col-form-label">14. Custom Created At</legend>
                                                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => handleChangeCustomDate(e)} />
                                                                </FormGroup>
                                                            )}
                                                        </FormGroup>
                                                    </div>
                                                    :
                                                    <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                        <legend className="col-form-label">{item?.sort}{". "}{item?.question}</legend>

                                                        {item?.options && item?.options.map((optionItem, optionIndex) => {
                                                            let isCheck = false
                                                            let isCheckSpine = false
                                                            let isCheckFracture = false
                                                            let isCheckOther = false
                                                            let isCheckCSection = false
                                                            if (item?.type == 'multi-select') {
                                                                if (params?.medical_history_id && !isEmptyObject(questionValue)) {
                                                                    if (questionValue[item?.key].includes(optionItem)) {
                                                                        isCheck = true;
                                                                    }
                                                                }
                                                            } else {
                                                                if (questionValue[item?.key] == optionItem) {
                                                                    isCheck = true;
                                                                }
                                                            }
                                                            if (item?.key == 'previous_surgeries') {
                                                                if (questionValue.hasOwnProperty('previous_surgeries')) {
                                                                    if (questionValue[item?.key].includes('Fracture Fixation')) {
                                                                        isCheckFracture = true;
                                                                    }
                                                                    if (questionValue[item?.key].includes('Spine')) {
                                                                        isCheckSpine = true;
                                                                    }
                                                                    if (questionValue[item?.key].includes('C-Section')) {
                                                                        isCheckCSection = true;
                                                                    }
                                                                    if (questionValue[item?.key].includes('Other')) {
                                                                        isCheckOther = true;
                                                                    }
                                                                }
                                                            }
                                                            if (item?.key == 'associated_diseases') {
                                                                if (questionValue.hasOwnProperty('associated_diseases')) {
                                                                    if (questionValue[item?.key].includes('Arthroscopy')) {
                                                                        isCheck = true;
                                                                    }
                                                                }
                                                            }

                                                            return (
                                                                <>
                                                                    <Col xs={12} md={activeStep == 3 ? '6' : '12'} lg={activeStep == 3 ? '6' : '12'} xl={activeStep == 3 ? '6' : '12'}>
                                                                        <>

                                                                            {params?.medical_history_id && !isEmptyObject(questionValue) ? (
                                                                                <FormGroup check style={{ marginLeft: '30px' }}>
                                                                                    <Label check>
                                                                                        <Input name={item?.key} checked={isCheck} defaultValue={optionItem} type={item?.type == "single-select" ? "radio" : "checkbox"} onChange={(e) => { handleClick(e, item, optionItem) }} />
                                                                                        {" "}{optionItem}
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            ) : (
                                                                                <FormGroup check style={{ marginLeft: '30px' }}>
                                                                                    <Label check>
                                                                                        <Input name={item?.key} defaultChecked={isCheck} defaultValue={optionItem} type={item?.type == "single-select" ? "radio" : "checkbox"} onChange={(e) => { handleClick(e, item, optionItem) }} />
                                                                                        {" "}{optionItem}
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )}
                                                                            {/* {item?.key == 'associated_diseases' && optionItem == 'Arthroscopy' && isCheck && ( */}
                                                                            {questionValue?.associated_diseases_arthroscopy && item?.key == 'associated_diseases' && optionItem == 'Arthroscopy' && (
                                                                                <>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"associated_diseases_arthroscopy"} defaultChecked={questionValue?.associated_diseases_arthroscopy?.left_knee} type={"radio"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'left_knee', true) }} />
                                                                                            {" "}Left Knee
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"associated_diseases_arthroscopy"} defaultChecked={questionValue?.associated_diseases_arthroscopy?.right_knee} type={"radio"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'right_knee', true) }} />
                                                                                            {" "}Right Knee
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"associated_diseases_arthroscopy"} defaultChecked={questionValue?.associated_diseases_arthroscopy?.left_shoulder} type={"radio"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'left_shoulder', true) }} />
                                                                                            {" "}Left Shoulder
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"associated_diseases_arthroscopy"} defaultChecked={questionValue?.associated_diseases_arthroscopy?.right_shoulder} type={"radio"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'right_shoulder', true) }} />
                                                                                            {" "}Right Shoulder
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"associated_diseases_arthroscopy"} defaultChecked={questionValue?.associated_diseases_arthroscopy?.other} type={"radio"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'other', true) }} />
                                                                                            {" "}Other
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* {questionValue?.associated_diseases_arthroscopy?.other && ( */}
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check style={{ width: '60%' }}>
                                                                                            Remarks:
                                                                                            <Input name={"associated_diseases_arthroscopy"} placeholder='Enter Remarks' defaultValue={questionValue?.associated_diseases_arthroscopy?.remark} type={"textarea"} onChange={(e) => { handleClickChild(e, 'associated_diseases', 'associated_diseases_arthroscopy', 'remark', false) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* )} */}
                                                                                </>
                                                                            )}
                                                                            {item?.key == 'previous_surgeries' && optionItem == 'Fracture Fixation' && questionValue?.previous_surgeries_fracture_fixation && (
                                                                                <>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            {" "}Bone
                                                                                            <Input name={"previous_surgeries"} defaultValue={questionValue?.previous_surgeries_fracture_fixation?.bone} type={"text"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_fracture_fixation', 'bone', e.target.value) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            {" "}Area Of Bone
                                                                                            <Input name={"previous_surgeries"} defaultValue={questionValue?.previous_surgeries_fracture_fixation?.area_of_bone} type={"text"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_fracture_fixation', 'area_of_bone', e.target.value) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            {" "}Type Of Fixation
                                                                                            <Input name={"previous_surgeries"} defaultValue={questionValue?.previous_surgeries_fracture_fixation?.type_of_fixation} type={"text"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_fracture_fixation', 'type_of_fixation', e.target.value) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                </>
                                                                            )}
                                                                            {item?.key == 'previous_surgeries' && optionItem == 'Spine' && questionValue?.previous_surgeries_spine && (
                                                                                <>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_spine?.decompression} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_spine', 'decompression', true) }} />
                                                                                            {" "}Decompression
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_spine?.fusion} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_spine', 'fusion', true) }} />
                                                                                            {" "}Fusion
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_spine?.discectomy} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_spine', 'discectomy', true) }} />
                                                                                            {" "}Discectomy
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_spine?.other} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_spine', 'other', true) }} />
                                                                                            {" "}Other
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* {questionValue?.previous_surgeries_spine?.other && ( */}
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>

                                                                                        <Label check style={{ width: '60%' }}>
                                                                                            Remarks:
                                                                                            <Input name={"previous_surgeries"} placeholder='Enter Remarks' defaultValue={questionValue?.previous_surgeries_spine?.remark} type={"textarea"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_spine', 'remark', false) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* )} */}
                                                                                </>
                                                                            )}
                                                                            {item?.key == 'previous_surgeries' && optionItem == 'C-Section' && questionValue?.previous_surgeries_c_section && (
                                                                                <>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.c_1} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'c_1', true) }} />
                                                                                            {" "}C1
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.c_2} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'c_2', true) }} />
                                                                                            {" "}C2
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.c_3} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'c_3', true) }} />
                                                                                            {" "}C3
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.c_4} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'c_4', true) }} />
                                                                                            {" "}C4
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.c_5} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'c_5', true) }} />
                                                                                            {" "}C5
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} defaultChecked={questionValue?.previous_surgeries_c_section?.other} type={"radio"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'other', true) }} />
                                                                                            {" "}Other
                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* {questionValue?.previous_surgeries_c_section?.other && ( */}
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check style={{ width: '60%' }}>
                                                                                            Remarks:
                                                                                            <Input name={"previous_surgeries"} placeholder='Enter Remarks' defaultValue={questionValue?.previous_surgeries_c_section?.remark} type={"textarea"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'previous_surgeries_c_section', 'remark', false) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                    {/* )} */}
                                                                                </>
                                                                            )}
                                                                            {item?.key == 'previous_surgeries' && optionItem == 'Other' && isCheckOther && (
                                                                                <>
                                                                                    <FormGroup check style={{ marginLeft: '55px' }}>
                                                                                        <Label check>
                                                                                            <Input name={"previous_surgeries"} placeholder='Enter Other Previous Surgeries' defaultValue={questionValue?.previous_surgeries?.remark} type={"input"} onChange={(e) => { handleClickChild(e, 'previous_surgeries', 'other', 'remark', false) }} />

                                                                                        </Label>
                                                                                    </FormGroup>
                                                                                </>
                                                                            )}

                                                                        </>
                                                                    </Col>
                                                                </>
                                                            )
                                                        })}

                                                    </FormGroup>
                                                }


                                            </Col>
                                        )
                                    }
                                })}
                            </Row>
                            <hr className="my-5" />
                            <div className="row align-items-center">
                                <Col xs="auto">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            handleBackButtonClick(1);
                                        }}
                                    >
                                        {activeStep == 1 ? "Cancel" : "Back"}
                                    </button>
                                </Col>
                                <Col className="text-center">
                                </Col>
                                <Col xs="auto">
                                    <button className="btn btn-primary" onClick={(e) => handleNextButtonClick()}>
                                        {activeStep == totalStep ? "Submit" : "Next"}
                                    </button>
                                </Col>
                            </div>
                            {/* {!params?.medical_history_id && (
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            )} */}
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
