import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getTestListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
const TestCategoriesList = () => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [testList, setTestList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
            getTestList()
    }, []);
    const getTestList = async () => {
        setIsLoader(true)
        const response = await getTestListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setTestList(response?.data)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClickEdit = (e, id) => {
        history.push(`/test-category/edit/${id}`)
    }
    const handleAddTestClick = ()=>{
        history.push(`/test-category/add`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
                <BreadCrumb items={[
                    { label: "Dashboard", url: "/" },
                    { label: `Test Categories` },
                ]} />
               
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Test List
                        <button className="btn btn-primary" onClick={() => handleAddTestClick()}>
                            Add Test
                        </button>
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testList && testList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.name}</td>
                                        <td>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>

                </CardBody>

            </Card>
        </div>
    );
};

export default TestCategoriesList;
