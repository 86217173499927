import { processRequest } from "./Network";
import axios, { CancelTokenSource } from "axios";
let baseURLType = window.BaseUrl;
if (localStorage.getItem("x-auth-token")) axios.defaults.headers = { "x-auth-token": localStorage.getItem("x-auth-token") };


export function getErrorResponse(error) {
  console.log(error, "error error    error ");
  let customResponse = [];
  try {
    if (error.data?.masssage == "timeout exceeded") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "timeout" };
      customResponse.user_message = "Timeout Exceeded";
    } else if (error && error?.toJSON().message === "Network Error") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "network" };
      customResponse.user_message = "Internet problem";
    } else {
      customResponse = error?.response.data;
      !!error && console.error(`FAILED API = ${error.response.config.url} | Error Code = ${customResponse.status_code?.value} | System Message = ${customResponse.system_message}`);
      !!!error && console.log("FAILED API with undefined error");
    }
  } catch (e) {
    console.log(e, "error catch");
    customResponse.success = false;
    customResponse.status_code = { key: "failed", value: -1, name: "network" };
    customResponse.user_message = "Internet problem";
  }
  return customResponse;
}
export const getCenterListAPI = async (page, rowsPerPage, cancelToken) => {
  const url = `getCenterList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submitCenterAPI = async (dataToSend, cancelToken) => {
  const url = `addCenter`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getUserListByCenterIdAPI = async (centerId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/getCenterAdminList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitUserForCenterAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/addCenterAdmin`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getStaffListAPI = async (centerId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/centerMembersList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitMemberForCenterAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/addCenterMember`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getMachineListAPI = async ( page, rowsPerPage, cancelToken) => {
  const url = `centerMachinesList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submitMachineAPI = async (dataToSend, cancelToken) => {
  const url = `addCenterMachine`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientListAPI = async (searchNameData, centerId, page, rowsPerPage, cancelToken) => {
  var url = '';
  if(searchNameData == null || searchNameData == ''){
    url = `${centerId}/patientList?offset=${page * 10}&limit=${rowsPerPage}`;
  }
    url = `${centerId}/patientList?offset=${page * 10}&limit=${rowsPerPage}&name=${searchNameData}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitPatientAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/addPatient`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};


export const getCenterDetailByIDAPI = async (center_id, cancelToken) => {
  const url = `getCenter?centerId=${center_id}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};



export const submitCenterEditAPI = async (centerID, dataToSend, cancelToken) => {
  const url = `${centerID}/editCenter`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getUserDetailByUserIDAPI = async (centerID, userId, cancelToken) => {
  const url = `${centerID}/getSpecficCenterAdmin?adminId=${userId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};


export const submitUserForCenterEditAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/editCenterAdmin`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getStaffMemberDetailByIdAPI = async (center_id, staffId, cancelToken) => {
  const url = `${center_id}/getCenterMember?memberId=${staffId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitMemberForCenterEditAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/editCenterMember`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getMachineDetailAPI = async (machine_id, cancelToken) => {
  const url = `centerMachine?machineId=${machine_id}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitMachineEditAPI = async (dataToSend, cancelToken) => {
  const url = `editCenterMachine`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientDetailAPI = async (center_id, patientId, cancelToken) => {
  const url = `${center_id}/getPatient?patientId=${patientId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitPatientEditAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/editPatient`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getMemberDetailAPI = async (center_id, memberId, cancelToken) => {
  const url = `${center_id}/getCenterMember?memberId=${memberId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const updateMemberForCenterAPI = async (center_id, dataToSend, cancelToken) => {
  const url = `${center_id}/editCenterMember`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getRolesListAPI = async (centerId, cancelToken) => {
  const url = `${centerId}/rolePermissionForAdmin`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getAllPermissionsAPI = async (cancelToken) => {
  const url = `permissions`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const updatePermissionAPI = async (centerID, dataToSend, cancelToken) => {
  const url = `${centerID}/updateRolePermissionByAdmin`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDegreeListAPI = async (cancelToken) => {
  const url = `getDegreeList`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getProfessionListAPI = async (cancelToken) => {
  const url = `getProfessionList`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getprofessionDetailByIDAPI = async (professionId, cancelToken) => {
  const url = `getSpecficProfession?id=${professionId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitProfessionAPI = async (dataToSend, cancelToken) => {
  const url = `addProfession`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitProfessionEditAPI = async (dataToSend, cancelToken) => {
  const url = `editProfession`;
  const request = { type: "PUT", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const deleteCenterAPI = async (id, cancelToken) => {
  const url = `archiveCenter?centerId=${id}`;
  const request = { type: "DELETE", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getArchivedCenterListAPI = async (page, rowsPerPage, cancelToken) => {
  const url = `archiveCenterList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientMedicalHistoryListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientMedicalHistory?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addPatientMedicalHistoryAPI = async (centerId, patientId, dataToSend, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientMedicalHistory`;
  const request = { type: "POST", urlString: url, params: dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientMedicalHistoryDetailAPI = async (centerId, patientId, medicalHistoryId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getSpecficMedicalHistory?id=${medicalHistoryId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addPatientTreatmentAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/createTreatment`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getPatientTreatmentListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientTreatmentList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientTreatmentListAPIFilter = async (centerId, patientId, startDate, endDate,rowsPerPage,page, cancelToken) => {
  let url = `${centerId}/getCenterTreatmentList?offset=${page * 10}&limit=${rowsPerPage}`;

  if (startDate) {
    url += `&start_date=${startDate}&`;
  }

  if (endDate) {
    url += `&end_date=${endDate}&`;
  }

  if (patientId) {
    url += `&patient_id=${patientId}`;
  }

  // Remove trailing '&' if it exists
  // if (url.endsWith('&')) {
  //   url = url.slice(0, -1);
  // }
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientSearchAPI = async (centerId, name, cancelToken) => {
  const url = `${centerId}/getCenterPatientSearch?name=${name}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientTreatmentCountAPI = async (centerId, patientId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientTreatmentCount`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDashboardReportAPI = async (cancelToken) => {
  const url = `getStatisticsForDasboard`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTodaysTreatmentAPI = async (cancelToken) => {
  const url = `getTreatmentList`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientTreatmentDetailAPI = async (centerId, treatmentId, cancelToken) => {
  const url = `${centerId}/getSpecficTreatment/${treatmentId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const updatePatientTreatmentAPI = async (centerId, treatmentData, cancelToken) => {
  const url = `${centerId}/updateSpecficTreatment`;
  const request = { type: "PUT", urlString: url, params: treatmentData };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitDpedAPI = async (centerId, patientId, dpedData, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addUpdatePatientDeepForm`;
  const request = { type: "POST", urlString: url, params: dpedData };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getPatientDpedListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientDeepFormList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getSpecificDpedDetailAPI = async (centerId, patientId, id, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientSpecficDeepForm?id=${id}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getCdiListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientCDIList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitCDIFormAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientCDI`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getSpecificCDIAPI = async (centerId, patientId, cdiId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getSpecficPatientCDI?id=${cdiId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const updateCDIFormAPI = async (centerId, patientId, cdiId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/editSpecficPatientCDI`;
  const request = { type: "PUT", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};


export const getECdiListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientECDIList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitECDIFormAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientECDI`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const updateECDIFormAPI = async (centerId, patientId, cdiId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/editSpecficPatientECDI`;
  const request = { type: "PUT", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getSpecificECDIAPI = async (centerId, patientId, cdiId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getSpecficPatientECDI?id=${cdiId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getDiagnosisListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientDiagnosisList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitDiagnosisAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientDiagnosis`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDiagnosisDetailAPI = async (centerId, patientId, diagnosisId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getSpecficPatientDiagnosis?id=${diagnosisId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitDiagnosisEditAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/editPatientDiagnosis`;
  const request = { type: "PUT", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTendinopathiesListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientCsrefForm6List?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submitTendinopathiesAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientCsrefForm6`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submiTendinopathiesEditAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/editPatientCsrefForm6`;
  const request = { type: "PUT", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getTendinopathiesDetailAPI = async (centerId, patientId, diagnosisId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getSpecficPatienCsrefForm6?id=${diagnosisId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getCategorizationListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientCategorizationList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submitCategorizationAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientCategorization`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getCategorizationDetailAPI = async (centerId, patientId, diagnosisId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getSpecficPatientCategorization?id=${diagnosisId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getSugestedTreatmentDetailAPI = async (centerId, patientId, diagnosisId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getSpecficPatienCsrefForm5?id=${diagnosisId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const submiSuggestedTreatmentEditAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/editPatientCsrefForm5`;
  const request = { type: "PUT", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};


export const submitSuggestedTreatmentAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientCsrefForm5`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getSuggestedTreatmentAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientCsrefForm5List?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getRecentPatientCategorizationsAPI = async (centerId, patientId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getRecentPatientCategorizations`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};

export const getDashboardDetailAPI = async (centerId, patientId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getDashboardDetails`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getLatestDpepAPI = async (centerId, patientId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getRecentPatientDeepForm`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDpepDetailAPI = async (centerId, patientId, dpepId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientSpecficDeepForm?id=${dpepId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addTreatmentBoughtAPI = async (data, cancelToken) => {
  const url = `addTreatmentToCenter`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTripleLineListAPI = async (centerId, patientId, page, rowsPerPage, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientTripleLineSignFormList?offset=${page * 10}&limit=${rowsPerPage}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitTripleLine = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientTripleLineSignForm`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTripleLineDetailAPI = async (centerId, patientId, tripleLineId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getSpecficPatientTripleSignForm?id=${tripleLineId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getProgressReportAPI = async (centerId, patientId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getProgressReport`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientDeepProgressReportAPI = async (centerId, patientId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientDeepProgressReport`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientTreatmentProgressReportAPI = async (centerId, patientId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientTreatmentProgressReport`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientDischargeDetailAPI = async (centerId, patientId, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/getPatientSummaryForDischarge`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addDischargeFormAPI = async (centerId, patientId, data, cancelToken) => {
  const url = `${centerId}/patient/${patientId}/addPatientDischarge`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDashboardReportSuperAdminAPI = async (centerId, cancelToken) => {
  const url = `/getStatisticsForDasboard?centerId=${centerId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTodaysTreatmentSuperAdminAPI = async (centerId, cancelToken) => {
  const url = `/getTreatmentList?center_id=${centerId}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getIfDischargeAPI = async (centerId,patientId, cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/form_info`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const checkIfCenterIsSetupAPI = async (centerId, cancelToken) => {
  const url = `/${centerId}/isMemberAdded`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const updatePatientStatusAPI = async (centerId,data, cancelToken) => {
  const url = `/${centerId}/setPatientStatus`;
  const request = { type: "PUT", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getCenterListSuggestionAPI = async (value,cancelToken) => {
  const url = `getCenterList?name=${value}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTreatmentDoneStatsAPI = async (query,cancelToken) => {
  const url = `treatmentDoneStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientStatusStatsAPI = async (query,cancelToken) => {
  const url = `getPatientStatusStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientRegistrationStatsAPI = async (query,cancelToken) => {
  const url = `getPatientRegistrationStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTreatmentDoneStatsDetailAPI = async (query,cancelToken) => {
  const url = `treatmentDoneStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientStatusStatsDetailAPI = async (query,cancelToken) => {
  const url = `getPatientStatusStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientRegistrationStatsDetailAPI = async (query,cancelToken) => {
  const url = `getPatientRegistrationStats?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getDiagnosisReportAPI = async (query,cancelToken) => {
  const url = `getDiagnosisReport?${query}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientDeepRadiculopathyMotorChartListAPI = async (centerId, patientId, dped_id_detail,cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getPatientDeepRadiculopathyMotorChartList/${dped_id_detail}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getPatientDeepRadiculopathyMotorSensoryChartListAPI = async (centerId, patientId, dped_id_detail,cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/getPatientDeepRadiculopathySensoryChartList/${dped_id_detail}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitRadiculopathyChartDataAPI = async (centerId, patientId, data,cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/addUpdatePatientDeepRadiculopathyMotorChart`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const submitRadiculopathySensoryChartDataAPI = async (centerId, patientId, data,cancelToken) => {
  const url = `/${centerId}/patient/${patientId}/addUpdatePatientDeepRadiculopathySensoryChart`;
  const request = { type: "POST", urlString: url, params: data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getTestListAPI = async (cancelToken) => {
  const url = `/getTestCategoryList`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addTestCategoriesAPI = async (data, cancelToken) => {
  const url = `/addTestCategory`;
  const request = { type: "POST", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const upateTestCategoriesAPI = async (data, cancelToken) => {
  const url = `/editTestCategory`;
  const request = { type: "PUT", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getSpecificTestAPI = async (testId, cancelToken) => {
  const url = `/getSpecficTestCategory?id=${testId}`;
  const request = { type: "GET", urlString: url};
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};