import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import {
    Row, Col,
    Button,
    CardHeader,
    Card,
    CardBody,
    Progress,
    TabContent,
    TabPane,
    CardTitle,
    Table
} from 'reactstrap';

import { getDashboardReportAPI, getTodaysTreatmentAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';

import {
    AreaChart, Area, Line,
    ResponsiveContainer,
    Bar,
    BarChart,
    ComposedChart,
    CartesianGrid,
    Tooltip,
    LineChart
} from 'recharts';

import {
    faAngleUp,
    faArrowRight,
    faArrowUp,
    faArrowLeft,
    faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../api/helperComponents/Loader';

const AnalyticsDashboard1 = () => {
    const cancelTokenSource = axios.CancelToken.source();
    const [dashboardTreatment, setDashboardTreatment] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [dashboardStats, setDashboardStats] = useState(null);

    useEffect(() => {
        getDashboardReport()
        getTodaysTreatment()
    }, []);

    const getDashboardReport = async () => {
        setIsLoader(true)
        const response = await getDashboardReportAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDashboardStats(response?.data)
            //    setVisitNumber(response?.data?.count)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getTodaysTreatment = async () => {
        setIsLoader(true)
        const response = await getTodaysTreatmentAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDashboardTreatment(response?.data?.rows)
            //    setVisitNumber(response?.data?.count)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }

    return (
        <div>
            {isLoader && (<Loader />)}
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Basic Dashboard"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <Row>
                <Col md="3">
                    <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div>
                        <div className="widget-subheading" style={{ marginTop: '5px' }}>
                            Total Treatments
                        </div>
                        <div className="widget-description text-white">
                            {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                            <span className="ps-1 fs-32">{dashboardStats?.treatments}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-midnight-bloom widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div>
                        <div className="widget-subheading" style={{ marginTop: '5px' }}>
                            Total Patients
                        </div>
                        <div className="widget-description text-white">
                            {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                            <span className="ps-1 fs-32">{dashboardStats?.patients}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-grow-early widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div>
                        <div className="widget-subheading" style={{ marginTop: '5px' }}>
                            Total Centers
                        </div>
                        <div className="widget-description text-white">
                            {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                            <span className="ps-1 fs-32">{dashboardStats?.admins}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-love-kiss widget-chart card-border">
                        {/* <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div> */}
                        <div className="widget-subheading" style={{ marginTop: '10px' }}>
                            <i className="fa fa-users" />
                            <span style={{ marginLeft: '20px' }}>Total Members: <strong>{parseInt(dashboardStats?.accountants + dashboardStats?.consultantDoctors + dashboardStats?.staff + dashboardStats?.treatmentDoctors)}</strong></span>
                        </div>
                        <div className="widget-description text-white" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span className="ps-1">Accountants: {dashboardStats?.accountants}</span>
                            <span className="ps-1">Consultant Doctors: {dashboardStats?.consultantDoctors}</span>
                            <span className="ps-1">Staff: {dashboardStats?.staff}</span>
                            <span className="ps-1">Treatment Doctors: {dashboardStats?.treatmentDoctors}</span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Today's Treatment List</CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Pateint Name</th>
                                    <th>Center Name</th>
                                    <th>Treatment By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardTreatment && dashboardTreatment.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.patient_info?.name}</td>
                                        <td>{item?.center_info?.name}</td>
                                        <td>{item?.user_created?.name}</td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                </CardBody>

            </Card>
        </div>
    );
}

export default AnalyticsDashboard1;
