import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getSuggestedTreatmentAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
const DiagnosisList = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const params = useParams()
    const [categorizationList, setCategorizationList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            patient_grade: [
                { GRADE_I: { key: 'GRADE_I', value: 1, name: 'Grade I' } },
                { GRADE_II: { key: 'GRADE_II', value: 2, name: 'Grade II' } },
                { GRADE_III: { key: 'GRADE_III', value: 3, name: 'Grade III' } },
                { GRADE_IV: { key: 'GRADE_IV', value: 4, name: 'Grade IV' } },
            ],
        },
        {
            duration: [
                { WEEKS_6_12: { key: 'Weeks_6_12Weeks_6_12', value: 1, name: '6-12 Weeks' } },
                { WEEKS_12_16: { key: 'WEEKS_12_16', value: 2, name: '12-16 Weeks' } },
                { WEEKS_16_24: { key: 'WEEKS_16_24', value: 3, name: '16-24 Weeks' } },
                { MORE_THAN_24_WEEKS: { key: 'MORE_THAN_24_WEEKS', value: 4, name: 'More than 24 Weeks' } },
            ],
        },

        {
            no_of_sessions: [
                { 6_12: { key: '6_12', value: 1, name: '6-12' } },
                { 12_20: { key: '12_20', value: 2, name: '12-20' } },
                { 20_24: { key: '20_24', value: 3, name: '20-24' } },
                { 24_30: { key: '24_30', value: 4, name: '24-30' } },
            ],
        },
        {
            follow_up: [
                { AS_PER_NEED: { key: 'AS_PER_NEED', value: 1, name: 'As Per Need' } },
                { WEEKS_24: { key: 'WEEKS_24', value: 2, name: '24 Weeks' } },
                { YEARS_2: { key: 'YEARS_2', value: 3, name: '2 Years' } },
                { LIFE_TIME: { key: 'LIFE_TIME', value: 4, name: 'Life Time' } },
            ],
        },
        {
            physiotherapy: [
                { OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED: { key: 'OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED', value: 1, name: 'Observe Posture Corrective educative advice as conveyed' } },
                { NOT_REQUIRED: { key: 'NOT_REQUIRED', value: 2, name: 'Not required' } },
            ],
        },
    ])
    useEffect(() => {
        if (centerId) {
            getSuggestedTreatment(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getSuggestedTreatment(centerId, page, rowsPerPage)
    }, [page]);
    const getSuggestedTreatment = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getSuggestedTreatmentAPI(centerId, params?.patient_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCategorizationList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddCategorizationClick = () => {
        history.push(`/patient/suggested-treatment/add/${params?.patient_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/suggested-treatment/edit/${params?.patient_id}/${id}`)
    }
    const getNameofSelectedOptions = (ids, key) => {
        console.log(ids, 'ids')
        let allDdata = null
        if (diagnosisQuestions) {
            for (let a of diagnosisQuestions) {
                let keyValueData = Object.keys(a)[0]
                if (keyValueData) {
                    if (keyValueData == key) {
                        allDdata = a[keyValueData]
                    }
                }

            }
        }

        console.log(allDdata, 'allDdata')
        console.log(diagnosisQuestions, 'diagnosisQuestions')
        // return;
        // ids = ids.map(Number);
        if (allDdata) {
            const filteredData = allDdata.filter(item => {
                const value = Object.values(item)[0].value;
                console.log(value, 'value value value')
                console.log(ids, 'idsidsidsidsids')
                if (ids == value) {
                    return true
                }
            });
            console.log(filteredData, 'filteredData')
            // return filteredData
            const names = filteredData.map(item => Object.values(item)[0].name).join(', ');
            console.log(names, 'names')
            return names
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Suggested Treatment" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Suggested Treatment
                        {(permissions.includes("PATIENT_CSREF_FORM_5_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddCategorizationClick()}>
                                Add Suggested Treatment
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Patient Grade</th>
                                    <th>Duration</th>
                                    <th>No Of Sessions</th>
                                    <th>Follow Up</th>
                                    <th>Physiotherapy</th>
                                    <th>Created At</th>
                                    {(permissions.includes("PATIENT_CSREF_FORM_5_UPDATE") || role == "SUPER_ADMIN") && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {categorizationList && categorizationList.map((item, index) => {
                                    // console.log(getNameofSpinal(item?.spinal),'getNameofSpinal(item?.spinal)')
                                    // console.log(getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', '),'getNameofSpinal(item?.spinal)dfd')
                                    // let spinalName = getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', ')
                                    return (
                                        <tr key={index}>
                                            <td>{getNameofSelectedOptions(item?.patient_grade, 'patient_grade')}</td>
                                            <td>{getNameofSelectedOptions(item?.duration, 'duration')}</td>
                                            <td>{getNameofSelectedOptions(item?.no_of_sessions, 'no_of_sessions')}</td>
                                            <td>{getNameofSelectedOptions(item?.follow_up, 'follow_up')}</td>
                                            <td>{getNameofSelectedOptions(item?.physiotherapy, 'physiotherapy')}</td>
                                            <td>{dateTimeFormate(item?.created_at)}</td>
                                            {(permissions.includes("PATIENT_CSREF_FORM_5_UPDATE") || role == "SUPER_ADMIN") && (
                                                <td>
                                                    <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default DiagnosisList;
