import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,FormGroup, Label
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getLabReportsAPI, getSingleDocumentAPI, deleteSingleDocumentAPI } from './../../../api/networkDocumentModule/Api';
import { getTestListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
const LabTestList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientReportList, setPatientReportList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [testCategoriesData, setTestCategoriesData] = useState([]);
    const [selectTestValue, setSelectTestValue] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    let patientId = params?.patient_id
    useEffect(() => {

        if (params?.patient_id) {
            getLabReports(params?.patient_id, page, rowsPerPage,selectTestValue)
        }
    }, [params?.patient_id]);
    useEffect(() => {
        getLabReports(params?.patient_id, page, rowsPerPage,selectTestValue)
    }, [page,selectTestValue]);
    useEffect(() => {
        getAllTestTypes()
    }, []);
    const getAllTestTypes = async () => {
        setIsLoader(true)
        const response = await getTestListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setTestCategoriesData(response?.data)
        } else {

        }
    }
    const getLabReports = async (patientId, page, rowsPerPage, selectTestValue) => {
        setIsLoader(true)
        const response = await getLabReportsAPI(patientId, page, rowsPerPage,selectTestValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response) {
            setPatientReportList(response?.items)
            setCount(response?.totalCount)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);

    };
    const handleAddPatientLabClick = (e) => {
        history.push(`/patient/lab-test/add/${patientId}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/cdi/edit/${patientId}/${id}`)
    }
    function calculateSubScore(level, data) {
        console.log(data, 'data')
        console.log(data[`${level}_olisthesis_lateral`], 'level')
        const lateral = Number(data[`${level}_olisthesis_lateral`]);
        const flexion = Number(data[`${level}_olisthesis_flexion`]);
        const extension = Number(data[`${level}_olisthesis_extension`]);
        const anterior = Number(data[`${level}_olisthesis_anterior`]);
        const posterior = Number(data[`${level}_olisthesis_posterior`]);
        const discSpaceNarrowing = Number(data[`${level}_disc_space_narrowing`]);

        const maxFlexionExtension = Math.max(flexion, extension);
        const maxAnteriorPosterior = Math.max(anterior, posterior);

        return lateral + maxFlexionExtension + maxAnteriorPosterior + discSpaceNarrowing;
    }
    const getTotalCdiScore = (item) => {

        return calculateSubScore('c2_c3', item) + calculateSubScore('c3_c4', item) + calculateSubScore('c4_c5', item) + calculateSubScore('c5_c6', item) + calculateSubScore('c6_c7', item)

    }
    const handleClickDownload = async (id, action) => {
        setIsLoader(true)
        const response = await getSingleDocumentAPI(id, cancelTokenSource.token)
        setIsLoader(false)
        if (response) {
            if(action === "download"){
            downloadFile(response?.doc_url,response?.document?.fileName)
            }else{
                window.open(response?.doc_url, '_blank');
            }
            // window.open(response?.doc_url, '_blank');
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    async function downloadFile(url, fileName) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
    
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
    
        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.download = fileName; // Set the name of the file to download
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    
        // Clean up
        URL.revokeObjectURL(blobUrl);
    }
    const handleClickDelete = async (id) => {
        setIsLoader(true)
        const response = await deleteSingleDocumentAPI(id, cancelTokenSource.token)
        setIsLoader(false)
        if (response) {
            toast("Document Deleted Successfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            getLabReports(params?.patient_id, page, rowsPerPage)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Lab Tests" },
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Lab Tests
                        {(permissions.includes("CREATE_PATIENT_DOCUMENT") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddPatientLabClick()}>
                                Add Lab Test
                            </button>
                        )}
                    </CardTitle>
                    <div className="row">
                                    <Col md="6" >
                                        <FormGroup>
                                            <Label for="f_name">Filter By Test Type</Label>
                                            <select className="form-control" onChange={(e) => setSelectTestValue(e.target.value)}>
                                                <option value="">Select Test Type</option>
                                                {testCategoriesData && testCategoriesData.map((item, index) => (
                                                    <option value={item?.name}>{item?.name}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    </div>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Test Report</th>
                                    <th>File Name</th>
                                    <th>Created At</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {patientReportList && patientReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.documentTypeId}</td>
                                        <td>{item?.fileName}</td>
                                        <td>{dateTimeFormate(item?.uploadDate)}</td>
                                        {(permissions.includes("PATIENT_CDI_UPDATE") || role == "SUPER_ADMIN") && (
                                            <td>
                                                {(permissions.includes("DOWNLOAD_PATIENT_DOCUMENT") || role == "SUPER_ADMIN") && (
                                                    <i style={{ cursor: 'pointer' }} className="fa fa-eye" onClick={(e) => { handleClickDownload(item.id,'view') }}></i>
                                                )}
                                                {(permissions.includes("DOWNLOAD_PATIENT_DOCUMENT") || role == "SUPER_ADMIN") && (
                                                    <i style={{ cursor: 'pointer', marginLeft: '10px' }} className="fa fa-download" onClick={(e) => { handleClickDownload(item.id,'download') }}></i>
                                                )}
                                                {(permissions.includes("DELETE_PATIENT_DOCUMENT") || role == "SUPER_ADMIN") && (
                                                    <i style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }} className="fa fa-trash" onClick={(e) => { handleClickDelete(item.id) }}></i>
                                                )}
                                            </td>
                                        )}

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default LabTestList;
